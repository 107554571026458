//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/styles/NewmealPlan.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import Request from "axios";
import { BASE_URL } from "../../components/baseURL";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ClientDropdown from "../../components/ClientDropdown";
import moment from "moment";
import TabularAssessment from "../../components/TabularAssessment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function NewMealPlanPage() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [clientNames, setClientNames] = useState([]);
  const Risingmeal = useRef();
  const Risingfood = useRef();
  const Risingremark = useRef();
  const Breakfastmeal = useRef();
  const Breakfastfood = useRef();
  const Breakfastremark = useRef();
  const Lunchmeal = useRef();
  const Lunchfood = useRef();
  const Lunchremark = useRef();
  const Snackmeal = useRef();
  const Snackfood = useRef();
  const Snackremark = useRef();
  const Dinnermeal = useRef();
  const Dinnerfood = useRef();
  const Dinnerremark = useRef();
  const Bedmeal = useRef();
  const Bedfood = useRef();
  const Bedremark = useRef();
  const Preworkout1meal = useRef();
  const Preworkout1food = useRef();
  const Preworkout1remark = useRef();
  const Preworkout2remark = useRef();
  const Preworkout2food = useRef();
  const Preworkout2meal = useRef();
  const Postworkout1meal = useRef();
  const Postworkout1food = useRef();
  const Postworkout1remark = useRef();
  const LateeveningSnackmeal = useRef();
  const LateeveningSnackfood = useRef();
  const LateeveningSnackremark = useRef();
  const Postworkout2remark = useRef();
  const Postworkout2food = useRef();
  const Postworkout2meal = useRef();
  const Midmorningremark = useRef();
  const Predinnerremark = useRef();
  const Midmorningfood = useRef();
  const Predinnerfood = useRef();
  const Midmorningmeal = useRef();
  const Predinnermeal = useRef();
  const Additional = useRef();
  const location = useLocation();
  const navigation = useNavigate();
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const [session, setSession] = useState();
  const [mask, setMask] = useState();
  const [selectedName, setSelectedName] = useState("Select a client");
  let userCache = JSON.parse(localStorage.getItem("user"));
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [
      // { id: "Risingmeal", name: "errorRisingMeal", ref: Risingmeal },
      // { id: "Risingfood", name: "errorRisingPlan", ref: Risingfood },
      // { id: "Breakfastmeal", name: "errorBreakdastMeal", ref: Breakfastmeal },
      // { id: "Breakfastfood", name: "errorBreakfastPlan", ref: Breakfastfood },
      // { id: "Lunchmeal", name: "errorLunchMeal", ref: Lunchmeal },
      // { id: "Lunchfood", name: "errorLunchPlan", ref: Lunchfood },
      // { id: "Snackmeal", name: "errorSnackMeal", ref: Snackmeal },
      // { id: "Snackfood", name: "errorSnackFood", ref: Snackfood },
      // { id: "Dinnermeal", name: "errorDinnerMeal", ref: Dinnermeal },
      // { id: "Dinnerfood", name: "errorDinnerPlan", ref: Dinnerfood },
      // { id: "Bedmeal", name: "errorBedMeal", ref: Bedmeal },
      // { id: "Bedfood", name: "errorBedPlan", ref: Bedfood },
    ];
    if (!edit && !create && selectedName === "Select a client") {
      error = true;
      alert("Select a user first");
    } else {
      error = false;
    }
    !error &&
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);

        if (val.ref.current.value?.length < 1) {
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }
      });
    if (!error && edit) {
      updatemealplan();
    }
    if (!error && create) {
      newmealplan();
    }
    if (!error && !edit && !create) {
      if (selectedName) {
        newmealplan();
      } else {
        alert("Select a client first!");
      }
    }
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//


  //-----------------------------------------new meal plan cache storage-------------------------------------------------------//
  
  useEffect(() => {
    let userDataCache = JSON.parse(
      localStorage.getItem(
        `mealplan-${edit || create ? location?.state?.data?.id : selectedName?.id}`
      )
    );

    if (userDataCache && !edit) {
      Risingmeal.current.value = userDataCache?.Risingmeal;
      Risingfood.current.value = userDataCache?.Risingfood;
      Risingremark.current.value = userDataCache?.Risingremark;
      Breakfastmeal.current.value = userDataCache?.Breakfastmeal;
      Breakfastfood.current.value = userDataCache?.Breakfastfood;
      Breakfastremark.current.value = userDataCache?.Breakfastremark;
      Lunchmeal.current.value = userDataCache?.Lunchmeal;
      Lunchfood.current.value = userDataCache?.Lunchfood;
      Lunchremark.current.value = userDataCache?.Lunchremark;
      Snackmeal.current.value = userDataCache?.Snackmeal;
      Snackfood.current.value = userDataCache?.Snackfood;
      Snackremark.current.value = userDataCache?.Snackremark;
      Dinnermeal.current.value = userDataCache?.Dinnermeal;
      Dinnerfood.current.value = userDataCache?.Dinnerfood;
      Dinnerremark.current.value = userDataCache?.Dinnerremark;
      Bedmeal.current.value = userDataCache?.Bedmeal;
      Bedfood.current.value = userDataCache?.Bedfood;
      Bedremark.current.value = userDataCache?.Bedremark;
      Preworkout1meal.current.value = userDataCache?.Preworkout1meal;
      Preworkout1food.current.value = userDataCache?.Preworkout1food;
      Preworkout1remark.current.value = userDataCache?.Preworkout1remark;
      Preworkout2remark.current.value = userDataCache?.Preworkout2remark;
      Preworkout2food.current.value = userDataCache?.Preworkout2food;
      Preworkout2meal.current.value = userDataCache?.Preworkout2meal;
      Postworkout1meal.current.value = userDataCache?.Postworkout1meal;
      Postworkout1food.current.value = userDataCache?.Postworkout1food;
      Postworkout1remark.current.value = userDataCache?.Postworkout1remark;
      Postworkout2remark.current.value = userDataCache?.Postworkout2remark;
      Postworkout2food.current.value = userDataCache?.Postworkout2food;
      Postworkout2meal.current.value = userDataCache?.Postworkout2meal;
      LateeveningSnackremark.current.value = userDataCache?.Lateeveningsnackremark;
      LateeveningSnackfood.current.value = userDataCache?.Lateeveningsnackfood;
      LateeveningSnackmeal.current.value = userDataCache?.Lateeveningsnackmeal;
      Midmorningremark.current.value = userDataCache?.Midmorningremark;
      Predinnerremark.current.value = userDataCache?.Predinnerremark;
      Midmorningfood.current.value = userDataCache?.Midmorningfood;
      Predinnerfood.current.value = userDataCache?.Predinnerfood;
      Midmorningmeal.current.value = userDataCache?.Midmorningmeal;
      Predinnermeal.current.value = userDataCache?.Predinnermeal;
      Additional.current.value = userDataCache?.Additional;
      document.getElementById("date").valueAsDate = new Date(userDataCache?.Startdate);
    } else {
      Risingmeal.current.value = "";
      Risingfood.current.value = "";
      Risingremark.current.value = "";
      Breakfastmeal.current.value = "";
      Breakfastfood.current.value = "";
      Breakfastremark.current.value = "";
      Lunchmeal.current.value = "";
      Lunchfood.current.value = "";
      Lunchremark.current.value = "";
      Snackmeal.current.value = "";
      Snackfood.current.value = "";
      Snackremark.current.value = "";
      Dinnermeal.current.value = "";
      Dinnerfood.current.value = "";
      Dinnerremark.current.value = "";
      Bedmeal.current.value = "";
      Bedfood.current.value = "";
      Bedremark.current.value = "";
      Preworkout1meal.current.value = "";
      Preworkout1food.current.value = "";
      Preworkout1remark.current.value = "";
      Preworkout2remark.current.value = "";
      Preworkout2food.current.value = "";
      Preworkout2meal.current.value = "";
      Postworkout1meal.current.value = "";
      Postworkout1food.current.value = "";
      Postworkout1remark.current.value = "";
      Postworkout2remark.current.value = "";
      Postworkout2food.current.value = "";
      Postworkout2meal.current.value = "";
      LateeveningSnackremark.current.value = "";
      LateeveningSnackfood.current.value = "";
      LateeveningSnackmeal.current.value = "";
      Midmorningremark.current.value = "";
      Predinnerremark.current.value = "";
      Midmorningfood.current.value = "";
      Predinnerfood.current.value = "";
      Midmorningmeal.current.value = "";
      Predinnermeal.current.value = "";
      Additional.current.value = "";
    }
  }, [selectedName, location?.state?.data?.id, edit, create]);

  //--------------------------------------------new meal plan cache storage----------------------------------------------------------//
  
  //----------------------------------------------------- Cache Storage Function---------------------------------------------------//

  function cacheStorage() {
    (edit || create ? location?.state?.data?.id : selectedName?.id) &&
      localStorage.setItem(
        `mealplan-${edit || create ? location?.state?.data?.id : selectedName?.id}`,
        JSON.stringify({
          Risingmeal: Risingmeal.current.value,
          Risingfood: Risingfood.current.value,
          Risingremark: Risingremark.current.value,
          Breakfastmeal: Breakfastmeal.current.value,
          Breakfastfood: Breakfastfood.current.value,
          Breakfastremark: Breakfastremark.current.value,
          Lunchmeal: Lunchmeal.current.value,
          Lunchfood: Lunchfood.current.value,
          Lunchremark: Lunchremark.current.value,
          Snackmeal: Snackmeal.current.value,
          Snackfood: Snackfood.current.value,
          Snackremark: Snackremark.current.value,
          Dinnermeal: Dinnermeal.current.value,
          Dinnerfood: Dinnerfood.current.value,
          Dinnerremark: Dinnerremark.current.value,
          Bedmeal: Bedmeal.current.value,
          Bedfood: Bedfood.current.value,
          Bedremark: Bedremark.current.value,
          Preworkout1meal: Preworkout1meal.current.value,
          Preworkout1food: Preworkout1food.current.value,
          Preworkout1remark: Preworkout1remark.current.value,
          Preworkout2remark: Preworkout2remark.current.value,
          Preworkout2food: Preworkout2food.current.value,
          Preworkout2meal: Preworkout2meal.current.value,
          Postworkout1meal: Postworkout1meal.current.value,
          Postworkout1food: Postworkout1food.current.value,
          Postworkout1remark: Postworkout1remark.current.value,
          Postworkout2remark: Postworkout2remark.current.value,
          Postworkout2food: Postworkout2food.current.value,
          Postworkout2meal: Postworkout2meal.current.value,
          LateeveningSnackremark: LateeveningSnackremark.current.value,
          LateeveningSnackfood: LateeveningSnackfood.current.value,
          LateeveningSnackmeal: LateeveningSnackmeal.current.value,
          Midmorningremark: Midmorningremark.current.value,
          Predinnerremark: Predinnerremark.current.value,
          Midmorningfood: Midmorningfood.current.value,
          Predinnerfood: Predinnerfood.current.value,
          Midmorningmeal: Midmorningmeal.current.value,
          Predinnermeal: Predinnermeal.current.value,
          Additional: Additional.current.value,

          Createddate: new Date(),
          Startdate: document.getElementById("date").valueAsDate,
        })
      );
  }

  //-----------------------------------------------------  Cache Storage Function---------------------------------------------------//


  //----------------------------------------------------- meal plan Function---------------------------------------------------//
  
  async function newmealplan() {
    Request.post(`${BASE_URL}plans/addMealPlans`, {
      Risingmeal: Risingmeal.current.value,
      Risingfood: Risingfood.current.value,
      Risingremark: Risingremark.current.value,
      Breakfastmeal: Breakfastmeal.current.value,
      Breakfastfood: Breakfastfood.current.value,
      Breakfastremark: Breakfastremark.current.value,
      Lunchmeal: Lunchmeal.current.value,
      Lunchfood: Lunchfood.current.value,
      Lunchremark: Lunchremark.current.value,
      Snackmeal: Snackmeal.current.value,
      Snackfood: Snackfood.current.value,
      Snackremark: Snackremark.current.value,
      Dinnermeal: Dinnermeal.current.value,
      Dinnerfood: Dinnerfood.current.value,
      Dinnerremark: Dinnerremark.current.value,
      Bedmeal: Bedmeal.current.value,
      Bedfood: Bedfood.current.value,
      Bedremark: Bedremark.current.value,
      Preworkout1meal: Preworkout1meal.current.value,
      Preworkout1food: Preworkout1food.current.value,
      Preworkout1remark: Preworkout1remark.current.value,
      Preworkout2remark: Preworkout2remark.current.value,
      Preworkout2food: Preworkout2food.current.value,
      Preworkout2meal: Preworkout2meal.current.value,
      Postworkout1meal: Postworkout1meal.current.value,
      Postworkout1food: Postworkout1food.current.value,
      Postworkout1remark: Postworkout1remark.current.value,
      Postworkout2remark: Postworkout2remark.current.value,
      Postworkout2food: Postworkout2food.current.value,
      Postworkout2meal: Postworkout2meal.current.value,
      LateeveningSnackremark: LateeveningSnackremark.current.value,
      LateeveningSnackfood: LateeveningSnackfood.current.value,
      LateeveningSnackmeal: LateeveningSnackmeal.current.value,
      Midmorningremark: Midmorningremark.current.value,
      Predinnerremark: Predinnerremark.current.value,
      Midmorningfood: Midmorningfood.current.value,
      Predinnerfood: Predinnerfood.current.value,
      Midmorningmeal: Midmorningmeal.current.value,
      Predinnermeal: Predinnermeal.current.value,
      Additional: Additional.current.value,
      Createddate: new Date(),
      Client_id: edit || create ? location?.state?.data?.id : selectedName?.id,
      Createdby: userCache?.Firstname + " " + userCache?.Lastname,
      Isapproved: userCache?.id === 1 ? 1 : 0,
      Planid: create ? location?.state?.data?.Planid : selectedName?.Plan_id,
      Startdate: document.getElementById("date").valueAsDate,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Meal Uploaded");
          setSelectedName("Select a client");
          localStorage.removeItem(
            `mealplan-${edit || create ? location?.state?.data?.id : selectedName?.id}`
          );
          Risingmeal.current.value = "";
          Risingfood.current.value = "";
          Risingremark.current.value = "";
          Breakfastmeal.current.value = "";
          Breakfastfood.current.value = "";
          Breakfastremark.current.value = "";
          Lunchmeal.current.value = "";
          Lunchfood.current.value = "";
          Lunchremark.current.value = "";
          Snackmeal.current.value = "";
          Snackfood.current.value = "";
          Snackremark.current.value = "";
          Dinnermeal.current.value = "";
          Dinnerfood.current.value = "";
          Dinnerremark.current.value = "";
          Bedmeal.current.value = "";
          Bedfood.current.value = "";
          Bedremark.current.value = "";
          Preworkout1meal.current.value = "";
          Preworkout1food.current.value = "";
          Preworkout1remark.current.value = "";
          Preworkout2remark.current.value = "";
          Preworkout2food.current.value = "";
          Preworkout2meal.current.value = "";
          Postworkout1meal.current.value = "";
          Postworkout1food.current.value = "";
          Postworkout1remark.current.value = "";
          Postworkout2remark.current.value = "";
          Postworkout2food.current.value = "";
          Postworkout2meal.current.value = "";
          LateeveningSnackremark.current.value = "";
          LateeveningSnackfood.current.value = "";
          LateeveningSnackmeal.current.value = "";
          Midmorningremark.current.value = "";
          Predinnerremark.current.value = "";
          Midmorningfood.current.value = "";
          Predinnerfood.current.value = "";
          Midmorningmeal.current.value = "";
          Predinnermeal.current.value = "";
          Additional.current.value = "";

          getClientNames();
        } else if (res?.data?.message == "Sessions exausted") {
          alert("No. of sessions exausted for this plan!");
        } else {
          alert("Error uploading meals", res?.data?.message);
          console.log("Error uploading meals", res?.data);
        }
      })
      .catch((err) => {
        alert("Error uploading meal");
        console.log("Error uploading meal in: ", err);
      });
  }

  //----------------------------------------------------- meal plan Function---------------------------------------------------//

  //---------------------------------------------------------Update  Meal Plan Function-----------------------------------------------------------------------//

  async function updatemealplan() {
    Request.put(`${BASE_URL}plans/updateMealPlan`, {
      Risingmeal: Risingmeal.current.value,
      Risingfood: Risingfood.current.value,
      Risingremark: Risingremark.current.value,
      Breakfastmeal: Breakfastmeal.current.value,
      Breakfastfood: Breakfastfood.current.value,
      Breakfastremark: Breakfastremark.current.value,
      Lunchmeal: Lunchmeal.current.value,
      Lunchfood: Lunchfood.current.value,
      Lunchremark: Lunchremark.current.value,
      Snackmeal: Snackmeal.current.value,
      Snackfood: Snackfood.current.value,
      Snackremark: Snackremark.current.value,
      Dinnermeal: Dinnermeal.current.value,
      Dinnerfood: Dinnerfood.current.value,
      Dinnerremark: Dinnerremark.current.value,
      Bedmeal: Bedmeal.current.value,
      Bedfood: Bedfood.current.value,
      Bedremark: Bedremark.current.value,
      Preworkout1meal: Preworkout1meal.current.value,
      Preworkout1food: Preworkout1food.current.value,
      Preworkout1remark: Preworkout1remark.current.value,
      Preworkout2remark: Preworkout2remark.current.value,
      Preworkout2food: Preworkout2food.current.value,
      Preworkout2meal: Preworkout2meal.current.value,
      Postworkout1meal: Postworkout1meal.current.value,
      Postworkout1food: Postworkout1food.current.value,
      Postworkout1remark: Postworkout1remark.current.value,
      Postworkout2remark: Postworkout2remark.current.value,
      Postworkout2food: Postworkout2food.current.value,
      Postworkout2meal: Postworkout2meal.current.value,
      Midmorningremark: Midmorningremark.current.value,
      Predinnerremark: Predinnerremark.current.value,
      Midmorningfood: Midmorningfood.current.value,
      Predinnerfood: Predinnerfood.current.value,
      Midmorningmeal: Midmorningmeal.current.value,
      Predinnermeal: Predinnermeal.current.value,
      LateeveningSnackremark: LateeveningSnackremark.current.value,
      LateeveningSnackfood: LateeveningSnackfood.current.value,
      LateeveningSnackmeal: LateeveningSnackmeal.current.value,
      Additional: Additional.current.value,
      Createddate: new Date(),
      Client_id: edit ? location?.state?.data?.id : selectedName?.Client_id,
      Createdby: userCache?.Firstname + " " + userCache?.Lastname,
      Planhistory_id: edit ? location?.state?.data?.Planid : selectedName?.Plan_id,
      Startdate: moment(document.getElementById("date").valueAsDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Meal Uploaded");
          Risingmeal.current.value = "";
          Risingfood.current.value = "";
          Risingremark.current.value = "";
          Breakfastmeal.current.value = "";
          Breakfastfood.current.value = "";
          Breakfastremark.current.value = "";
          Lunchmeal.current.value = "";
          Lunchfood.current.value = "";
          Lunchremark.current.value = "";
          Snackmeal.current.value = "";
          Snackfood.current.value = "";
          Snackremark.current.value = "";
          Dinnermeal.current.value = "";
          Dinnerfood.current.value = "";
          Dinnerremark.current.value = "";
          Bedmeal.current.value = "";
          Bedfood.current.value = "";
          Bedremark.current.value = "";
          Preworkout1meal.current.value = "";
          Preworkout1food.current.value = "";
          Preworkout1remark.current.value = "";
          Preworkout2remark.current.value = "";
          Preworkout2food.current.value = "";
          Preworkout2meal.current.value = "";
          Postworkout1meal.current.value = "";
          Postworkout1food.current.value = "";
          Postworkout1remark.current.value = "";
          Postworkout2remark.current.value = "";
          Postworkout2food.current.value = "";
          Postworkout2meal.current.value = "";
          LateeveningSnackremark.current.value = "";
          LateeveningSnackfood.current.value = "";
          LateeveningSnackmeal.current.value = "";
          Midmorningremark.current.value = "";
          Predinnerremark.current.value = "";
          Midmorningfood.current.value = "";
          Predinnerfood.current.value = "";
          Midmorningmeal.current.value = "";
          Predinnermeal.current.value = "";
          Additional.current.value = "";
        } else {
          alert("Error uploading meals", res?.data?.message);
          console.log("Error uploading meals", res?.data);
        }
      })
      .catch((err) => {
        alert("Error uploading meal");
        console.log("Error uploading meal in: ", err);
      });
  }

  //---------------------------------------------------------Update  Meal Plan Function-----------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to Get Meal Plan-----------------------------------------------------------------------//

  useEffect(() => {
    if (location?.state?.data?.edit || location?.state?.data?.create) {
      if (location?.state?.data?.create) {
        let sessionCount = location?.state?.data
          ? location?.state?.data?.Max_Sessions !== null
            ? parseInt(
                location?.state?.data?.currentSession ? location?.state?.data?.currentSession : 0
              ) +
                1 >
              location?.state?.data?.Max_Sessions
              ? "count expired"
              : "Session " +
                (parseInt(
                  location?.state?.data?.currentSession ? location?.state?.data?.currentSession : 0
                ) +
                  1)
            : "Plan not purchased yet"
          : "";
        setSession(`${sessionCount}`);
        setCreate(true);
        const today = new Date();
        // const expDate = new Date(today.setDate(today.getDate() + 15));
        document.getElementById("date").valueAsDate = today;
        // setSession(0)
      }
      if (location?.state?.data?.edit) {
        setEdit(true);
        Request.get(`${BASE_URL}plans/getMealPlans?id=${location?.state?.data?.id}`, {})
          .then((res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.data?.length > 0) {
                setSession(`Session ${res?.data?.data[0]?.Session}`);
                Risingmeal.current.value = res?.data?.data[0]?.Risingmeal;
                Risingfood.current.value = res?.data?.data[0]?.Risingfood;
                Risingremark.current.value = res?.data?.data[0]?.Risingremark;
                Breakfastmeal.current.value = res?.data?.data[0]?.Breakfastmeal;
                Breakfastfood.current.value = res?.data?.data[0]?.Breakfastfood;
                Breakfastremark.current.value = res?.data?.data[0]?.Breakfastremark;
                Lunchmeal.current.value = res?.data?.data[0]?.Lunchmeal;
                Lunchfood.current.value = res?.data?.data[0]?.Lunchfood;
                Lunchremark.current.value = res?.data?.data[0]?.Lunchremark;
                Snackmeal.current.value = res?.data?.data[0]?.Snackmeal;
                Snackfood.current.value = res?.data?.data[0]?.Snackfood;
                Snackremark.current.value = res?.data?.data[0]?.Snackremark;
                Dinnermeal.current.value = res?.data?.data[0]?.Dinnermeal;
                Dinnerfood.current.value = res?.data?.data[0]?.Dinnerfood;
                Dinnerremark.current.value = res?.data?.data[0]?.Dinnerremark;
                Bedmeal.current.value = res?.data?.data[0]?.Bedmeal;
                Bedfood.current.value = res?.data?.data[0]?.Bedfood;
                Bedremark.current.value = res?.data?.data[0]?.Bedremark;
                Preworkout1meal.current.value = res?.data?.data[0]?.Preworkout1meal;
                Preworkout1food.current.value = res?.data?.data[0]?.Preworkout1food;
                Preworkout1remark.current.value = res?.data?.data[0]?.Preworkout1remark;
                Preworkout2remark.current.value = res?.data?.data[0]?.Preworkout2remark;
                Preworkout2food.current.value = res?.data?.data[0]?.Preworkout2food;
                Preworkout2meal.current.value = res?.data?.data[0]?.Preworkout2meal;
                Postworkout1meal.current.value = res?.data?.data[0]?.Postworkout1meal;
                Postworkout1food.current.value = res?.data?.data[0]?.Postworkout1food;
                Postworkout1remark.current.value = res?.data?.data[0]?.Postworkout1remark;
                Postworkout2remark.current.value = res?.data?.data[0]?.Postworkout2remark;
                Postworkout2food.current.value = res?.data?.data[0]?.Postworkout2food;
                Postworkout2meal.current.value = res?.data?.data[0]?.Postworkout2meal;
                LateeveningSnackremark.current.value = res?.data?.data[0]?.Lateeveningsnackremark;
                LateeveningSnackfood.current.value = res?.data?.data[0]?.Lateeveningsnackfood;
                LateeveningSnackmeal.current.value = res?.data?.data[0]?.Lateeveningsnackmeal;
                Midmorningremark.current.value = res?.data?.data[0]?.Midmorningremark;
                Predinnerremark.current.value = res?.data?.data[0]?.Predinnerremark;
                Midmorningfood.current.value = res?.data?.data[0]?.Midmorningfood;
                Predinnerfood.current.value = res?.data?.data[0]?.Predinnerfood;
                Midmorningmeal.current.value = res?.data?.data[0]?.Midmorningmeal;
                Predinnermeal.current.value = res?.data?.data[0]?.Predinnermeal;
                Additional.current.value = res?.data?.data[0]?.Additional;

                document.getElementById("date").valueAsDate = new Date(
                  res?.data?.data[0]?.Startdate
                );
              } else {
                alert("No meal plans found");
                navigation("/manageclients");
              }
            } else {
              alert("Error fetching meal plan information", res?.data?.message);
              navigation("/manageclients");
            }
          })
          .catch((err) => {
            console.log("Error getting meal plan", err);
          });
      }
    } else {
      getClientNames();
      const today = new Date();
      // const expDate = new Date(today.setDate(today.getDate() + 15));
      document.getElementById("date").valueAsDate = today;
    }
    dispatch({ type: "ROUTES", payload: "Manage Clients" });
  }, []);
  
  //---------------------------------------------------------UseEffect to Get Meal Plan-----------------------------------------------------------------------//

  //---------------------------------------------------------Get Client Name Function-----------------------------------------------------------------------//

  function getClientNames() {
    Request.get(`${BASE_URL}client/getClientsName`)
      .then((res) => {
        if (res?.data?.status === 200) {
          setClientNames(res?.data?.data);
        } else {
          alert("Error fetching client names", res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("Error fetching client names: ", err);
      });
  }

  //---------------------------------------------------------Get Client Name Function-----------------------------------------------------------------------//
  
  //---------------------------------------------------------Set Session-----------------------------------------------------------------------//

  let sessionCount = selectedName
    ? selectedName?.Max_Sessions !== null
      ? parseInt(selectedName?.currentSession ? selectedName?.currentSession : 0) + 1 >
        selectedName?.Max_Sessions
        ? "count expired"
        : "" + (parseInt(selectedName?.currentSession ? selectedName?.currentSession : 0) + 1)
      : "Plan not purchased yet"
    : "";

  //---------------------------------------------------------Set Session-----------------------------------------------------------------------//

  return (
    <div className={styles.maindiv}>
      <div className={bootstrap["bootstrap-iso"] + " " + styles.thesixty}>
        {mask && (
          <div
            style={{ width: window.innerWidth, height: window.innerHeight }}
            className={styles.mask}
            onClick={() => {
              setMask(false);
            }}
          ></div>
        )}
        <div className={styles.formSection}>
          <label className={styles.headingName}>
            {edit ? "Edit" : "New"} Meal Plan{" "}
            {selectedName !== "Select a client" && `(Session ${sessionCount})`}
            {session && `(${session})`}
            {/* {edit} */}
          </label>
          <div className={styles.secondRowOfPI}>
            <div className={styles.casteInput}>
              <label>
                Client Name<span> *</span>
              </label>
              {edit || create ? (
                <>
                  <input
                    value={location?.state?.data?.Firstname + " " + location?.state?.data?.Lastname}
                    readOnly
                  />
                </>
              ) : (
                <ClientDropdown
                  setMask={setMask}
                  mask={mask}
                  data={clientNames}
                  selectedItem={selectedName}
                  setSelectedItem={setSelectedName}
                />
              )}
            </div>
          </div>
          <div className={styles.secondRowOfPI}>
            <div className={styles.MobileNoInput}>
              <label>
                Weight.<span> *</span>
              </label>
              <input
                type="text"
                placeholder="Weight"
                readOnly
                value={
                  selectedName?.latestWeight
                    ? selectedName?.latestWeight
                    : selectedName?.Weight
                    ? selectedName?.Weight
                    : location?.state?.data?.latestWeight
                    ? location?.state?.data?.latestWeight
                    : location?.state?.data?.Weight
                }
              />
            </div>
            <div className={styles.MobileNoInput}>
              <label>
                Start Date<span> </span>
              </label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="date"
                id="date"
              />
            </div>
          </div>

          <label className={styles.formHeadingName}>On Rising</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Risingmeal}
                id="Risingmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorRisingMeal"></span>
            </div>
          </div>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Risingfood}
                id="Risingfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorRisingPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Risingremark}
                id="Risingremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorRisingRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Pre Workout</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Preworkout1meal}
                id="Risingmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorRisingMeal"></span>
            </div>
          </div>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Preworkout1food}
                id="Risingfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorRisingPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Preworkout1remark}
                id="Risingremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorRisingRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Post Workout</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Postworkout1meal}
                id="Risingmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorRisingMeal"></span>
            </div>
          </div>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Postworkout1food}
                id="Risingfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorRisingPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Postworkout1remark}
                id="Risingremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorRisingRemark"></span>
            </div>
          </div>

          <label className={styles.formHeadingName}>Breakfast</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Breakfastmeal}
                id="Breakfastmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorBreakdastMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Breakfastfood}
                id="Breakfastfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorBreakfastPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Breakfastremark}
                id="Breakfastremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorBreakfastRemark"></span>
            </div>
          </div>

          <label className={styles.formHeadingName}>Mid Morning</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Midmorningmeal}
                id="Breakfastmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorBreakdastMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Midmorningfood}
                id="Breakfastfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorBreakfastPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Midmorningremark}
                id="Breakfastremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorBreakfastRemark"></span>
            </div>
          </div>

          <label className={styles.formHeadingName}>Lunch</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Lunchmeal}
                id="Lunchmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorLunchMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Lunchfood}
                id="Lunchfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorLunchPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Lunchremark}
                id="Lunchremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorLunchRemark"></span>
            </div>
          </div>

          <label className={styles.formHeadingName}>Snack</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Snackmeal}
                id="Snackmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorSnackMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Snackfood}
                id="Snackfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorSnackFood"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Snackremark}
                id="Snackremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorSnackRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Pre Workout</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Preworkout2meal}
                id="Snackmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorSnackMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Preworkout2food}
                id="Snackfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorSnackFood"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Preworkout2remark}
                id="Snackremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorSnackRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Post Workout</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Postworkout2meal}
                id="Snackmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorSnackMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Postworkout2food}
                id="Snackfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorSnackFood"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Postworkout2remark}
                id="Snackremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorSnackRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Late Evening Snack</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={LateeveningSnackmeal}
                id="Snackmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorSnackMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={LateeveningSnackfood}
                id="Snackfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorSnackFood"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={LateeveningSnackremark}
                id="Snackremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorSnackRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Pre Dinner</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Predinnermeal}
                id="Snackmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorSnackMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Predinnerfood}
                id="Snackfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorSnackFood"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Predinnerremark}
                id="Snackremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorSnackRemark"></span>
            </div>
          </div>

          <label className={styles.formHeadingName}>Dinner</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Dinnermeal}
                id="Dinnermeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorDinnerMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Dinnerfood}
                id="Dinnerfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorDinnerPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Dinnerremark}
                id="Dinnerremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorDinnerRemark"></span>
            </div>
          </div>

          <label className={styles.formHeadingName}>BedTime</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.countryInput}>
              <label>Meal Time</label>
              <input
                onBlur={() => {
                  cacheStorage();
                }}
                type="text"
                ref={Bedmeal}
                id="Bedmeal"
                placeholder="Meal Time"
              />
              <span className={styles.errorSpan} id="errorBedMeal"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Food Plan</label>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Bedfood}
                id="Bedfood"
                placeholder="Food Plan"
              />
              <span className={styles.errorSpan} id="errorBedPlan"></span>
            </div>
            <div className={styles.stateInput}>
              <label>Remark</label>
              <textarea
                className={styles.textareaPadidng}
                type="text"
                ref={Bedremark}
                id="Bedremark"
                placeholder="Remark"
              />
              <span className={styles.errorSpan} id="errorBedRemark"></span>
            </div>
          </div>
          <label className={styles.formHeadingName}>Physical Activity</label>

          <div className={styles.foruthRowOfPI}>
            <div className={styles.stateInput} style={{ width: "100%" }}>
              <textarea
                onBlur={() => {
                  cacheStorage();
                }}
                className={styles.textareaPadidng}
                type="text"
                ref={Additional}
                id="Bedremark"
                placeholder="Physical Activity"
              />
              <span className={styles.errorSpan} id="errorBedMeal"></span>
            </div>
          </div>
          <div className={styles.BtnRowsec}>
            <button
              className={styles.PrimaryBtncls}
              onClick={() => {
                Validate();
              }}
            >
              Submit
            </button>
            <button
              className={styles.secondaryBtncls}
              onClick={() => {
                navigation("/admindashboard");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className={styles.thefourty}>
        {selectedName !== "Select a client" ? (
          <TabularAssessment mealpage={true} className={styles.setheight} client={selectedName} />
        ) : (
          location?.state?.data && (
            <TabularAssessment
              mealpage={true}
              className={styles.setheight}
              client={location?.state?.data}
            />
          )
        )}
      </div>
    </div>
  );
}

export default NewMealPlanPage;
