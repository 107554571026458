//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef, useState } from "react";
import styles from "../assets/styles/manageRegistrationModal.module.css";
import Request from "axios";
import closedEye from "../assets/images/closedEye.png";
import openEye from "../assets/images/openEye.png";
import { BASE_URL } from "./baseURL";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//


function ManagerRegistrationModal({ setShowManagerModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();
  const number = useRef();
  const password = useRef();
  const [showPass, setShowPass] = useState(false);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [
      { id: "email", name: "errorMessageEmail", ref: email, type: "email" },
      { id: "password", name: "errorMessagePassword", ref: password, type: "password" },
      { id: "firstName", name: "errorMessageFirst", ref: firstName },
      { id: "lastName", name: "errorMessageLast", ref: lastName },
      { id: "mobileNo", name: "errorMessageMobile", ref: number, type: "number" },
    ];

    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      let regrx = new RegExp(
        val.type === "email"
          ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
          : val.type === "password"
          ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
          : val.type === "number"
          ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
          : ""
      );
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else if (!regrx.test(val.ref.current.value)) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter Valid ${val.type}`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    !error && addManager();
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Add Manager Function-----------------------------------------------------------------------//

  function addManager() {
    Request.post(`${BASE_URL}manager/addManager`, {
      Firstname: firstName?.current?.value,
      Lastname: lastName?.current?.value,
      Emailid: email?.current?.value,
      Mobilenumber: number?.current?.value,
      Password: password?.current?.value,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Manager added!");
          setShowManagerModal(false);
        } else {
          if (res?.data?.message?.code === "ER_DUP_ENTRY") {
            alert("Email id already registered!");
          } else {
            alert("Error addding manager");
            console.log("Error adding manager", res?.data?.message);
          }
        }
      })
      .catch((err) => {
        console.error("Error adding manager", err);
      });
  }

  //---------------------------------------------------------Add Manager Function-----------------------------------------------------------------------//
  return (
    <>
      <div className={styles.modalBackground}></div>
      <div className={styles.modal}>
        <div className={styles.modalContent50}>
          <div>
            <p className={styles.title}>Manager Registration</p>
          </div>
          <div className={styles.mainContainerWB}>
            <div className={styles.container}>
              <div>
                <label className={styles.font}>
                  {" "}
                  First Name <span className={styles.span}>*</span>
                </label>
              </div>
              <input
                ref={firstName}
                type="text"
                className={styles.input}
                id="firstName"
                placeholder="First Name"
                required
              ></input>
              <span className={styles.errorSpan} id="errorMessageFirst"></span>
            </div>
            <div className={styles.container}>
              <div>
                <label className={styles.font}>
                  Last Name <span className={styles.span}>*</span>
                </label>
              </div>
              <div>
                <input
                  ref={lastName}
                  type="text"
                  className={styles.input}
                  id="lastName"
                  placeholder="Last Name"
                  required
                ></input>
                <span className={styles.errorSpan} id="errorMessageLast"></span>
              </div>
            </div>
          </div>

          <div className={styles.mainContainerWB}>
            <div className={styles.container}>
              <div>
                <label className={styles.font}>
                  Email <span className={styles.span}>*</span>
                </label>
              </div>
              <div>
                <input
                  ref={email}
                  type="text"
                  className={styles.input}
                  id="email"
                  placeholder="Email"
                  required
                ></input>
                <span className={styles.errorSpan} id="errorMessageEmail"></span>
              </div>
            </div>
            <div className={styles.container}>
              <div>
                <label className={styles.font}>
                  Mobile No. <span className={styles.span}>*</span>
                </label>
              </div>
              <div>
                <input
                  ref={number}
                  type="text"
                  className={styles.input}
                  id="mobileNo"
                  placeholder="Mobile No."
                  required
                ></input>
                <span className={styles.errorSpan} id="errorMessageMobile"></span>
              </div>
            </div>
          </div>

          <div className={styles.mainContainerWB}>
            <div className={styles.container}>
              <div>
                <label className={styles.font}>
                  Password <span className={styles.span}>*</span>
                </label>
              </div>
              <div className={styles.passwordWithEye}>
                <input
                  ref={password}
                  type={showPass ? "text" : "password"}
                  className={styles.input}
                  id="password"
                  placeholder="Password"
                  required
                ></input>
                <button
                  type="button"
                  className={styles.showPasswd}
                  onClick={() => {
                    setShowPass(() => !showPass);
                  }}
                >
                  <img src={showPass ? openEye : closedEye} alt="" />
                </button>
              </div>
              <span className={styles.errorSpan} id="errorMessagePassword"></span>
              {/* <div>
                <label className={styles.font}>Password <span className={styles.span}>*</span></label>
              </div>
              <div>
                <input ref={password} type={showPass ? "text" : "password"} className={styles.input} id="password" placeholder="Password" required></input>
                <span className={styles.errorSpan} id="errorMessagePassword"></span>
              </div> */}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.forsizeoffifty}>
              <button
                className={styles.submit + " " + styles.forsizeofone}
                onClick={() => {
                  Validate();
                }}
              >
                <label className={styles.sumitText}>Submit</label>
              </button>
            </div>

            <div className={styles.forsizeoffifty}>
              <button
                className={styles.cancel + " " + styles.forsizeofone}
                onClick={() => {
                  setShowManagerModal(false);
                }}
                id="cancelBtn"
              >
                <label className={styles.cancelText}>Cancel</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagerRegistrationModal;
