//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA3 = forwardRef(
  (
    {
      workMeals,
      homeCook,
      familyType,
      familyTypeOther,
      maritalStatus,
      maritalStatusOther,
      totalKids,
      kidsAge,
      eatinghabits,
      eatingHabitsOtherRef,
      setPageIndex,
      adminData,
      profileData,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [family, setFamily] = useState("Nuclear-Family");
    const [maritalStatusRadio, setMaritalStatusRadio] = useState("Married");
    const [eatingHabits, setEatingHabits] = useState("Vegetarian");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [{ id: "homeCook", name: "errorMessageHomeCook", ref: homeCook }];

      let erId3=-1
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        let regrx = new RegExp(
          val.type === "email"
            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            : val.type === "password"
            ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
            : val.type === "number"
            ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
            : ""
        );
        if (val.ref?.current?.value?.length < 1) {
          if(erId3==-1){
            erId3=val.id
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
          document.getElementById(val.id).focus();
        } else if (!regrx.test(val.ref?.current?.value)) {
          if(erId3==-1){
            erId3=val.id
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter Valid ${val.type}`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }if(erId3!=-1){
          document.getElementById(erId3).focus();
        }
      });
      if (document.getElementById("familyTypeOther").checked) {
        if (familyTypeOther.current.value?.length < 1) {
          error = true;
          document.getElementById("familyTypeOtherfield").style.border = "2px solid red";
          document.getElementById("errorMessagefamilyTypeOther").style.display = "block";
          document.getElementById("errorMessagefamilyTypeOther").textContent = `Please Enter this field`;
        }
      } else {
        document.getElementById("familyTypeOtherfield").style.border = "1.5px solid #D9D9D9";
        document.getElementById("errorMessagefamilyTypeOther").style.display = "none";
        document.getElementById("errorMessagefamilyTypeOther").textContent = `none`;
      }

      if (document.getElementById("maritalStatusOther").checked) {
        if (maritalStatusOther.current.value?.length < 1) {
          error = true;
          document.getElementById("maritalStatusOtherfield").style.border = "2px solid red";
          document.getElementById("errorMessagemaritalStatusOther").style.display = "block";
          document.getElementById(
            "errorMessagemaritalStatusOther"
          ).textContent = `Please Enter this field`;
        }
      } else {
        document.getElementById("maritalStatusOtherfield").style.border = "1.5px solid #D9D9D9";
        document.getElementById("errorMessagemaritalStatusOther").style.display = "none";
        document.getElementById("errorMessagemaritalStatusOther").textContent = `none`;
      }

      if (document.getElementById("eatingHabitsOther").checked) {
        if (eatingHabitsOtherRef.current.value?.length < 1) {
          error = true;
          document.getElementById("eatingHabitsOtherfield").style.border = "2px solid red";
          document.getElementById("errorMessageeatingHabitsOther").style.display = "block";
          document.getElementById(
            "errorMessageeatingHabitsOther"
          ).textContent = `Please Enter this field`;
        }
      } else {
        document.getElementById("eatingHabitsOtherfield").style.border = "1.5px solid #D9D9D9";
        document.getElementById("errorMessageeatingHabitsOther").style.display = "none";
        document.getElementById("errorMessageeatingHabitsOther").textContent = `none`;
      }
      !error && setPageIndex(4);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      eatinghabits.current.value = "Vegetarian";
      maritalStatus.current.value = "Married";
      familyType.current.value = "Nuclear-Family";
      let cache = JSON.parse(localStorage.getItem("CA3"));
      let data = adminData ? adminData : profileData;

      if (adminData) {
        workMeals.current.value = data?.Carrymeal;
        homeCook.current.value = data?.Whocook;

        if (data.Familytype === "Nuclear-Family") {
          familyType.current.value = "Nuclear-Family";

          setFamily("Nuclear-Family");
        } else if (data.Familytype === "Joint Family") {
          familyType.current.value = "Joint Family";

          setFamily("Joint Family");
        } else if (data.Familytype === "Bachelor") {
          familyType.current.value = "Bachelor";
          setFamily("Bachelor");
        } else {
          setFamily("Other");
          familyType.current.value = "Other";
          familyTypeOther.current.value = data.Familytype;
        }

        if (data?.Maritalstatus === "Married") {
          maritalStatus.current.value = "Married";
          setMaritalStatusRadio("Married");
        } else if (data?.Maritalstatus === "Unmarried") {
          maritalStatus.current.value = "Unmarried";
          setMaritalStatusRadio("Unmarried");
        } else {
          maritalStatus.current.value = "Other";
          maritalStatusOther.current.value = data?.Maritalstatus;
          setMaritalStatusRadio("Other");
        }

        if (data?.Eatinghabit === "Vegetarian") {
          eatinghabits.current.value = "Vegetarian";
          setEatingHabits("Vegetarian");
        } else if (data?.Eatinghabit === "Non-Vegetarian") {
          eatinghabits.current.value = "Non-Vegetarian";
          setEatingHabits("Non-Vegetarian");
        } else if (data?.Eatinghabit === "Egg Eater") {
          eatinghabits.current.value = "Egg Eater";
          setEatingHabits("Egg Eater");
        } else if (data?.Eatinghabit === "Vegan") {
          eatinghabits.current.value = "Egg Eater";
          setEatingHabits("Vegan");
        } else {
          setEatingHabits("Other");
          eatinghabits.current.value = "Other";
          eatingHabitsOtherRef.current.value = data?.Eatinghabit;
        }
        totalKids.current.value = data?.Kids;
        kidsAge.current.value = data?.Childrenage;
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        workMeals.current.value = data?.Carrymeal;
        homeCook.current.value = data?.Whocook;

        if (data.Familytype === "Nuclear-Family") {
          familyType.current.value = "Nuclear-Family";

          setFamily("Nuclear-Family");
        } else if (data.Familytype === "Joint Family") {
          familyType.current.value = "Joint Family";

          setFamily("Joint Family");
        } else if (data.Familytype === "Bachelor") {
          familyType.current.value = "Bachelor";
          setFamily("Bachelor");
        } else {
          setFamily("Other");
          familyType.current.value = "Other";
          familyTypeOther.current.value = data.Familytype;
        }

        if (data?.Maritalstatus === "Married") {
          maritalStatus.current.value = "Married";
          setMaritalStatusRadio("Married");
        } else if (data?.Maritalstatus === "Unmarried") {
          maritalStatus.current.value = "Unmarried";
          setMaritalStatusRadio("Unmarried");
        } else {
          maritalStatus.current.value = "Other";
          maritalStatusOther.current.value = data?.Maritalstatus;
          setMaritalStatusRadio("Other");
        }

        if (data?.Eatinghabit === "Vegetarian") {
          eatinghabits.current.value = "Vegetarian";
          setEatingHabits("Vegetarian");
        } else if (data?.Eatinghabit === "Non-Vegetarian") {
          eatinghabits.current.value = "Non-Vegetarian";
          setEatingHabits("Non-Vegetarian");
        } else if (data?.Eatinghabit === "Egg Eater") {
          eatinghabits.current.value = "Egg Eater";
          setEatingHabits("Egg Eater");
        } else if (data?.Eatinghabit === "Vegan") {
          eatinghabits.current.value = "Egg Eater";
          setEatingHabits("Vegan");
        } else {
          setEatingHabits("Other");
          eatinghabits.current.value = "Other";
          eatingHabitsOtherRef.current.value = data?.Eatinghabit;
        }
        totalKids.current.value = data?.Kids;
        kidsAge.current.value = data?.Childrenage;
      }
    }, []);

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      workMeals.current.value = cache.Carrymeal;
      homeCook.current.value = cache.Whocook;

      if (cache.Familytype === "Nuclear-Family") {
        familyType.current.value = "Nuclear-Family";

        setFamily("Nuclear-Family");
      } else if (cache.Familytype === "Joint Family") {
        familyType.current.value = "Joint Family";

        setFamily("Joint Family");
      } else if (cache.Familytype === "Bachelor") {
        familyType.current.value = "Bachelor";
        setFamily("Bachelor");
      } else {
        setFamily("Other");
        familyType.current.value = "Other";
        familyTypeOther.current.value = cache.Familytype;
      }
      if (cache?.Maritalstatus === "Married") {
        maritalStatus.current.value = "Married";
        setMaritalStatusRadio("Married");
      } else if (cache?.Maritalstatus === "Unmarried") {
        maritalStatus.current.value = "Unmarried";
        setMaritalStatusRadio("Unmarried");
      } else {
        maritalStatus.current.value = "Other";
        maritalStatusOther.current.value = cache?.Maritalstatus;
        setMaritalStatusRadio("Other");
      }
      if (cache?.Eatinghabit === "Vegetarian") {
        eatinghabits.current.value = "Vegetarian";

        setEatingHabits("Vegetarian");
      } else if (cache?.Eatinghabit === "Non-Vegetarian") {
        eatinghabits.current.value = "Non-Vegetarian";

        setEatingHabits("Non-Vegetarian");
      } else if (cache?.Eatinghabit === "Egg Eater") {
        eatinghabits.current.value = "Egg Eater";
        setEatingHabits("Egg Eater");
      } else if (cache?.Eatinghabit === "Vegan") {
        eatinghabits.current.value = "Vegan";
        setEatingHabits("Vegan");
      } else {
        setEatingHabits("Other");
        eatinghabits.current.value = "Vegan";
        eatingHabitsOtherRef.current.value = cache?.Eatinghabit;
      }
      totalKids.current.value = cache.Kids;
      kidsAge.current.value = cache.Childrenage;
    }
    
    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.additionalInformationOneForm}>
        <label className={style.additionalInformationOneLabel}>Additional Information</label>
        <div className={style.firstRowOfAI1 + " " + style.maroftop}>
          <div className={style.workMealsInput}>
            <label>How many meals you carry to work?</label>
            <input
              readOnly={adminData ? true : false}
              ref={workMeals}
              type="text"
              placeholder="Meals No."
              id="workMeals"
            />
          </div>
          <div className={style.homeMealsInput}>
            <label>
              Who cooks food at home.?<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={homeCook}
              type="text"
              placeholder="Who Cook"
              id="homeCook"
            />
            <span className={style.errorSpan} id="errorMessageHomeCook"></span>
          </div>
        </div>
        <div className={style.secondRowOfAI1}>
          <div className={style.familyTypeRadioInput}>
            <label ref={familyType}>
              Type of Family?<span> *</span>
            </label>
            <div className={style.familyTypeRadioDiv}>
              <div className={style.familyTypeRadioNuclear}>
                <input
                  readOnly={adminData ? true : false}
                  id="familyTypeNuclear"
                  onChange={() => {
                    if (!adminData) {
                      setFamily("Nuclear-Family");
                      familyType.current.value = "Nuclear-Family";
                    }
                  }}
                  type="radio"
                  name="familyTypeRadioSelection"
                  checked={family == "Nuclear-Family" ? true : false}
                />
                <label htmlFor="familyTypeNuclear">Nuclear Family</label>
              </div>
              <div className={style.familyTypeRadioJoint}>
                <input
                  readOnly={adminData ? true : false}
                  id="familyTypeJoint"
                  onChange={() => {
                    if (!adminData) {
                      setFamily("Joint Family");
                      familyType.current.value = "Joint Family";
                    }
                  }}
                  checked={family == "Joint Family" ? true : false}
                  type="radio"
                  name="familyTypeRadioSelection"
                />
                <label htmlFor="familyTypeJoint">Joint Family</label>
              </div>
              <div className={style.familyTypeRadioBachelor}>
                <input
                  readOnly={adminData ? true : false}
                  id="familyTypeBachelor"
                  onChange={() => {
                    if (!adminData) {
                      setFamily("Bachelor");
                      familyType.current.value = "Bachelor";
                    }
                  }}
                  type="radio"
                  name="familyTypeRadioSelection"
                  checked={family == "Bachelor" ? true : false}
                />
                <label htmlFor="familyTypeBachelor">Bachelor</label>
              </div>
              <div className={style.familyTypeRadioNone}>
                <input
                  readOnly={adminData ? true : false}
                  id="familyTypeOther"
                  onChange={() => {
                    if (!adminData) {
                      setFamily("Other");
                      familyType.current.value = "Other";
                    }
                  }}
                  type="radio"
                  name="familyTypeRadioSelection"
                  checked={family == "Other" ? true : false}
                />
                <label htmlFor="familyTypeOther">Other</label>
              </div>
            </div>
          </div>
          <div className={style.familyTypeOtherInput}>
            <label>
              Other<span> </span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={familyTypeOther}
              type="text"
              placeholder="Other"
              id="familyTypeOtherfield"
            />
            <span className={style.errorSpan} id="errorMessagefamilyTypeOther"></span>
          </div>
        </div>
        <div className={style.thirdRowOfAI1}>
          <div className={style.maritalStatusRadioInput}>
            <label ref={maritalStatus}>
              Marital Status<span> *</span>
            </label>
            <div className={style.maritalStatusRadioDiv}>
              <div
                className={style.maritalStatusRadioFrequent}
                onClick={() => {
                  if (!adminData) {
                    maritalStatus.current.value = "Married";
                    setMaritalStatusRadio("Married");
                  }
                }}
              >
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {}}
                  id="maritalStatusMarried"
                  type="radio"
                  name="maritalStatusRadioSelection"
                  checked={maritalStatusRadio === "Married" ? true : false}
                />
                <label htmlFor="maritalStatusMarried">Married</label>
              </div>
              <div
                className={style.maritalStatusRadioOneTime}
                // onClick={() => {
                //   maritalStatus.current.value = "Unmarried";

                // }}
              >
                <input
                  readOnly={adminData ? true : false}
                  id="maritalStatusUnmarried"
                  type="radio"
                  name="maritalStatusRadioSelection"
                  onChange={() => {
                    if (!adminData) {
                      setMaritalStatusRadio("Unmarried");
                      maritalStatus.current.value = "Unmarried";
                    }
                  }}
                  checked={maritalStatusRadio == "Unmarried" ? true : false}
                />
                <label htmlFor="maritalStatusUnmarried">Unmarried</label>
              </div>
              <div className={style.maritalStatusRadioNone}>
                <input
                  readOnly={adminData ? true : false}
                  id="maritalStatusOther"
                  onChange={() => {
                    if (!adminData) {
                      maritalStatus.current.value = "Other";
                      setMaritalStatusRadio("Other");
                    }
                  }}
                  checked={maritalStatusRadio == "Other" ? true : false}
                  type="radio"
                  name="maritalStatusRadioSelection"
                />
                <label htmlFor="maritalStatusOther">Other</label>
              </div>
            </div>
          </div>
          <div className={style.maritalOtherInput}>
            <label>
              Other<span> </span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={maritalStatusOther}
              type="text"
              placeholder="Other"
              id="maritalStatusOtherfield"
            />
            <span className={style.errorSpan} id="errorMessagemaritalStatusOther"></span>
          </div>
        </div>
        <div className={style.foruthRowOfAI1}>
          <div className={style.kidsInput}>
            <label>How many kids you have?</label>
            <input
              readOnly={adminData ? true : false}
              ref={totalKids}
              type="text"
              placeholder="Kids"
              id="totalKids"
            />
          </div>
          <div className={style.kidsAgeInput}>
            <label>Age of Children?</label>
            <input
              readOnly={adminData ? true : false}
              ref={kidsAge}
              type="text"
              placeholder="Age of Children"
              id="kidsAge"
            />
          </div>
        </div>
        <div className={style.fifthRowOfAI1}>
          <div className={style.eatingHabitsRadioInput}>
            <label ref={eatinghabits} className={style.maroftop}>
              Eating Habits<span> *</span>
            </label>
            <div className={style.eatingHabitsRadioDiv}>
              <div className={style.eatingHabitsRadioVeg}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    if (!adminData) {
                      setEatingHabits("Vegetarian");
                      eatinghabits.current.value = "Vegetarian";
                    }
                  }}
                  type="radio"
                  name="eatingHabitsRadioSelection"
                  id="eatinghabitsvegradio"
                  checked={eatingHabits === "Vegetarian" ? true : false}
                />
                <label htmlFor="eatinghabitsvegradio">Vegetarian</label>
              </div>
              <div className={style.eatingHabitsRadioNonVeg}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    if (!adminData) {
                      setEatingHabits("Non-Vegetarian");
                      eatinghabits.current.value = "Non-Vegetarian";
                    }
                  }}
                  type="radio"
                  name="eatingHabitsRadioSelection"
                  id="eatinghabitsnonvegradio"
                  checked={eatingHabits == "Non-Vegetarian" ? true : false}
                />
                <label htmlFor="eatinghabitsnonvegradio">Non-Vegetarian</label>
              </div>
              <div className={style.eatingHabitsRadioEgg}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    if (!adminData) {
                      setEatingHabits("Egg Eater");
                      eatinghabits.current.value = "Egg Eater";
                    }
                  }}
                  type="radio"
                  name="eatingHabitsRadioSelection"
                  id="eatinghabitseggradio"
                  checked={eatingHabits === "Egg Eater" ? true : false}
                />
                <label htmlFor="eatinghabitseggradio">Egg Eater</label>
              </div>
              <div className={style.eatingHabitsRadioVegan}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    if (!adminData) {
                      setEatingHabits("Vegan");
                      eatinghabits.current.value = "Vegan";
                    }
                  }}
                  type="radio"
                  name="eatingHabitsRadioSelection"
                  id="eatinghabitsveganradio"
                  checked={eatingHabits === "Vegan" ? true : false}
                />
                <label htmlFor="eatinghabitsveganradio">Vegan</label>
              </div>
              <div className={style.eatingHabitsRadioOther}>
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {
                    if (!adminData) {
                      setEatingHabits("Other");
                      eatinghabits.current.value = "Other";
                    }
                  }}
                  type="radio"
                  name="eatingHabitsRadioSelection"
                  id="eatingHabitsOther"
                  checked={eatingHabits === "Other" ? true : false}
                />
                <label htmlFor="eatingHabitsOther">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.sixthRowOfAI1}>
          <div className={style.eatingHabitsotherInput}>
            <label className={style.maroftop}>
              Other<span></span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={eatingHabitsOtherRef}
              type="text"
              placeholder="Other"
              id="eatingHabitsOtherfield"
            />
            <span className={style.errorSpan} id="errorMessageeatingHabitsOther"></span>
          </div>
        </div>
      </div>
    );
  }
);

export default CA3;
