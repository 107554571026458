//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ManageClients.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import $ from "jquery";
import Request from "axios";
import style from "../../assets/styles/planHistory.module.css";
import { JsonToExcel } from "react-json-to-excel";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function PlanHistory() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [mask, setMask] = useState();
  const navigation = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to get Plan History-----------------------------------------------------------------------//

  useEffect(() => {
    Request.get(`${BASE_URL}plans/showplanHistory?id=${data?.id}`)
      .then((res) => {
        setList(res?.data?.data);
        $(() => {
          $("#myTable").DataTable();
        });
        let exceltemp = [];
        res?.data?.data?.map((val) => {
          exceltemp.push({
            PurchaseDate: val.Purchasedate,
            ExpiryDate: val.Expirydate,
            PlanStatus: val.Isexpired,
            ClientName: val.Firstname + val.Lastname,
            Type: val.Type,
          });
        });
        setExcelList(exceltemp);
        setTimeout(() => {
          styleChanges();
        }, [200]);
      })
      .catch((err) => {
        alert("Error fetching plan history", err);
        console.log("Error fetching plan history", err);
      });
    dispatch({ type: "ROUTES", payload: "Plan History" });
  }, []);

  //---------------------------------------------------------UseEffect to get Plan History-----------------------------------------------------------------------//
  
  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  function styleChanges() {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document.querySelectorAll(".dataTables_wrapper").forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginBottom = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document.querySelectorAll(".dataTables_length").forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
    document
      .querySelectorAll(".dataTables_wrapper .dataTables_info")
      .forEach((el) => (el.style.paddingLeft = "10px"));
    document.querySelectorAll(".paginate_button").forEach((el) => (el.style.color = "#FFFFFF"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.background = "#00C1BD"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.border = "1px solid #00C1BD"));
    document.querySelectorAll(".paginate_button").forEach((el) => (el.style.borderRadius = "4px"));
  }

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//
 
  //---------------------------------------------------------UseEffect to style Table-----------------------------------------------------------------------//

  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = mask ? "150px" : "50px"));
  }, [mask]);

  //---------------------------------------------------------UseEffect to style Table-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to change style automatically-----------------------------------------------------------------------//

  useEffect(() => {
    setTimeout(() => {
      styleChanges();
    }, [200]);
  }, []);
  
  //---------------------------------------------------------UseEffect to change style automatically-----------------------------------------------------------------------//

  return (
    <div className={style.PlanHistoryTrackerMainDiv}>
      <div
        className={style.backButtonDiv}
        onClick={() => {
          navigation("/manageclients");
        }}
      >
        {/* <img src={} alt="" /> */}
        <label> &lt; Back</label>
      </div>

      <div className={style.setwidth}>
        <p className={style.topLabel}>
          {data?.Firstname} {data?.Lastname}
        </p>
        <JsonToExcel
          title="Excel Export"
          data={excelList}
          fileName="Manager List"
          btnClassName={style.customexportButton}
        />
      </div>

      <table
        id="myTable"
        className={bootstrap[("table", "table-striped")] + " " + styles.tableCustomcls}
      >
        <thead>
          <tr>
            <th className={styles.Tablefontcls}>Plan Purchase date </th>
            <th className={styles.Tablefontcls}>Plan Expiration date </th>
            <th className={styles.Tablefontcls}>Plan Expired </th>
            {/* <th className={styles.Tablefontcls}>Client Name </th> */}
            <th className={styles.Tablefontcls}>Plan Type</th>
            <th className={styles.Tablefontcls}>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((val, i) => {
              return (
                <tr key={String(i)}>
                  <td className={styles.Tablefonttdcls}>
                    {moment(val?.Purchasedate ? val?.Purchasedate : "-").format("YYYY-MM-DD")}
                  </td>
                  <td className={styles.Tablefonttdcls}>
                    {moment(val?.Expirydate ? val?.Expirydate : "-").format("YYYY-MM-DD")}
                  </td>
                  <td className={styles.Tablefonttdcls}>{val?.Isexpired ? val?.Isexpired : "-"}</td>
                  {/* <td className={styles.Tablefonttdcls}>
                    {val?.Firstname ? val?.Firstname : "-"}
                    {val?.Lastname ? val?.Lastname : "-"}
                  </td> */}
                  <td className={styles.Tablefonttdcls}>
                    {val?.Timeduration ? val?.Timeduration : "-"}
                  </td>
                  <td
                    className={styles.Tablefonttdcls + " " + styles.decor}
                    onClick={() => {
                      localStorage.setItem("pageData", val.Client_id);
                      window.open("invoicepdf");
                    }}
                  >
                    Download Invoice
                  </td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PlanHistory;
