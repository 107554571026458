//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ManageClients.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import $ from "jquery";
import "datatables.net"; // Ensure DataTables is imported properly
import Request from "axios";
import style from "../../assets/styles/weightTrackerGrid.module.css";
import MealPlanDropdown from "../../components/MealPlanDropdown";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function MealPlanHistory() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const navigation = useNavigate();
  const temp = JSON.parse(localStorage?.getItem("user"));
  const location = useLocation();
  const data = location?.state?.data;
  const [mask, setMask] = useState();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to get Meal Plan History -----------------------------------------------------------------------//
  useEffect(() => {
    const fetchMealPlanHistory = async () => {
      try {
        const response = await Request.get(
          `${BASE_URL}client/showclientMealPlanHistory?id=${data?.Client_id}`
        );
        setList(response?.data?.data);
        console.log(response?.data?.data);

        $(() => {
          $("#myTable").DataTable({});
        });
        setTimeout(() => {
          styleChanges();
        }, 200);
      } catch (err) {
        alert("Error fetching clients");
        console.error("Error fetching clients", err);
      }
    };

    fetchMealPlanHistory();
  }, [data?.Client_id]);
  //---------------------------------------------------------UseEffect to get Meal Plan History -----------------------------------------------------------------------//

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//
  const styleChanges = () => {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginBottom = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
    document
      .querySelectorAll(".dataTables_wrapper .dataTables_info")
      .forEach((el) => (el.style.paddingLeft = "10px"));
    document
      .querySelectorAll(".paginate_button")
      .forEach((el) => (el.style.color = "#FFFFFF"));
    document
      .querySelectorAll(".paginate_button")
      .forEach((el) => (el.style.borderRadius = "4px"));
  };
  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to style Table -----------------------------------------------------------------------//
  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = mask ? "150px" : "50px"));
  }, [mask]);
  //---------------------------------------------------------UseEffect to style Table -----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Dispatch Routes -----------------------------------------------------------------------//
  useEffect(() => {
    dispatch({ type: "ROUTES", payload: "Meal Plan History" });
  }, [dispatch]);
  //---------------------------------------------------------UseEffect to Dispatch Routes -----------------------------------------------------------------------//

  return (
    <div className={style.weightTrackerMainDiv}>
      {mask && (
        <div
          style={{ width: window.innerWidth, height: window.innerHeight }}
          className={styles.mask}
          onClick={() => setMask(false)}
        ></div>
      )}
      <div
        className={style.backButtonDiv}
        onClick={() => {
          temp?.Role === "Admin"
            ? navigation("/manageclients")
            : navigation("/clientdashboard");
        }}
      >
        <label> &lt; Back</label>
      </div>
      <table
        id="myTable"
        className={`${bootstrap.table} ${bootstrap["table-striped"]} ${styles.tableCustomcls}`}
      >
        <thead>
          <tr>
            <th className={styles.Tablefontcls}>Index</th>
            <th className={styles.Tablefontcls}>Start Date</th>
            <th className={styles.Tablefontcls}>End Date</th>
            <th className={styles.Tablefontcls}>Session</th>
            <th className={styles.Tablefontcls}>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((val, i) => (
              <tr key={String(i)}>
                <td className={styles.Tablefonttdcls}>{val?.PlanIndex}</td>
                <td className={styles.Tablefonttdcls}>
                  {moment(val?.Startdate).format("YYYY-MM-DD")}
                </td>
                <td className={styles.Tablefonttdcls}>
                  {moment(val?.Enddate).format("YYYY-MM-DD")}
                </td>
                <td className={styles.Tablefonttdcls}>{val?.Session}</td>
                <td className={styles.Tablefonttdcls}>{val?.Type}</td>
                <td>
                  <MealPlanDropdown
                    setMask={setMask}
                    mask={mask}
                    id={i}
                    data={val}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MealPlanHistory;
