//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef } from "react";
import style from "../assets/styles/RaiseQuery.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function QueryModal({ setShowQueryModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const userCache = JSON.parse(localStorage.getItem("user"));
  const QueryMessagearea = useRef();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //--------------------------------------------------------------------Raise Query Function---------------------------------------------------------------------------//

  function sendQuery() {
    Request.post(`${BASE_URL}client/raiseQuery`, {
      Emailid: userCache.Emailid,
      Body: QueryMessagearea.current.value,
    })
      .then(() => {
        alert("Query sent!");
        setShowQueryModal(false);
      })
      .catch((err) => {
        console.log("Error sending query", err);
      });
  }

  //--------------------------------------------------------------------Raise Query Function----------------------------------------------------------------------------//
  
  //--------------------------------------------------------------------Validate Function---------------------------------------------------------------------------//

  async function Validate() {
    let error = false;
    let checklist = [
      { id: "messageArea", name: "errorMessageArea", ref: QueryMessagearea, type: "text" },
    ];

    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);

      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
    });
    !error && sendQuery();
  }
  
  //--------------------------------------------------------------------Validate Function---------------------------------------------------------------------------//
  return (
    <div id="myModal" className={style.modal}>
      <div className={style.modalContent}>
        <span className={style.close} onClick={() => setShowQueryModal(false)}>
          &times;
        </span>
        <label className={style.popUpHeading}>Raise Query</label>
        <div className={style.uploadmaindiv}>
          <label className={style.popUpHeadinglabel}>
            Message<span className={style.messagelabelrequired}>*</span>
          </label>
          <textarea
            ref={QueryMessagearea}
            id="messageArea"
            name="messageArea"
            className={style.messageAreacls}
          ></textarea>
          <span className={style.errorSpan} id="errorMessageArea"></span>
        </div>
        <div className={style.BtnRowsec}>
          <button
            className={style.PrimaryBtncls}
            onClick={() => {
              Validate();
            }}
          >
            Submit
          </button>
          <button
            className={style.secondaryBtncls}
            onClick={() => {
              setShowQueryModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default QueryModal;
