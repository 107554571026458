//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import uploadIcon from "../assets/images/cloudIcon.png";
import style from "../assets/styles/PhotoModal.module.css";
//import style from "../assets/styles/ClientDashboard.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function PhotoModal({ setShowPhotoModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const upload1 = useRef();
  const upload2 = useRef();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const userCache = JSON.parse(localStorage.getItem("user"));
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------Get Image Data Function-----------------------------------------------------------------------//

  function getImgData(id, img) {
    const imgPreview = document.getElementById(id);
    const files = img;
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        imgPreview.style.display = "flex";
        imgPreview.innerHTML =
          '<img src="' +
          this.result +
          `"  style="width: 100% ; max-height:300px; max-width: 250px;">`;
      });
    }
  }

  //---------------------------------------------------------Get Image Data Function-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Validate Image1 Size-----------------------------------------------------------------------//

  useEffect(() => {
    if (img1?.size > 1000000) {
      alert("Please upload file of size 1MB or less!");
      setImg1();
    } else {
      img1 && getImgData("img-preview", img1);
    }
  }, [img1]);

  //---------------------------------------------------------UseEffect to Validate Image1 Size-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Validate Image2 Size-----------------------------------------------------------------------//

  useEffect(() => {
    if (img2?.size > 1000000) {
      alert("Please upload file of size 1MB or less!");
      setImg2();
    } else {
      img2 && getImgData("img-preview2", img2);
    }
  }, [img2]);

  //---------------------------------------------------------UseEffect to Validate Image2 Size-----------------------------------------------------------------------//

  //---------------------------------------------------------Send Image Function-----------------------------------------------------------------------//

  function sendImageData() {
    const data = new FormData();
    data.append("photo", img1);
    data.append("Client_id", userCache?.id);
    data.append("Session", userCache?.Session);
    data.append("Mealplan_id", 1);
    Request.post(`${BASE_URL}tracker/addPhotoTrackerBefore`, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          const data = new FormData();
          data.append("photo", img2);
          data.append("id", res?.data?.results?.insertId);

          Request.put(`${BASE_URL}tracker/updatePhotoTracker`, data)
            .then((res) => {
              if (res?.data?.status === 200) {
                alert("Photo uploaded");
                setShowPhotoModal(false);
              } else {
                alert("Error adding images");
                console.log("Error adding images 2", res?.data);
              }
            })
            .catch((err) => {
              console.log("Error adding photo tracker image 2", err);
            });
        } else if (res?.data?.message === "Photo tracker exausted for the session") {
          alert("Maximum photo entries reached for this session!");
          setShowPhotoModal(false);
        } else if (res?.data?.message === "No meal plan assigned") {
          alert("Wait till a mill plan is assigned to you!");
          setShowPhotoModal(false);
        } else {
          alert("Error uploading image 1");
          console.log("Error uploading image 1", res?.data);
        }
      })
      .catch((err) => {
        console.log("Error sending photo after", err);
      });
  }

  //---------------------------------------------------------Send Image Function-----------------------------------------------------------------------//

  return (
    <div id="myModal" className={style.modal}>
      <div className={style.modalContent}>
        <span className={style.close} onClick={() => setShowPhotoModal(false)}>
          &times;
        </span>
        <label className={style.popUpHeading}>Photo Tracker</label>
        <div className={style.uploadmaindiv}>
          <div className={style.photoUploadsec}>
            <div className={style.photouploadsecRow}>
              <div
                className={style.photouploadsecOne}
                onClick={() => {
                  upload1.current.click();
                }}
              >
                <label className={style.uploadHeading}>Before</label>
                <input
                  type="file"
                  id="file"
                  ref={upload1}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    setImg1(file);
                  }}
                />
                {
                  <div
                    id="img-preview"
                    className={style.imgPreview}
                    style={{ display: !img1 && "none" }}
                  ></div>
                }
                {!img1 && (
                  <>
                    <div className={style.fileuploader}>
                      <div id="upload-label">
                        <img className={style.uploadIconcls} src={uploadIcon} alt="" />
                      </div>
                      <div className={style.title}>Upload photo</div>
                      <div className={style.fileExcls}>jpg</div>
                    </div>
                  </>
                )}
                <div className={style.previewContainer}>
                  <div className={style.collection + " " + style.card} id="previews">
                    <div
                      className={
                        style.collectionitem +
                        " " +
                        style.clearhack +
                        " " +
                        style.valignwrapper +
                        " " +
                        style.itemtemplate
                      }
                      id="zdrop-template"
                    >
                      <div
                        className={style.left + " " + style.pv + " " + style.zdropinfo}
                        data-dz-thumbnail
                      >
                        <div>
                          <span data-dz-name></span> <span data-dz-size></span>
                        </div>
                        <div className={style.progress}>
                          <div className={style.determinate} data-dz-uploadprogress></div>
                        </div>
                        <div className={style.dzerrormessage}>
                          <span data-dz-errormessage></span>
                        </div>
                      </div>

                      <div className={style.secondarycontent + " " + style.actions}>
                        <a
                          href="#!"
                          data-dz-remove
                          className={
                            style.btnfloating +
                            " " +
                            style.ph +
                            " " +
                            style.red +
                            " " +
                            style.whitetext +
                            " " +
                            style.waveseffect +
                            " " +
                            style.waveslight
                          }
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={style.photouploadsecTwo}
                onClick={() => {
                  upload2.current.click();
                }}
              >
                <label className={style.uploadHeading}>After</label>
                <input
                  type="file"
                  id="file"
                  ref={upload2}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    setImg2(file);
                  }}
                />
                <div
                  id="img-preview2"
                  className={style.imgPreview2}
                  style={{ display: !img2 && "none" }}
                ></div>

                {!img2 && (
                  <>
                    <div className={style.fileuploader}>
                      <div id="upload-label">
                        <img className={style.uploadIconcls} src={uploadIcon} alt="" />
                      </div>
                      <div className={style.title}>Upload photo</div>
                      <div className={style.fileExcls}>jpg</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={style.BtnRowsec}>
          <button
            className={style.PrimaryBtncls}
            onClick={() => {
              if (img1 && img2) {
                sendImageData();
              } else {
                alert("Select images first!");
              }
            }}
          >
            Submit
          </button>
          <button
            className={style.secondaryBtncls}
            onClick={() => {
              setShowPhotoModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default PhotoModal;
