//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ManageClients.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import $ from "jquery";
import Request from "axios";
import style from "../../assets/styles/clientHistory.module.css";
import { JsonToExcel } from "react-json-to-excel";
import AdminWeightTracker from "./AdminWeightTracker";
import AdminInchTracker from "./AdminInchTracker";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ClientHistory() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  return (
    <div>
      <AdminWeightTracker />
      <AdminInchTracker />
    </div>
  );
}

export default ClientHistory;
