//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from "react";
import styles from "../assets/styles/Parent.module.css";
import newRegNotiLogo from "../assets/images/newRegNotiLogo.png";
import { BASE_URL } from "./baseURL";
import Request from "axios";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function PlanNotification({ name, email, MobileNumber, Type, refresh, id, Receipt, Purchasedate }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  let todays_date = moment();
  let CreatedDate = moment(Purchasedate);
  let dateDiff = todays_date.diff(CreatedDate, "days");
  let duration = "";
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //-------------------------------------------------------------------Set Duration-----------------------------------------------------------------------------//

  if (dateDiff == 1) {
    duration = "1 day ago";
  } else if (dateDiff == 0) {
    duration = "Today";
  } else {
    duration = `${dateDiff} days ago`;
  }

  //-------------------------------------------------------------------Set Duration-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Approve Plan Function-----------------------------------------------------------------------------//

  function approve() {
    Request.post(`${BASE_URL}plans/approvePlan`, { id: id, Emailid: email })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Approved Successfully");
          refresh();
        }
      })
      .catch((err) => {
        console.log("Error approving plan: ", err);
      });
  }

  //-------------------------------------------------------------------Approve Plan Function-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Decline Plan Function-----------------------------------------------------------------------------//

  function decline() {
    Request.post(`${BASE_URL}plans/declinePlan`, { id: id })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Declined Successfully");
          refresh();
        }
      })
      .catch((err) => {
        console.log("Error approving plan: ", err);
      });
  }

  //-------------------------------------------------------------------Decline Plan Function-----------------------------------------------------------------------------//
  return (
    <>
      <div className={styles.divider}></div>
      <div className={styles.notificationOne}>
        <div className={styles.notificationOneInnerDivOneAdmin}>
          <img src={newRegNotiLogo} alt="" />
          <div className={styles.titleTextsAndDates}>
            <label className={styles.titleApprovedNoti}>New Purchase ({duration})</label>
            <label className={styles.infoApprovedNoti}>
              Name: <span>{name}</span>
            </label>
            <label className={styles.dateApprovedNoti}>
              Email: <span>{email}</span>
            </label>
            <label className={styles.dateApprovedNoti}>
              Number: <span>{MobileNumber}</span>
            </label>
            <label className={styles.dateApprovedNoti}>
              Purchased Plan: <span>{Type}</span>
            </label>
            <label className={styles.dateApprovedNoti}>
              <button
                onClick={() => {
                  let url = `https://server.healvick.com/receipt/${Receipt}`;
                  window.open(url);
                }}
              >
                View Receipt
              </button>
            </label>
          </div>
        </div>
        <div className={styles.notificationOneButtonTwoAdmin}>
          <button
            onClick={() => {
              approve();
            }}
          >
            Accept
          </button>
          <button
            onClick={() => {
              decline();
            }}
          >
            Reject
          </button>
        </div>
      </div>
    </>
  );
}

export default PlanNotification;
