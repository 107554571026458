//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import style from "../assets/styles/ClientDashboard.module.css";
import Request from "axios";
import { BASE_URL } from "./baseURL";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function MealModal({ setShowMealModal, id, planId }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("user")));
  const [plans, setPlans] = useState();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //-------------------------------------------------------------------Use Effect to set side bar--------------------------------------------------------------------------//

  useEffect(() => {
    if (!setShowMealModal) {
      document.getElementById("sidebarMainDiv").style.zIndex = 5;
    } else {
      document.getElementById("sidebarMainDiv").style.zIndex = 1;
    }
  }, []);

  //-------------------------------------------------------------------Use Effect to set sidebar--------------------------------------------------------------------------//

  
  //-------------------------------------------------------------------Get All Meal Function-----------------------------------------------------------------------------//

  async function getAllMeals(data) {
    Request.get(`${BASE_URL}plans/getAllMealPlans?id=${data.id}&planid=${data.Planid}`)
      .then((res) => {
        if (res?.data?.status === 200) {
          setPlans(res?.data?.data);
        } else {
          alert("error fetching meal plans");
          console.log("Error fetching meal plans", res?.data?.error);
        }
      })
      .catch((err) => {
        console.log("Error fetching meals", err);
      });
  }

  //-------------------------------------------------------------------Get All Meal Function-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Use Effect to set GetAllMeals--------------------------------------------------------------------------//

  useEffect(() => {
    if (userDetails) {
      if (id > -1 && planId > -1 && id != "NaN" && planId != "NaN") {
        getAllMeals({ id: id, Planid: planId });
      } else {
        getAllMeals(userDetails);
      }
    } else {
      let temp = JSON.parse(localStorage.getItem("user"));
      setUserDetails(temp);
    }
  }, [userDetails]);

  //-------------------------------------------------------------------Use Effect to set GetAllMeals--------------------------------------------------------------------------//

  return (
    <div id="myModal" className={style.modal}>
      <div className={style.modalContent}>
        <span className={style.close} onClick={() => setShowMealModal(false)}>
          &times;
        </span>
        <label className={style.popUpHeading}>Download Meal Plan</label>
        <div className={style.MainContentRow}>
          {plans?.length > 0 ? (
            plans?.map((val, i) => {
              return (
                <div className={style.dietSession} key={String(i)}>
                  <label className={style.labelHeading}>Meal Plan {parseInt(i) + 1}</label>
                  <button
                    className={style.secondaryBtncls + " " + style.downloadPdfcls}
                    onClick={() => {
                      localStorage.setItem("planpdf", JSON.stringify(val));
                      window.open("/planpdf");
                    }}
                  >
                    Download PDF
                  </button>
                </div>
              );
            })
          ) : (
            <>No meal plans assigned yet.</>
          )}
        </div>
      </div>
    </div>
  );
}

export default MealModal;
