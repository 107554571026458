//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ManageClients.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import $ from "jquery";
import Request from "axios";
import style from "../../assets/styles/clientHistory.module.css";
import { JsonToExcel } from "react-json-to-excel";
import DeleteDropdown from "../../components/DeleteDropdown";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function AdminWeightTracker() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [mask, setMask] = useState();
  const navigation = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;

  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Show Client Plan History-----------------------------------------------------------------------//

  useEffect(() => {
    getData();
  }, []);

  //---------------------------------------------------------useEffect to Show Client Plan History-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Show Client Plan History-----------------------------------------------------------------------//

  function getData() {
    Request.get(
      `${BASE_URL}client/showclientWeightHistory?id=${
        data?.Rid ? data?.Rid : data?.id
      }`
    )
      .then((res) => {
        setList(res?.data?.data);
        $(() => {
          $("#myTable").DataTable({
            order: [[1, "asc"]],
          });
        });
        let exceltemp = [];
        res?.data?.data?.map((val) => {
          exceltemp.push({
            Session: val.Session,
            Date: val.Date,
            Weight: val.Weight,
            WeightDifference: val.Weightdifference,
          });
        });
        setExcelList(exceltemp);
        setTimeout(() => {
          styleChanges();
        }, []);
      })
      .catch((err) => {
        alert("Error fetching plan history", err);
        console.log("Error fetching plan history", err);
      });
  }

  //---------------------------------------------------------useEffect to Show Client Plan History-----------------------------------------------------------------------//

  function styleChanges() {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
    document
      .querySelectorAll(".dataTables_wrapper .dataTables_info")
      .forEach((el) => (el.style.paddingLeft = "10px"));
    document
      .querySelectorAll(".paginate_button")
      .forEach((el) => (el.style.color = "#FFFFFF"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.background = "#00C1BD"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.border = "1px solid #00C1BD"));
    document
      .querySelectorAll(".paginate_button")
      .forEach((el) => (el.style.borderRadius = "4px"));
  }
  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = mask ? "150px" : "50px"));
  }, [mask]);
  useEffect(() => {
    setTimeout(() => {
      styleChanges();
    }, [200]);
    dispatch({ type: "ROUTES", payload: "client History" });
  }, []);
  return (
    <div>
      {mask && (
        <div
          style={{ width: window.innerWidth, height: window.innerHeight }}
          className={styles.mask}
          onClick={() => {
            setMask(false);
          }}
        ></div>
      )}
      <div className={style.clientHistoryTrackerMainDiv}>
        <div
          className={style.backButtonDiv}
          onClick={() => {
            navigation("/manageclients");
          }}
        >
          {/* <img src={} alt="" /> */}
          <label> &lt; Back</label>
        </div>
        <div className={style.customBtnsec}>
          <p className={style.topLabel}>
            {data?.Firstname} {data?.Lastname}
          </p>
          <JsonToExcel
            title="Excel Export"
            data={excelList}
            fileName="Manager List"
            btnClassName={style.customexportButton}
          />
        </div>
        <table
          id="myTable"
          className={
            bootstrap[("table", "table-striped")] + " " + styles.tableCustomcls
          }
        >
          <thead>
            <tr>
              <th className={styles.Tablefontcls}>Session</th>
              {/* <th className={styles.Tablefontcls}>Plan Type</th> */}
              <th className={styles.Tablefontcls}>Date</th>
              <th className={styles.Tablefontcls}>Weight</th>
              <th className={styles.Tablefontcls}>Weight Difference</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((val, i) => {
                return (
                  <tr key={String(i)}>
                    <td className={styles.Tablefonttdcls}>
                      {val?.Session ? val?.Session : "-"}
                    </td>
                    {/* <td className={styles.Tablefonttdcls}>{val?.Type ? val?.Type : "-"}</td> */}
                    <td className={styles.Tablefonttdcls}>
                      {val?.Date ? moment(val?.Date).format("YYYY-MM-DD") : "-"}
                    </td>
                    <td className={styles.Tablefonttdcls}>
                      {val?.Weight ? val?.Weight : "-"}
                    </td>
                    <td
                      className={styles.Tablefonttdcls}
                      style={{
                        backgroundColor:
                          val?.Weightdifference > 0 ? "#FFE4E1" : "#EEFBEB",
                      }}
                    >
                      {val?.Weightdifference ? val?.Weightdifference : "-"}
                    </td>
                    <td>
                      <DeleteDropdown
                        setMask={setMask}
                        mask={mask}
                        id={i}
                        data={val}
                        type="weight"
                        getData={getData}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminWeightTracker;
