//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import style from "../assets/styles/TabularAssessment.module.css";
import { BASE_URL } from "./baseURL";
import $ from "jquery";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import Request from "axios";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function TabularAssessment({ mealpage, client }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const location = useLocation();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const data = location?.state?.data;
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //-------------------------------------------------------------------USEffect to Post Client Assessment-----------------------------------------------------------------------------//

  useEffect(() => {
    Request.post(`${BASE_URL}client/getClientAssessment`, {
      Client_id: client?.id ? client?.id : data?.Rid ? data?.Rid : data?.id,
    })
      .then((res) => {
        setList(res?.data?.data[0]);
        $(() => {
          $("#myTable").DataTable();
        });
      })
      .catch((err) => {
        alert("Error fetching tabular assessment", err);
        console.log("Error fetching tabular assessment", err);
      });
    dispatch({ type: "ROUTES", payload: "Plan History" });
  }, [client]);

  //-------------------------------------------------------------------USEffect to Post Client Assessment-----------------------------------------------------------------------------//
  return (
    <div style={{ width: "96%", padding: "2%" }}>
      <div className={style.margofbot}>
        <p className={style.bluelabel}>Personal Information</p>
      </div>
      <div className={style.mainflex}>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Client Name</p>
            <p className={style.blacklabel}>
              {client?.Firstname ? client?.Firstname : location?.state?.data?.Firstname}{" "}
              {client?.Firstname ? client?.Lastname : location?.state?.data?.Lastname}
            </p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Gender</p>
            <p className={style.blacklabel}>{list?.Gender ? list?.Gender : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Age</p>
            <p className={style.blacklabel}>{list?.Age ? list?.Age : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Date of Register</p>
            <p className={style.blacklabel}>
              {list?.CreatedDate ? moment(list?.CreatedDate).format("YYYY-MM-DD") : "-"}
            </p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Caste</p>
            <p className={style.blacklabel}>{list?.Caste ? list?.Caste : "-"}</p>
          </div>
          {/* <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Plan Purchase Date</p>
            <p className={style.blacklabel}>
              {list?.Purchasedate ? moment(list?.Purchasedate).format("YYYY-MM-DD") : "-"}
            </p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Plan Expiration Date</p>
            <p className={style.blacklabel}>
              {list?.Enddate ? moment(list?.Datedate).format("YYYY-MM-DD") : "-"}
            </p>
          </div> */}
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Email</p>
            <p className={style.blacklabel}>{list?.EmailId ? list?.EmailId : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Mobile No.</p>
            <p className={style.blacklabel}>{list?.Mobile ? list?.Mobile : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Address</p>
            <p className={style.blacklabel}>{list?.Address ? list?.Address : "-"}</p>
          </div>
        </div>
      </div>
      <div className={style.margofbot + " " + style.margoftop}>
        <p className={style.bluelabel}>Physical Information</p>
      </div>
      <div className={style.mainflex}>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Height</p>
            <p className={style.blacklabel}>{list?.Height ? list?.Height : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Weight</p>
            <p className={style.blacklabel}>{list?.Weight ? list?.Weight : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Last Weight you saw on scale </p>
            <p className={style.blacklabel}>{list?.Lastweight ? list?.Lastweight : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Dream Weight (Realistic Goal)</p>
            <p className={style.blacklabel}>{list?.Goal ? list?.Goal : "-"}</p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Job Timings (Type NA if it is not applicable)</p>
            <p className={style.blacklabel}>{list?.Jobtiming ? list?.Jobtiming : "-"} </p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Occupation</p>
            <p className={style.blacklabel}>{list?.Occupation ? list?.Occupation : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Activity Pattern</p>
            <p className={style.blacklabel}>
              {list?.Activitypattern ? list?.Activitypattern : "-"}
            </p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Does your work includes Travel?</p>
            <p className={style.blacklabel}>{list?.Travelling == "0" ? "No" : "Yes"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>How often do you travel?</p>
            <p className={style.blacklabel}>{list?.Traveloften ? list?.Traveloften : "-"}</p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={style.setcol100} style={{ width: "100%" }}>
            <p className={style.greylabel}>What kind of Physical Activity you do?</p>
            <p className={style.blacklabel}>
              {list?.PhysicalActivity ? list?.PhysicalActivity : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className={style.margofbot + " " + style.margoftop}>
        <p className={style.bluelabel}>Additional Information</p>
      </div>
      <div className={style.mainflex}>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>How many meals you carry to work?</p>
            <p className={style.blacklabel}>{list?.Carrymeal ? list?.Carrymeal : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Marital Status</p>
            <p className={style.blacklabel}>{list?.Maritalstatus ? list?.Maritalstatus : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Who cooks food at home.?</p>
            <p className={style.blacklabel}>{list?.Whocook ? list?.Whocook : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Type of Family?</p>
            <p className={style.blacklabel}>{list?.Familytype ? list?.Familytype : "-"}</p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>How many kids you have?</p>
            <p className={style.blacklabel}>{list?.Kids ? list?.Kids : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Age of kids?</p>
            <p className={style.blacklabel}>{list?.Childrenage ? list?.Childrenage : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Preferred Cuisine (LIKES)</p>
            <p className={style.blacklabel}>
              {list?.Preferredcuisine ? list?.Preferredcuisine : "-"}
            </p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Eating Habits</p>
            <p className={style.blacklabel}>{list?.Eatinghabit ? list?.Eatinghabit : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Smoking</p>
            <p className={style.blacklabel}>{list?.Smoking ? list?.Smoking : "-"}</p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Alcohol</p>
            <p className={style.blacklabel}>{list?.Alcohol ? list?.Alcohol : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Frequency of alcohol consumption?</p>
            <p className={style.blacklabel}>
              {list?.Alcoholconsumption ? list?.Alcoholconsumption : "-"}
            </p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Water Intake</p>
            <p className={style.blacklabel}>{list?.Waterintake ? list?.Waterintake : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Any Religious Restrictions</p>
            <p className={style.blacklabel}>
              {list?.Religiousrestrictions ? list?.Religiousrestrictions : "-"}
            </p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Frequency of Fasting?</p>
            <p className={style.blacklabel}>
              {list?.Fastingfrequency ? list?.Fastingfrequency : "-"}
            </p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Food Dislikes</p>
            <p className={style.blacklabel}>{list?.Fooddislike ? list?.Fooddislike : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Sleep Pattern</p>
            <p className={style.blacklabel}>{list?.Sleeppattern ? list?.Sleeppattern : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Food Allergies</p>
            <p className={style.blacklabel}>{list?.Foodallergies ? list?.Foodallergies : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Bowel Movement</p>
            <p className={style.blacklabel}>{list?.Bowelmovement ? list?.Bowelmovement : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Regular Periods</p>
            <p className={style.blacklabel}>{list?.Regularperiods ? list?.Regularperiods : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Last monthly cycle date</p>
            <p className={style.blacklabel}>
              {list?.Monthlycycledate ? moment(list?.Monthlycycledate).format("YYYY-MM-DD") : "-"}
            </p>
          </div>
        </div>
        {/* <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={style.setcol100}>
            <p className={style.greylabel}>Remark</p>
            <p className={style.blacklabel}>{list?.Description ? list?.Description : "-"}</p>
          </div>
        </div> */}
      </div>
      <div className={style.margofbot + " " + style.margoftop}>
        <p className={style.bluelabel}>Medical Condition</p>
      </div>
      <div className={style.mainflex}>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Do you have any medical History</p>
            <p className={style.blacklabel}>{list?.MedicalHistory ? list?.MedicalHistory : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Are you on any medication currently ?</p>
            <p className={style.blacklabel}>
              {list?.CurrentMedication && list?.CurrentMedication !== "0000-00-00"
                ? moment(list?.CurrentMedication).format("YYYY-MM-DD")
                : "-"}
            </p>
          </div>
          {/* <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Medical Report </p>
            <p className={style.blacklabel}>View Report</p>
          </div> */}
        </div>
      </div>
      <div className={style.margofbot + " " + style.margoftop}>
        <p className={style.bluelabel}>Dietary Recall</p>
      </div>
      <div className={style.mainflex}>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>What time you wake up ?</p>
            <p className={style.blacklabel}>{list?.WakeTime ? list?.WakeTime : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Prefer</p>
            <p className={style.blacklabel}>{list?.Prefer ? list?.Prefer : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>What time you consume your breakfast?</p>
            <p className={style.blacklabel}>{list?.BreakfastTime ? list?.BreakfastTime : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>What are the food items prepared for Breakfast?</p>
            <p className={style.blacklabel}>{list?.BreakfastFood ? list?.BreakfastFood : "-"}</p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>What do you have for your Lunch?</p>
            <p className={style.blacklabel}>{list?.LunchFood ? list?.LunchFood : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Evening Snack?</p>
            <p className={style.blacklabel}>{list?.EveningSnack ? list?.EveningSnack : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>What do you usually eat for your dinner?</p>
            <p className={style.blacklabel}>{list?.DinnerFood ? list?.DinnerFood : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>Anything else you prefer eating in the whole day?</p>
            <p className={style.blacklabel}>{list?.WholeDayFood ? list?.WholeDayFood : "-"}</p>
          </div>
          <div className={mealpage ? style.setcol45 : style.setcol}>
            <p className={style.greylabel}>What time you go to bed?</p>
            <p className={style.blacklabel}>{list?.BedTime ? list?.BedTime : "-"}</p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={style.setcol100} style={{ width: "100%", gap: "15px" }}>
            <p className={style.greylabel}>
              What is your daily / Monthly consumption of these foods?
            </p>
            {/* <p className={style.blacklabel}>{list?.Description ? list?.Description : "-"}</p> */}
            <p className={style.blacklabelEdited}>
              <span>Pizza</span>
              <span>{list?.Pizza ? list?.Pizza : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Pasta</span>
              <span>{list?.Pasta ? list?.Pasta : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Maggie</span>
              <span>{list?.Maggie ? list?.Maggie : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Biscuits</span>
              <span>{list?.Biscuits ? list?.Biscuits : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Chaat</span>
              <span>{list?.Chaat ? list?.Chaat : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Ghee</span>
              <span>{list?.Ghee ? list?.Ghee : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Fruits</span>
              <span>{list?.Fruits ? list?.Fruits : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Fist / Chicken / Meat</span>
              <span>{list?.FishChickenMeat ? list?.FishChickenMeat : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Bread</span>
              <span>{list?.Bread ? list?.Bread : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Popcorn / Chips</span>
              <span>{list?.PopcornChips ? list?.PopcornChips : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Chocolate</span>
              <span>{list?.Chocolate ? list?.Chocolate : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Egg</span>
              <span>{list?.Egg ? list?.Egg : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Ice Cream</span>
              <span>{list?.Icecream ? list?.Icecream : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Late night Binging</span>
              <span>{list?.LateNightBinging ? list?.LateNightBinging : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Samosa / Vada Pav</span>
              <span>{list?.SamosaVadapav ? list?.SamosaVadapav : "-"}</span>
            </p>
            <p className={style.blacklabelEdited}>
              <span>Cheese</span>
              <span>{list?.Cheese ? list?.Cheese : "-"}</span>
            </p>
          </div>
        </div>
        <div className={mealpage ? style.theflex40 : style.theflex}>
          <div className={style.setcol100} style={{ width: "100%" }}>
            <p className={style.greylabel}>
              We would love to know more about you and your current lifestyle. Please explain in
              brief.
            </p>
            <p className={style.blacklabel}>{list?.Description ? list?.Description : "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabularAssessment;
