//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA1 = forwardRef(
  (
    {
      age,
      gender,
      fullname,
      caste,
      mobile,
      address,
      zipcode,
      country,
      state,
      city,
      setPageIndex,
      adminData,
      profileData,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [genderRadio, setGenderRadio] = useState("Male");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        { id: "age", name: "errorMessageAge", ref: age },
        { id: "caste", name: "errorMessagecaste", ref: caste },
        {
          id: "mobile",
          name: "errorMessageMobileno",
          ref: mobile,
          type: "number",
        },
        { id: "address", name: "errorMessageaddress", ref: address },
        { id: "zipcode", name: "errorMessagezipcode", ref: zipcode },
        { id: "country", name: "errorMessagecountry", ref: country },
        { id: "state", name: "errorMessagestate", ref: state },
        { id: "city", name: "errorMessagecity", ref: city },
      ];
      let erId = -1;
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        let regrx = new RegExp(
          val.type === "email"
            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            : val.type === "password"
            ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
            : val.type === "number"
            ? /^(\+\d{1,3}[- ]?)?\d{10}$/
            : ""
        );
        if (val.ref?.current?.value?.length < 1) {
          if (erId == -1) {
            erId = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
          document.getElementById(val.id).focus();
        } else if (!regrx.test(val.ref?.current?.value)) {
          if (erId == -1) {
            erId = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter Valid ${val.type}`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }
        if (erId != -1) {
          document.getElementById(erId).focus();
        }
      });
      !error && setPageIndex((i) => i + 1);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      document.getElementById("radioMale").checked = true;
      let cache = JSON.parse(localStorage.getItem("CA1"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        fullname.current.value = `${data?.Firstname} ${data?.Lastname}`;
        age.current.value = data?.Age;
        gender.current.value = data?.Gender;
        caste.current.value = data?.Caste;
        address.current.value = data?.Address;
        country.current.value = data?.Country;
        city.current.value = data?.City;
        zipcode.current.value = data?.Zipcode;
        mobile.current.value = data?.Mobile;
        state.current.value = data?.State;
        data?.Gender?.length > 0 && setGenderRadio(data?.Gender);
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        fullname.current.value = `${data?.Firstname} ${data?.Lastname}`;
        age.current.value = data?.Age;
        gender.current.value = data?.Gender;
        caste.current.value = data?.Caste;
        address.current.value = data?.Address;
        country.current.value = data?.Country;
        city.current.value = data?.City;
        zipcode.current.value = data?.Zipcode;
        mobile.current.value = data?.Mobile;
        state.current.value = data?.State;
        data?.Gender?.length > 0 && setGenderRadio(data?.Gender);
      } else {
        let temp = JSON.parse(localStorage.getItem("user"));
        fullname.current.value = temp?.Firstname + " " + temp?.Lastname;
      }
    }, [adminData, profileData]);

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      let temp = JSON.parse(localStorage.getItem("user"));
      fullname.current.value = temp?.Firstname + " " + temp?.Lastname;
      age.current.value = cache.Age;
      gender.current.value = cache.Gender;
      caste.current.value = cache.Caste;
      address.current.value = cache.Address;
      country.current.value = cache.Country;
      city.current.value = cache.City;
      zipcode.current.value = cache.Zipcode;
      mobile.current.value = cache.Mobile;
      state.current.value = cache.State;
      cache.Gender?.length > 0 && setGenderRadio(cache.Gender);
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.personalInformationForm}>
        <label className={style.personalInformationLabel}>
          Personal Information
        </label>
        <div className={style.firstRowOfPI + " " + style.maroftop}>
          <div className={style.fullNameInput}>
            <label>
              {" "}
              Full Name <span> * </span>{" "}
            </label>
            <input readOnly ref={fullname} type="text" id="fullName" />
          </div>
          <div className={style.ageInput}>
            <label>
              {" "}
              Age <span> *</span>{" "}
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={age}
              type="text"
              placeholder="Age"
              id="age"
            />
            <span className={style.errorSpan} id="errorMessageAge"></span>
          </div>
        </div>
        <div className={style.firstRowOfPI + " " + style.marofbot}>
          <div className={style.genderRadioInput}>
            <label ref={gender}>
              {" "}
              Gender<span> * </span>{" "}
            </label>
            <div className={style.genderRadioDiv}>
              <div
                className={style.genderRadioMale}
                onClick={() => {
                  if (!adminData) {
                    setGenderRadio("Male");
                    gender.current.value = "Male";
                  }
                }}
              >
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {}}
                  id="radioMale"
                  type="radio"
                  name="genderRadioSelection"
                  value="Male"
                  checked={genderRadio === "Male" ? true : false}
                />
                <label>Male</label>
              </div>
              <div
                onClick={() => {
                  if (!adminData) {
                    setGenderRadio("Female");
                    gender.current.value = "Female";
                  }
                }}
                className={style.genderRadioFemale}
              >
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {}}
                  type="radio"
                  name="genderRadioSelection"
                  value="Female"
                  id="radioFemale"
                  checked={genderRadio === "Female" ? true : false}
                />
                <label>Female</label>
              </div>
              <div
                onClick={() => {
                  if (!adminData) {
                    setGenderRadio("Prefer not to say");
                    gender.current.value = "Prefer not to say";
                  }
                }}
                className={style.genderRadioNone}
              >
                <input
                  readOnly={adminData ? true : false}
                  onChange={() => {}}
                  type="radio"
                  name="genderRadioSelection"
                  value="Prefer not to say"
                  id="radioOthers"
                  checked={genderRadio === "Prefer not to say" ? true : false}
                />
                <label>Prefer not to say</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.secondRowOfPI}>
          <div className={style.casteInput}>
            <label>
              Caste<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={caste}
              type="text"
              placeholder="Caste"
              id="caste"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessagecaste"></span>
          </div>
          <div className={style.MobileNoInput}>
            <label>
              Mobile No.<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={mobile}
              type="text"
              placeholder="Mobile No."
              id="mobile"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessageMobileno"></span>
          </div>
        </div>
        <div className={style.thirdRowOfPI}>
          <div className={style.addressInput}>
            <label>
              Address<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={address}
              type="text"
              placeholder="Address"
              id="address"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessageaddress"></span>
          </div>
          <div className={style.zipcodeInput}>
            <label>
              Zipcode<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={zipcode}
              type="text"
              placeholder="Zipcode"
              id="zipcode"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessagezipcode"></span>
          </div>
        </div>
        <div className={style.foruthRowOfPI}>
          <div className={style.countryInput}>
            <label>
              Country<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={country}
              type="text"
              placeholder="Country"
              id="country"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessagecountry"></span>
          </div>
          <div className={style.stateInput}>
            <label>
              State<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={state}
              type="text"
              placeholder="State"
              id="state"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessagestate"></span>
          </div>
          <div className={style.cityInput}>
            <label>
              City<span> *</span>
            </label>
            <input
              readOnly={adminData ? true : false}
              ref={city}
              type="text"
              placeholder="City"
              id="city"
              onChange={() => {}}
            />
            <span className={style.errorSpan} id="errorMessagecity"></span>
          </div>
        </div>
      </div>
    );
  }
);

export default CA1;
