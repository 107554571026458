//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/styles/weightTrackerModal.module.css";
import { useSelector } from "react-redux";
import { BASE_URL } from "./baseURL";
import Request from "axios";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function PeriodTracker({ setShowUpdateInfoModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const Flow = useRef();
  const Symptoms = useRef();
  let initialDetails = useSelector((state) => state.allData?.user_details);
  const [userDetails, setUserDetails] = useState(initialDetails);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  useEffect(() => {
    let userDetailsTemp = JSON.parse(localStorage.getItem("user"));
    setUserDetails(userDetailsTemp);
    document.getElementById("date").valueAsDate = new Date();
  }, []);

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function Validate() {
    let checklist = [
      { id: "flow", name: "errorMessageFlow", ref: Flow },
      { id: "symptoms", name: "errorMessageSymptoms", ref: Symptoms },
    ];
    let error = false;
    checklist.map((val) => {
      let propStyle = document.getElementById(val.id);
      let propText = document.getElementById(val.name);
      if (val.ref.current.value?.length < 1) {
        error = true;
        propStyle.style.border = "2px solid red";
        propText.style.display = "block";
        propText.textContent = `Please Enter this field`;
      } else {
        propStyle.style.border = "1.5px solid #D9D9D9";
        propText.style.display = "none";
        propText.textContent = "none";
      }
      console.log("Error", error);
    });
    addPeriodtracker(error);
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Add Period Tracker Function-----------------------------------------------------------------------//

  function addPeriodtracker(_error) {
    Request.post(`${BASE_URL}tracker/addPeriodTracker`, {
      Symptoms: Symptoms.current.value,
      Flow: Flow.current.value,
      Lastperiodate: moment(document.getElementById("date").valueAsDate).format("YYYY-MM-DD"),
      Client_id: userDetails?.id,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Period Tracker data added!");
          setShowUpdateInfoModal(false);
        } else if (res?.data?.message === "Weight tracker exausted for the session") {
          alert("Maximum entries of weight reached for this session!");
          setShowUpdateInfoModal(false);
        } else if (res?.data?.message === "No meal plan assigned") {
          alert("Please wait till a meal plan is assigned to you!");
          setShowUpdateInfoModal(false);
        } else {
          alert("Error addding period tracker data");
          console.log("Error adding weight tracking data", res?.data);
        }
      })
      .catch((err) => {
        console.error("Error adding weight tracking data", err);
      });
  }

  //---------------------------------------------------------Add Period Tracker Function-----------------------------------------------------------------------//

  return (
    <>
      <div className={styles.modalContent}>
        <div>
          <p className={styles.title}>Period Tracker </p>
        </div>
        <div className={styles.mainContainerWB}></div>

        <div className={styles.mainContainerWB + " " + styles.margintopmain}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Last Month Period Date <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Date}
                type="date"
                className={styles.date}
                // placeholder="Session"
                id="date"
                required
              ></input>
              <span className={styles.errorSpan} id="errorMessageDate"></span>
            </div>
          </div>
        </div>
        <div className={styles.mainContainerWB + " " + styles.margintopmain}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Pre Menstrual Symptoms <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Symptoms}
                type="text"
                className={styles.input}
                placeholder="Symptoms"
                required
                id="symptoms"
              ></input>
              <span className={styles.errorSpan} id="errorMessageSymptoms"></span>
            </div>
          </div>
        </div>
        <div className={styles.mainContainerWB + " " + styles.margintopmain}>
          <div className={styles.container}>
            <div>
              <label className={styles.font}>
                Describe Your Flow <span className={styles.span}>*</span>
              </label>
            </div>
            <div>
              <input
                ref={Flow}
                type="text"
                className={styles.input}
                placeholder="Describe"
                required
                id="flow"
              ></input>
              <span className={styles.errorSpan} id="errorMessageFlow"></span>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.forsizeoffifty}>
            <button
              onClick={() => {
                Validate();
              }}
              className={styles.submit + " " + styles.forsizeofone}
            >
              <label className={styles.sumitText}>Submit</label>
            </button>
          </div>
          <div className={styles.forsizeoffifty}>
            <button
              className={styles.cancel + " " + styles.forsizeofone}
              onClick={() => {
                setShowUpdateInfoModal(false);
              }}
              id="cancelBtn"
            >
              <label className={styles.cancelText}>Cancel</label>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeriodTracker;
