//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/AdminDashboard.module.css";
import totalClients from "../../assets/images/admindashboard_totalClients.png";
import active from "../../assets/images/admindashboard_active.png";
import register from "../../assets/images/admindashboard_register.png";
import leaf from "../../assets/images/admindashboard_leaf.png";
import bronze from "../../assets/images/admindashboard_bronze.png";
import completed from "../../assets/images/completedClients.png";
import incompleted from "../../assets/images/incompleteClients.png";
import dormant from "../../assets/images/dormantClients.png";
import { BASE_URL } from "../../components/baseURL";
import Request from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function AdminDashboard() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [clientCount, setClientCount] = useState();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Get Client Count Function-----------------------------------------------------------------------//

  function getClientCount() {
    Request.get(`${BASE_URL}client/getClientCount`)
      .then((res) => {
        if (res?.data?.status === 200) {
        } else {
          alert("Error getting client count", res?.data?.message);
        }

        setClientCount(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log("Error getting client count", err);
      });
  }

  //---------------------------------------------------------Get Client Count Function-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Dispatch Routes-----------------------------------------------------------------------//

  useEffect(() => {
    getClientCount();
    dispatch({ type: "ROUTES", payload: "Dashboard" });
  }, []);

  //---------------------------------------------------------useEffect to Dispatch Routes-----------------------------------------------------------------------//

  return (
    <div>
      <div className={styles.adminDashboard}>Clients</div>

      <div className={styles.cardflex}>
        <div
          className={styles.firstcard + " " + styles.marginleftdiv}
          onClick={() => {
            navigation("/manageclients");
          }}
        >
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={totalClients} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.All_Clients ? clientCount?.All_Clients : 0}
              </p>
              <p className={styles.clientblack}>Total Clients</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>
        <div
          className={styles.firstcard}
          onClick={() => {
            navigation("/manageclients", { state: "Active" });
          }}
        >
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={active} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.Total_Active_Clients ? clientCount?.Total_Active_Clients : 0}
              </p>
              <p className={styles.clientblack}>Total Active Clients</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>
        <div className={styles.firstcard}>
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={register} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.New_Orders ? clientCount?.New_Orders : 0}
              </p>
              <p className={styles.clientblack}>New Orders</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>
        <div
          className={styles.firstcard}
          onClick={() => {
            navigation("/manageclients", { state: { data: { week: true } } });
          }}
        >
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={bronze} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.TrackerUpdatedCount ? clientCount?.TrackerUpdatedCount : 0}
              </p>
              <p className={styles.clientblack}>Tracker Updated</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>
      </div>

      <div className={styles.adminDashboard}></div>
      <div className={styles.cardflex + " " + styles.cardbetween}>
        <div
          className={styles.firstcard}
          onClick={() => {
            navigation("/manageclients", { state: "Dormant" });
          }}
        >
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={dormant} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.Total_Dormant ? clientCount?.Total_Dormant : 0}
              </p>
              <p className={styles.clientblack}>Dormant Clients</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>

        <div
          className={styles.firstcard + " " + styles.marginrightdiv}
          onClick={() => {
            navigation("/manageclients", { state: "Completed" });
          }}
        >
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={completed} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.Total_Inactive_Clients ? clientCount?.Total_Inactive_Clients : 0}
              </p>
              <p className={styles.clientblack}>Completed Clients</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>

        <div
          className={styles.firstcard}
          onClick={() => {
            navigation("/manageclients", { state: "Incomplete" });
          }}
        >
          <div className={styles.firstcardfirtdiv}>
            <div>
              <img src={incompleted} alt=""></img>
            </div>
            <div className={styles.colflex}>
              <p className={styles.clientlabel}>
                {clientCount?.Total_Incomplete ? clientCount?.Total_Incomplete : 0}
              </p>
              <p className={styles.clientblack}>Incomplete Clients</p>
            </div>
          </div>
          <div className={styles.leafDiv}>
            <img src={leaf} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
