//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "../../../assets/styles/ClientAssessmentForm.module.css";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const CA5 = forwardRef(
  (
    {
      sleepPattern,
      bowelMovement,
      regularPeriods,
      regularPeriodsOther,
      lastMonthlyCycle,
      remark,
      adminData,
      profileData,
      setPageIndex,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkValidation() {
        Validate();
      },
    }));


    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
    const [BowelmovementSet, setBowelmovement] = useState("Yes");
    const [regularPeriodsSet, setregularPeriods] = useState("Yes");
    //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

    async function Validate() {
      let error = false;
      let checklist = [
        { id: "sleepPattern", name: "errorMessagessleepPattern", ref: sleepPattern },
        { id: "remark", name: "errorMessagesremark", ref: remark },
      ];
      let erId5 = -1;
      checklist.map((val) => {
        let propStyle = document.getElementById(val.id);
        let propText = document.getElementById(val.name);
        let regrx = new RegExp(
          val.type === "email"
            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            : val.type === "password"
            ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i
            : val.type === "number"
            ? /^[0][1-9]\d{9}$|^[1-9]\d{9}$/i
            : ""
        );
        if (val.ref?.current?.value?.length < 1) {
          if (erId5 == -1) {
            erId5 = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter this field`;
          document.getElementById(val.id).focus();
        } else if (!regrx.test(val.ref?.current?.value)) {
          if (erId5 == -1) {
            erId5 = val.id;
          }
          error = true;
          propStyle.style.border = "2px solid red";
          propText.style.display = "block";
          propText.textContent = `Please Enter Valid ${val.type}`;
        } else {
          propStyle.style.border = "1.5px solid #D9D9D9";
          propText.style.display = "none";
          propText.textContent = "none";
        }
        if (erId5 != -1) {
          document.getElementById(erId5).focus();
        }
      });
      if (document.getElementById("regularPeriodsRadioSelectionOther").checked) {
        if (regularPeriodsOther.current.value?.length < 1) {
          error = true;
          document.getElementById("regularPeriodsRadioSelectionField").style.border =
            "2px solid red";
          document.getElementById("errorMessageregularPeriodsOther").style.display = "block";
          document.getElementById(
            "errorMessageregularPeriodsOther"
          ).textContent = `Please Enter this field`;
        }
      } else {
        document.getElementById("regularPeriodsRadioSelectionField").style.border =
          "1.5px solid #D9D9D9";
        document.getElementById("errorMessageregularPeriodsOther").style.display = "none";
        document.getElementById("errorMessageregularPeriodsOther").textContent = `none`;
      }
      !error && setPageIndex(6);
    }

    //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//
    
    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    useEffect(() => {
      setregularPeriods();
      setBowelmovement("Regular");
      bowelMovement.current.value = "Regular";
      let cache = JSON.parse(localStorage.getItem("CA5"));
      let data = adminData ? adminData : profileData;
      if (adminData) {
        sleepPattern.current.value = data?.Sleeppattern;
        bowelMovement.current.value = data?.Bowelmovement;
        if (data?.Bowelmovement == "Regular") {
          setBowelmovement("Regular");
          bowelMovement.current.value = "Regular";
        } else if (data?.Bowelmovement == "Irregular") {
          setBowelmovement("Irregular");
          bowelMovement.current.value = "Irregular";
        } else if (data?.Bowelmovement == "Constipated") {
          setBowelmovement("Constipated");
          bowelMovement.current.value = "Constipated";
        } else {
          setBowelmovement("Other");
          bowelMovement.current.value = "Other";
        }

        regularPeriods.current.value = data?.Regularperiods;
        if (data?.Regularperiods) {
          if (data?.Regularperiods == "Yes") {
            setregularPeriods("Yes");
            regularPeriods.current.value = "Yes";
          } else if (data?.Regularperiods == "No") {
            setregularPeriods("No");
            regularPeriods.current.value = "No";
          } else if (data?.Regularperiods == "Delayed") {
            setregularPeriods("Delayed");
            regularPeriods.current.value = "Delayed";
          } else {
            setregularPeriods("Other");
            regularPeriods.current.value = "Other";
            regularPeriodsOther.current.value = data?.Regularperiods ? data?.Regularperiods : "";
          }
        }
        lastMonthlyCycle.current.value = data?.Monthlycycledate
          ? moment(data?.Monthlycycledate).format("YYYY-MM-DD")
          : "";
        remark.current.value = data?.Description;
      } else if (cache) {
        loadFromCache(cache);
      } else if (profileData) {
        sleepPattern.current.value = data?.Sleeppattern;
        bowelMovement.current.value = data?.Bowelmovement;
        if (data?.Bowelmovement == "Regular") {
          setBowelmovement("Regular");
          bowelMovement.current.value = "Regular";
        } else if (data?.Bowelmovement == "Irregular") {
          setBowelmovement("Irregular");
          bowelMovement.current.value = "Irregular";
        } else if (data?.Bowelmovement == "Constipated") {
          setBowelmovement("Constipated");
          bowelMovement.current.value = "Constipated";
        } else {
          setBowelmovement("Other");
          bowelMovement.current.value = "Other";
        }

        regularPeriods.current.value = data?.Regularperiods;
        if (data?.Regularperiods) {
          if (data?.Regularperiods == "Yes") {
            setregularPeriods("Yes");
            regularPeriods.current.value = "Yes";
          } else if (data?.Regularperiods == "No") {
            setregularPeriods("No");
            regularPeriods.current.value = "No";
          } else if (data?.Regularperiods == "Delayed") {
            setregularPeriods("Delayed");
            regularPeriods.current.value = "Delayed";
          } else {
            setregularPeriods("Other");
            regularPeriods.current.value = "Other";
            regularPeriodsOther.current.value = data?.Regularperiods ? data?.Regularperiods : "";
          }
        }
        lastMonthlyCycle.current.value = data?.Monthlycycledate
          ? moment(data?.Monthlycycledate).format("YYYY-MM-DD")
          : "";
        remark.current.value = data?.Description;
      }
    }, []);

    //---------------------------------------------------------UseEffect-----------------------------------------------------------------------//

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    function loadFromCache(cache) {
      sleepPattern.current.value = cache?.Sleeppattern;
      if (cache?.Bowelmovement == "Regular") {
        setBowelmovement("Regular");
        bowelMovement.current.value = "Regular";
      } else if (cache?.Bowelmovement == "Irregular") {
        setBowelmovement("Irregular");
        bowelMovement.current.value = "Irregular";
      } else if (cache?.Bowelmovement == "Constipated") {
        setBowelmovement("Constipated");
        bowelMovement.current.value = "Constipated";
      } else {
        setBowelmovement("Other");
        bowelMovement.current.value = "Other";
      }

      if (cache?.Regularperiods) {
        if (cache?.Regularperiods == "Yes") {
          setregularPeriods("Yes");
          regularPeriods.current.value = "Yes";
        } else if (cache?.Regularperiods == "No") {
          setregularPeriods("No");
          regularPeriods.current.value = "No";
        } else if (cache?.Regularperiods == "Delayed") {
          setregularPeriods("Delayed");
          regularPeriods.current.value = "Delayed";
        } else {
          setregularPeriods("Other");
          regularPeriods.current.value = "Other";
          regularPeriodsOther.current.value = cache?.Regularperiods ? cache?.Regularperiods : "";
        }
      }
      lastMonthlyCycle.current.value = cache.Monthlycycledate;
      remark.current.value = cache.Description;
      if (cache.Gender !== "Prefer not to say") {
        let gen1 = document.getElementById(`radio${cache.Gender}`);
        if (gen1) {
          gen1.checked = true;
        }
      } else {
        document.getElementById("radioOthers").checked = true;
      }
    }

    //---------------------------------------------------------Load From Cache Function-----------------------------------------------------------------------//

    return (
      <div className={style.additionalInformationOneForm}>
        <label className={style.additionalInformationOneLabel}>Additional Information</label>
        <div className={style.firstRowOfAI3 + " " + style.maroftop}>
          <div className={style.sleepPatternDiv}>
            <label>
              Sleep Pattern<span> *</span>
            </label>
            <input ref={sleepPattern} type="text" placeholder="Sleep Pattern" id="sleepPattern" />
            <span className={style.errorSpan} id="errorMessagessleepPattern"></span>
          </div>
          <div className={style.bowelRadioInput}>
            <label ref={bowelMovement}>
              Bowel Movement<span> *</span>
            </label>
            <div className={style.bowelRadioDiv}>
              <div className={style.bowelRadioReg}>
                <input
                  id="regularRadio"
                  onChange={() => {
                    bowelMovement.current.value = "Regular";
                    setBowelmovement("Regular");
                  }}
                  type="radio"
                  name="bowelRadioSelection"
                  checked={BowelmovementSet == "Regular" ? true : false}
                />
                <label htmlFor="regularRadio">Regular</label>
              </div>
              <div className={style.bowelRadioIreg}>
                <input
                  id="irregularRadio"
                  onChange={() => {
                    bowelMovement.current.value = "Irregular";
                    setBowelmovement("Irregular");
                  }}
                  type="radio"
                  name="bowelRadioSelection"
                  checked={BowelmovementSet == "Irregular" ? true : false}
                />
                <label htmlFor="irregularRadio">Irregular</label>
              </div>
              <div className={style.bowelRadioConsti}>
                <input
                  id="ConstipatedRadio"
                  onChange={() => {
                    bowelMovement.current.value = "Constipated";
                    setBowelmovement("Constipated");
                  }}
                  type="radio"
                  name="bowelRadioSelection"
                  checked={BowelmovementSet == "Constipated" ? true : false}
                />
                <label htmlFor="ConstipatedRadio">Constipated</label>
              </div>
              <div className={style.bowelRadioNone}>
                <input
                  id="otherbowelRadio"
                  onChange={() => {
                    bowelMovement.current.value = "Other";
                    setBowelmovement("Other");
                  }}
                  type="radio"
                  name="bowelRadioSelection"
                  checked={BowelmovementSet == "Other" ? true : false}
                />
                <label htmlFor="otherbowelRadio">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.secondRowOfAI3}>
          <div className={style.regularPeriodsRadioInput}>
            <label ref={regularPeriods}>Regular Periods?</label>
            <div className={style.regularPeriodsRadioDiv}>
              <div className={style.regularPeriodsRadioSed}>
                <input
                  id="yesregularradio"
                  onChange={() => {
                    regularPeriods.current.value = "Yes";
                    setregularPeriods("Yes");
                  }}
                  type="radio"
                  name="regularPeriodsRadioSelection"
                  checked={regularPeriodsSet == "Yes" ? true : false}
                />
                <label htmlFor="yesregularradio">Yes</label>
              </div>
              <div className={style.regularPeriodsRadioMod}>
                <input
                  id="noregularradio"
                  onChange={() => {
                    regularPeriods.current.value = "No";
                    setregularPeriods("No");
                  }}
                  type="radio"
                  name="regularPeriodsRadioSelection"
                  checked={regularPeriodsSet == "No" ? true : false}
                />
                <label htmlFor="noregularradio">No</label>
              </div>
              <div className={style.regularPeriodsRadioHeavy}>
                <input
                  onChange={() => {
                    regularPeriods.current.value = "Delayed";
                    setregularPeriods("Delayed");
                  }}
                  type="radio"
                  name="regularPeriodsRadioSelection"
                  id="regularPeriodsRadioSelection"
                  checked={regularPeriodsSet == "Delayed" ? true : false}
                />
                <label htmlFor="regularPeriodsRadioSelection">Delayed</label>
              </div>
              <div className={style.regularPeriodsRadioNone}>
                <input
                  onChange={() => {
                    regularPeriods.current.value = "Other";
                    setregularPeriods("Other");
                  }}
                  type="radio"
                  name="regularPeriodsRadioSelection"
                  id="regularPeriodsRadioSelectionOther"
                  checked={regularPeriodsSet == "Other" ? true : false}
                />
                <label htmlFor="regularPeriodsRadioSelectionOther">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.thirdRowOfAI3}>
          <div className={style.otherInputAI3}>
            <label>Other</label>
            <input
              ref={regularPeriodsOther}
              type="text"
              placeholder="Other"
              id="regularPeriodsRadioSelectionField"
            />
            <span className={style.errorSpan} id="errorMessageregularPeriodsOther"></span>
          </div>
        </div>
        <div className={style.foruthRowOfAI3}>
          <div className={style.cycleDateInputAI3}>
            <label>Last Monthly Cycle Date</label>
            <input ref={lastMonthlyCycle} type="date" />
          </div>
        </div>
        <div className={style.fifthRowOfAI3}>
          <div className={style.remarkInputAI3}>
            <label>
              We would love to know more about you and your current lifestyle.<span> *</span>
            </label>
            <textarea
              ref={remark}
              className=""
              cols="10"
              rows="10"
              placeholder="Remark"
              id="remark"
            ></textarea>
            <span className={style.errorSpan} id="errorMessagesremark"></span>
          </div>
        </div>
      </div>
    );
  }
);

export default CA5;
