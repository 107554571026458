//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/styles/Planpdf.module.css";
import A_Jain_logo from "../assets/images/A_Jain_logo.png";
import login_bg_top from "../assets/images/login_bg_top.png";
import contact from "../assets/images/contact.png";
import { useReactToPrint } from "react-to-print";
import Request from "axios";
import { BASE_URL } from "./baseURL";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Planpdf() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Meal Plan`,
    onAfterPrint: () => {
      console.log("Printed PDF successfully!");
      localStorage.removeItem("planpdf");
      window.close();
    },
  });
  let temp = JSON.parse(localStorage.getItem("planpdf"));
  const [mealInfo, setMealInfo] = useState();

  let Bedfood = mealInfo?.Bedfood;
  let Bedmeal = mealInfo?.Bedmeal;
  let Breakfastfood = mealInfo?.Breakfastfood;
  let Breakfastmeal = mealInfo?.Breakfastmeal;
  let Dinnerfood = mealInfo?.Dinnerfood;
  let Dinnermeal = mealInfo?.Dinnermeal;
  let Lateeveningsnackfood = mealInfo?.Lateeveningsnackfood;
  let Lateeveningsnackmeal = mealInfo?.Lateeveningsnackmeal;
  let Lunchfood = mealInfo?.Lunchfood;
  let Lunchmeal = mealInfo?.Lunchmeal;
  let Midmorningfood = mealInfo?.Midmorningfood;
  let Midmorningmeal = mealInfo?.Midmorningmeal;
  let Postworkout1food = mealInfo?.Postworkout1food;
  let Postworkout1meal = mealInfo?.Postworkout1meal;
  let Postworkout2food = mealInfo?.Postworkout2food;
  let Postworkout2meal = mealInfo?.Postworkout2meal;
  let Predinnerfood = mealInfo?.Predinnerfood;
  let Predinnermeal = mealInfo?.Predinnermeal;
  let Preworkout1food = mealInfo?.Preworkout1food;
  let Preworkout1meal = mealInfo?.Preworkout1meal;
  let Preworkout2food = mealInfo?.Preworkout2food;
  let Preworkout2meal = mealInfo?.Preworkout2meal;
  let Risingfood = mealInfo?.Risingfood;
  let Risingmeal = mealInfo?.Risingmeal;
  let Snackfood = mealInfo?.Snackfood;
  let Snackmeal = mealInfo?.Snackmeal;
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //-------------------------------------------------------------------UseEffect to Fetch Plan-----------------------------------------------------------------------------//

  useEffect(() => {
    if (temp?.idd > -1) {
      Request.get(`${BASE_URL}plans/getMealPlans?id=${temp?.idd}`)
        .then((res) => {
          setMealInfo(res?.data?.data[0]);
        })
        .catch((err) => {
          console.log("Error fetching plan for client", err);
        });
    } else {
      setMealInfo(JSON.parse(localStorage.getItem("planpdf")));
    }
  }, []);
  //-------------------------------------------------------------------UseEffect to Fetch Plan-----------------------------------------------------------------------------//
  return (
    <div id="basic">
      <div ref={componentRef} className={styles.passtitle}>
        <div className={[styles.firstmain]}>
          <img src={A_Jain_logo} className={styles.logosize} alt=""></img>
          <div className={styles.contactdiv}>
            <img src={contact} className={styles.contactimg} alt=""></img>
            <p className={styles.contactno}>+91 9867189080</p>
            <div className={styles.bgtopdiv}>
              <img src={login_bg_top} className={styles.logintop} alt=""></img>
            </div>
          </div>
        </div>

        <div className={styles.flexcols}>
          <div className={styles.clientname}>
            <p className={styles.greyname}>Client Name: </p>
            <p className={styles.blackname}>
              {" "}
              {temp?.Firstname} {temp?.Lastname}
            </p>
          </div>

          <div className={styles.clientname}>
            <p className={styles.greyname}>Weight: </p>
            <p className={styles.blackname}>
              {" "}
              {temp?.latestWeight ? temp?.latestWeight : temp?.Weight} kgs
            </p>
          </div>
          <div className={styles.clientname}>
            <p className={styles.greyname}>Start Date: </p>
            <p className={styles.blackname}> {moment(mealInfo?.Startdate).format("YYYY-MM-DD")}</p>
          </div>
          <div className={styles.clientname}>
            <p className={styles.greyname}>Meal Plan Upload Date: </p>
            <p className={styles.blackname}>
              {" "}
              {moment(mealInfo?.Createddate).format("YYYY-MM-DD")}
            </p>
          </div>
          <div className={styles.clientname}>
            <p className={styles.greyname}>Session:</p>
            <p className={styles.blackname}> {mealInfo?.Session}</p>
          </div>
        </div>

        <div>
          <p className={styles.thered}>* Do read the text which is marked in red color.</p>
        </div>

        <div className={styles.tablediv}>
          <table>
            <tr>
              <th className={styles.thstyle + " " + styles.fixtablewidth}>Day</th>
              <th className={styles.thstyle + " " + styles.fixtablewidth}>Meal Time</th>
              <th>Food Plan</th>
            </tr>
            {Risingfood && Risingmeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>On Rising</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Risingmeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Risingfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Risingmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Risingremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Preworkout1food && Preworkout1meal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Pre Workout</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Preworkout1meal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Preworkout1food}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Preworkout1meal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Preworkout1remark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Postworkout1food && Postworkout1meal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Post workout</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Postworkout1meal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Postworkout1food}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Postworkout1meal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Postworkout1remark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Breakfastfood && Breakfastmeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Breakfast</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Breakfastmeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Breakfastfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Breakfastmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Breakfastremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Midmorningfood && Midmorningmeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Mid Morning</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Midmorningmeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Midmorningfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Midmorningmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Midmorningremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Lunchfood && Lunchmeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Lunch</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Lunchmeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Lunchfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Lunchmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Lunchremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Snackfood && Snackmeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Snacks</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Snackmeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Snackfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Snackmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Snackremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Preworkout2food && Preworkout2meal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Pre workout</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Preworkout1meal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Preworkout2food}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Preworkout2meal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Preworkout2remark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Postworkout2food && Postworkout2meal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Post workout</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Postworkout1meal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Postworkout2food}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Postworkout2meal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Postworkout2remark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Lateeveningsnackfood && Lateeveningsnackmeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Late Evening snack</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Lateeveningsnackmeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Lateeveningsnackfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Lateeveningsnackmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Lateeveningsnackremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Predinnerfood && Predinnermeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Pre Dinner</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Predinnermeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Predinnerfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Predinnermeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Predinnerremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Dinnerfood && Dinnermeal && (
              <tr>
                <td className={styles.tdstyle + " " + styles.borderbottom}>Dinner</td>
                <td className={styles.tdstyle + " " + styles.borderbottom}>
                  {mealInfo?.Dinnermeal}
                </td>
                <td className={styles.borderbottom}>
                  <label className={styles.labelflex}>{mealInfo?.Dinnerfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Dinnermeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Dinnerremark}</span>
                  </label>
                </td>
              </tr>
            )}
            {Bedfood && Bedmeal && (
              <tr>
                <td className={styles.tdstyle}>Bedtime</td>
                <td className={styles.tdstyle}>{mealInfo?.Bedmeal}</td>
                <td>
                  <label className={styles.labelflex}>{mealInfo?.Bedfood}</label>
                  {/* <label className={styles.labelflex}>{mealInfo?.Bedmeal}</label> */}
                  <label className={styles.labelflex}>
                    <span className={styles.theredtable}>{mealInfo?.Bedremark}</span>
                  </label>
                </td>
              </tr>
            )}
          </table>
        </div>
        {mealInfo?.Additional && (
          <div className={styles.physicalActivitySection}>
            <div className={styles.headingSection}>
              <label>PHYSICAL ACTIVITY</label>
            </div>
            <ol className={styles.orderList}>
              <p className={styles.labelflex}>{mealInfo?.Additional}</p>
            </ol>
          </div>
        )}
        {/* <div className={styles.footer}>
          <img src={bg_bottom_right} className={styles.logintop} alt=""></img>
          <div className={styles.footermiddle}>
            <div className={styles.middleflex}>
              <div className={styles.footermiddlein + " " + styles.setmarginleft}>
                <img src={instagramf} className={styles.contactimg} alt=""></img>
                <p className={styles.footermiddlep}>dt.arpita_healvick</p>
              </div>
              <div className={styles.footermiddlein}>
                <img src={sms} className={styles.contactimg} alt=""></img>
                <p className={styles.footermiddlep}>dt.arpita@healvick.com</p>
              </div>
              <div className={styles.footermiddlein + " " + styles.setmarginright}>
                <img src={browse} className={styles.contactimg} alt=""></img>
                <p className={styles.footermiddlep}>www.healvick.com</p>
              </div>
            </div>
          </div>
          <img src={login_bg_bottom} className={styles.logintop} alt=""></img>
        </div> */}
      </div>
      <div className={styles.printBtn}>
        <button
          onClick={() => {
            handlePrint();
          }}
        >
          print
        </button>
      </div>
    </div>
  );
}

export default Planpdf;
