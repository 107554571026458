//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import Request from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import A_jain_logo from "../assets/images/A_Jain_logo.png";
import accessControlSidebarImage from "../assets/images/accessControlSidebarImage.png";
import bellIcon from "../assets/images/bellIcon.png";
import DashboardActive from "../assets/images/DashboardActive.png";
import hamBurgerImg from "../assets/images/hamBurgerImg.png";
import logoutSidebarImage from "../assets/images/logoutSidebarImage.png";
import manageClientsActive from "../assets/images/ManageClientActive.png";
import manageClientSidebarImage from "../assets/images/manageClientsSidebarImage.png";
import MealPlanActive from "../assets/images/MealPlanActive.png";
import CreateBillActive from "../assets/images/createBillActive.png";
import createBillSidebarImage from "../assets/images/createBillSidebarImage.png";
import AccessControlActive from "../assets/images/accessControlActive.png";
import mealPlansSidebarImage from "../assets/images/mealPlansSidebarImage.png";
import crossButton from "../assets/images/notificationCloseButotn.png";
import clientImageSidebar from "../assets/images/personIcon.png";
import searchIcon from "../assets/images/searchIconHeader.png";
import sidebarBottomImage from "../assets/images/sidebarBottomImage.png";
import HealthyRecipe from "../assets/images/HealthyRecipe.png";
import RaiseQuery from "../assets/images/RaiseQuery.png";
import dashboardSidebarImage from "../assets/images/sidebarDashboardImage.png";
import styles from "../assets/styles/Parent.module.css";
import { BASE_URL } from "./baseURL";
import NotificationPanel from "./NotificationPanel";
import { closeNav, closeSidebar, openNav, openSidebar } from "./ParentFunctions";
import QueryModal from "./QueryModal";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Parent({ children, routeName }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const notiDiv = useRef();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  let initialDetails = useSelector((state) => state.allData?.user_details);
  let parentFree = [
    "",
    "/",
    "/signup",
    "/forgotpassword",
    "/ChangePassword",
    "/changepassword",
    "/invoicepdf",
    "/planpdf",
    "/receipt",
    undefined,
  ];
  let showParent = parentFree.includes(routeName);
  const notiCount = useSelector((state) => state.allData?.notification_count);
  const [, setUserDetails] = useState(initialDetails);
  let userDetailsTemp = JSON.parse(localStorage.getItem("user"));
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showQueryModal, setShowQueryModal] = useState(false);
  let adminSearchOptions = [
    { keywords: ["admin", "dashboard"], path: "/admindashboard", name: "Dashboard" },
    { keywords: ["manage", "client"], path: "/manageclients", name: "Manage Clients" },
    { keywords: ["invoice", "bill"], path: "/invoice", name: "Invoice" },
  ];
  const pageName = useSelector((state) => state?.allData?.routes);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect for Admin Search Option-----------------------------------------------------------------------//

  useEffect(() => {
    if (searchText?.length > 0) {
      let tempOptions = [];
      userDetailsTemp?.Role == "Admin" &&
        adminSearchOptions?.map((gval) => {
          gval.keywords?.map((val) => {
            if (val.includes(searchText.toLowerCase())) {
              tempOptions.push({ path: gval.path, keyword: val, name: gval.name });
            }
          });
        });
      const unique = [...new Map(tempOptions.map((item) => [item["name"], item])).values()];
      setSearchOptions(() => unique);
    } else {
      setSearchOptions([]);
    }
  }, [searchText]);

  //---------------------------------------------------------UseEffect for Admin Search Option-----------------------------------------------------------------------//

  //---------------------------------------------------------UseLayout Effect-----------------------------------------------------------------------//

  useLayoutEffect(() => {
    function updateSize() {
      let a = document.getElementById("blackBackgroundNotification");
      let b = document.getElementById("sidebarMainDiv");
      let c = document.getElementById("clientInfo");
      let d = document.getElementById("redirectionLinks");
      if (window.innerWidth > 900) {
        if (document.getElementById("notificationPanel")?.getBoundingClientRect().width > 0) {
          openNav();
        }

        a && b && c && d && openSidebar();
      } else {
        if (document.getElementById("notificationPanel")?.getBoundingClientRect().width > 0) {
          openNav();
        }
        a && b && c && d && closeSidebar();
      }
    }
    window.addEventListener("resize", updateSize);
    if (!showParent) {
      updateSize();
    }
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  //---------------------------------------------------------UseLayout Effect-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  useEffect(() => {
    if (!userDetailsTemp?.id) {
      // routeName.includes("changepassword") && navigation("");
      localStorage.clear();
    }
    setUserDetails(userDetailsTemp);
    if (userDetailsTemp?.Role === "Client") {
      Request.post(`${BASE_URL}login/loginDetails`, { Client_id: userDetailsTemp?.id }).then(
        (res) => {
          let temp = userDetailsTemp;
          temp = {
            ...temp,
            PlanStatus: res?.data?.data[0]?.PlanStatus,
            PlanType: res?.data?.data[0]?.Type,
            PlanPurchasedate: res?.data?.data[0]?.Purchasedate,
            PlanExpired: res?.data?.data[0]?.isExpired,
            Profilepicture: res?.data?.data[0]?.Profilepicture,
            PlanStartDate: res?.data?.data[0]?.PlanStartDate,
            Logindate: res?.data?.data[0]?.Logindate,
            Firstname: res?.data?.data[0]?.Firstname,
            Lastname: res?.data?.data[0]?.Lastname,
            Mobilenumber: res?.data?.data[0]?.Mobilenumber,
            Planid: res?.data?.data[0]?.Planid,
          };
          setUserDetails(temp);
          dispatch({ type: "USER_DETAILS", payload: temp });
          localStorage.setItem("user", JSON.stringify(temp));
        }
      );
    }
  }, []);

  //---------------------------------------------------------UseEffect to set User Details-----------------------------------------------------------------------//

  //----------------------------------------------------UseEffect to Navigate to Change Password----------------------------------------------------------------//

  useEffect(() => {
    if (!userDetailsTemp && !showParent) {
      localStorage.clear();
      // alert("Please log in again");
      !routeName.includes("changepassword") && navigation("");
    }
  }, [userDetailsTemp]);

  //----------------------------------------------------UseEffect to Navigate to Change Password----------------------------------------------------------------//
  return (
    <div className={styles.MainDiv}>
      {!showParent ? (
        <>
          <div className={styles.sidebarMainDiv} id="sidebarMainDiv">
            {showQueryModal && <QueryModal setShowQueryModal={setShowQueryModal} />}
            <img className={styles.sidebarTopLogo} src={A_jain_logo} alt="" />
            <img
              className={styles.crossButtonSidebar}
              onClick={closeSidebar}
              src={crossButton}
              alt=""
            />
            <div
              className={styles.clientInfo}
              id="clientInfo"
              onClick={() => {
                if (userDetailsTemp?.Role === "Client") {
                  navigation("myprofileclient");
                } else {
                  navigation("myprofileadmin");
                }
              }}
            >
              <img
                src={
                  userDetailsTemp?.Profilepicture
                    ? `https://server.healvick.com/profile/${userDetailsTemp?.Profilepicture}`
                    : clientImageSidebar
                }
                className={styles.clientPhotoSidebar}
                alt=""
              />
              <label className={styles.clientNameSidebar}>
                {userDetailsTemp ? userDetailsTemp.Firstname : "Full Name"}{" "}
                {userDetailsTemp ? userDetailsTemp.Lastname : "Full Name"}
              </label>
              <label className={styles.clientPositionSidebar}>
                {userDetailsTemp ? userDetailsTemp.Role : "Position"}
              </label>
            </div>
            <div className={styles.redirectionLinks} id="redirectionLinks">
              {userDetailsTemp?.Role == "Admin" || userDetailsTemp?.Role == "Manager" ? (
                <ul>
                  <li
                    style={{ color: routeName == "/admindashboard" ? "#000000" : "#959595" }}
                    onClick={() => navigation("admindashboard")}
                  >
                    <img
                      src={routeName == "/admindashboard" ? DashboardActive : dashboardSidebarImage}
                      alt=""
                    />
                    Dashboard
                  </li>

                  <li
                    onClick={() => navigation("manageclients")}
                    style={{ color: routeName == "/manageclients" ? "#000000" : "#959595" }}
                  >
                    <img
                      src={
                        routeName == "/manageclients"
                          ? manageClientsActive
                          : manageClientSidebarImage
                      }
                      alt=""
                    />
                    Manage Clients
                  </li>

                  {userDetailsTemp?.Role === "Admin" && (
                    <li
                      onClick={() => navigation("accesscontrol")}
                      style={{ color: routeName == "/accesscontrol" ? "#000000" : "#959595" }}
                    >
                      <img
                        src={
                          routeName == "/accesscontrol"
                            ? AccessControlActive
                            : accessControlSidebarImage
                        }
                        alt=""
                      />
                      Access Control
                    </li>
                  )}

                  <li
                    style={{ color: routeName == "/newmealplan" ? "#000000" : "#959595" }}
                    onClick={() => routeName !== "/newmealplan" && navigation("newmealplan")}
                  >
                    <img
                      src={routeName == "/newmealplan" ? MealPlanActive : mealPlansSidebarImage}
                      alt=""
                    />
                    Meal Plans
                  </li>

                  <li
                    style={{ color: routeName == "/invoice" ? "#000000" : "#959595" }}
                    onClick={() => navigation("invoice")}
                  >
                    <img
                      src={routeName == "/invoice" ? CreateBillActive : createBillSidebarImage}
                      alt=""
                    />
                    Invoice
                  </li>

                  <li
                    onClick={() => {
                      localStorage.clear();
                      dispatch({ type: "USER_DETAILS", payload: null });
                      navigation("/");
                    }}
                  >
                    <img src={logoutSidebarImage} alt="" />
                    <label>Logout</label>
                  </li>
                </ul>
              ) : (
                <>
                  <ul>
                    <li
                      style={{ color: routeName == "/clientdashboard" ? "#000000" : "#959595" }}
                      onClick={() => {
                        if (userDetailsTemp) {
                          if (
                            userDetailsTemp?.PlanExpired != 1 &&
                            (userDetailsTemp?.PlanStatus == 1 || userDetailsTemp?.PlanStatus == 4)
                          ) {
                            navigation("clientdashboard");
                          } else {
                            alert("Please purchase a plan first");
                            navigation("plans");
                          }
                        } else {
                          localStorage.clear();
                          // alert("Please log in again");
                          !routeName.includes("changepassword") && navigation("");
                        }
                      }}
                    >
                      <img
                        src={
                          routeName == "/clientdashboard" ? DashboardActive : dashboardSidebarImage
                        }
                        alt=""
                      />
                      My Account
                    </li>

                    <li
                      style={{ color: routeName == "/plans" ? "#000000" : "#959595" }}
                      onClick={() => navigation("plans")}
                    >
                      <img
                        src={routeName == "/plans" ? MealPlanActive : mealPlansSidebarImage}
                        alt=""
                      />
                      Meal Plans
                    </li>
                    <li
                      style={{ color: routeName == "/newmealplan" ? "#000000" : "#959595" }}
                      onClick={() => setShowQueryModal(true)}
                    >
                      <img src={routeName == "/newmealplan" ? RaiseQuery : RaiseQuery} alt="" />
                      Raise Query
                    </li>
                    <li
                      style={{ color: routeName == "/newmealplan" ? "#000000" : "#959595" }}
                      onClick={() => window.open("https://healvick.com/recipes/")}
                    >
                      <img
                        src={routeName == "/newmealplan" ? HealthyRecipe : HealthyRecipe}
                        alt=""
                      />
                      Healthy Recipes
                    </li>
                    <li
                      onClick={() => {
                        dispatch({ type: "USER_DETAILS", payload: null });
                        localStorage.removeItem("user");
                        navigation("/");
                      }}
                    >
                      <img src={logoutSidebarImage} alt="" />
                      Logout
                    </li>
                  </ul>
                </>
              )}
            </div>
            <img className={styles.sidebarBottomImage} src={sidebarBottomImage} alt="" />
          </div>
        </>
      ) : (
        <></>
      )}
      {!showParent ? (
        <div
          className={styles.rightContentMainDiv}
          style={{ width: routeName !== "" ? "80%" : "100%", height: "100%" }}
        >
          <div className={styles.headerMainDiv}>
            <img
              src={hamBurgerImg}
              className={styles.hamBurgerImgDiv}
              alt=""
              onClick={openSidebar}
            />
            <label className={styles.DynamicHeading}>{pageName ? pageName : ""}</label>
            <div className={styles.searchBar}>
              <input
                value={searchText}
                type="text"
                name="pwd"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(() => e.target.value);
                }}
                className={styles.searchBarInput}
              />
              <div style={{ flexDirection: "column" }}>
                {searchOptions?.map((val, i) => {
                  return (
                    <label
                      key={String(i)}
                      onClick={() => {
                        setSearchOptions([]);
                        setSearchText("");
                        navigation(val.path);
                      }}
                    >
                      {val?.name}
                    </label>
                  );
                })}
              </div>
              <button type="button" id={styles.searchIcon}>
                <img src={searchIcon} alt="" />
              </button>
            </div>
            <div
              className={styles.blackBackgroundNotification}
              id="blackBackgroundNotification"
              onClick={() => {
                closeNav();
                if (window.innerWidth < 900) {
                  closeSidebar();
                }
              }}
            ></div>
            <div className={styles.notificationDiv} ref={notiDiv}>
              <img className={styles.notificationIcon} onClick={openNav} src={bellIcon} alt="" />
              {notiCount > 0 && <p>{notiCount > 9 ? "9+" : notiCount}</p>}
            </div>
            <div className={styles.profileMobile}>
              <div className={styles.headerProfileBox}>
                <img
                  className={styles.headerprofileImage}
                  src={
                    userDetailsTemp?.Profilepicture
                      ? `https://server.healvick.com/profile/${userDetailsTemp?.Profilepicture}`
                      : clientImageSidebar
                  }
                  alt=""
                  onClick={() => {
                    if (userDetailsTemp?.Role === "Client") {
                      navigation("myprofileclient");
                    } else {
                      navigation("myprofileadmin");
                    }
                  }}
                />
              </div>
            </div>
            <NotificationPanel closeNav={closeNav} />
          </div>
          <div className={styles.childrenDiv}>{children}</div>
          <div className={styles.footerMainDiv}>
            <label>Healvick &#169; 2023 All Rights Reserved</label>
          </div>
        </div>
      ) : (
        <div className={styles.childrenDivOther}>{children}</div>
      )}
    </div>
  );
}

export default Parent;
