//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../assets/styles/ClientDropdown.module.css";
import dropArrow from '../assets/images/dropArrow.png'
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const ClientDropdown = ({ setMask, mask, data, selectedItem, setSelectedItem,bill }) => {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [show, setShow] = useState(false);
  const options = data;
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set Selected Item-----------------------------------------------------------------------//

  useEffect(() => {!mask && setShow(false); }, [mask]);
  function onItemClick(val) {
    setSelectedItem(val);
  }
  
  //---------------------------------------------------------UseEffect to set Selected Item-----------------------------------------------------------------------//

  return (
    <div className={styles.mainwidth}>
      <div className={styles.dropdownDisplay} role="button" onClick={() => {
          setShow((val)=>!val);
          setMask(true);
        }}>
        <div className={styles.dropdownnname}>
          <p className={styles.dropheadingtext}> {selectedItem?.Firstname ? selectedItem?.Firstname + " " + selectedItem?.Lastname : "Select a client"} </p>
          <img src={dropArrow} alt="" className={styles.rupeeSize}></img>
        </div>
      </div>
      {show && (
        <div className={bill?styles["dropContBill"]:styles["dropCont"]} onBlur={() => setShow(false)}>
          {options?.map((val, i) => {
            return (
              <a key={String(i)} onClick={() => { onItemClick(val); setShow(false); setMask(false); }} className={styles["items"]}
                style={{ backgroundColor: val === selectedItem ? "#E6F8F1" : "transparent" }} >
                {val?.Firstname} {val?.Lastname} ({val?.EmailId})
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ClientDropdown;
