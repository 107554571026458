//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useState } from "react";
import styles from "../assets/styles/UpdateInfoModal.module.css";
import WeightTrackerModal from "../components/weightTrackerModal";
import radioActive from "../assets/images/radioActive.png";
import radioInactive from "../assets/images/radioInactive.png";
import InchModal from "./InchModal";
import BloodTrackerModal from "./BloodTrackerModal";
import PeriodTracker from "./PeriodTracker";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function UpdateInfoModal({ setShowUpdateInfoModal }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [selectedModal, setSelectedModal] = useState("Weight");
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  return (
    <>
      <div className={styles.modalBackground}></div>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div>
            <p className={styles.title}>Update Physical Information </p>
          </div>
          <div className={styles.radioDiv}>
            <div
              className={styles.radioIn}
              onClick={() => {
                setSelectedModal("Weight");
              }}
            >
              <img
                className={styles.radioImg}
                src={selectedModal === "Weight" ? radioActive : radioInactive}
                alt=""
              />
              <p className={styles.blacklabel}>Weight Tracker</p>
            </div>
            <div
              className={styles.radioIn}
              onClick={() => {
                setSelectedModal("Inch");
              }}
            >
              <img
                className={styles.radioImg}
                src={selectedModal === "Inch" ? radioActive : radioInactive}
                alt=""
              />
              <p className={styles.blacklabel}>Inch Tracker</p>
            </div>
            <div
              className={styles.radioIn}
              onClick={() => {
                setSelectedModal("Blood");
              }}
            >
              <img
                className={styles.radioImg}
                src={selectedModal === "Blood" ? radioActive : radioInactive}
                alt=""
              />
              <p className={styles.blacklabel}>Blood Tracker</p>
            </div>
            <div
              className={styles.radioIn}
              onClick={() => {
                setSelectedModal("Period");
              }}
            >
              <img
                className={styles.radioImg}
                src={selectedModal === "Period" ? radioActive : radioInactive}
                alt=""
              />
              <p className={styles.blacklabel}>Period Tracker</p>
            </div>
          </div>
          <div className={styles.theflex}>
            <div className={styles.divider}></div>
          </div>
          <div className={styles.paddingofone}>
            {selectedModal === "Weight" && (
              <WeightTrackerModal setShowUpdateInfoModal={setShowUpdateInfoModal} />
            )}
          </div>
          <div className={styles.paddingofone}>
            {selectedModal === "Inch" && (
              <InchModal setShowUpdateInfoModal={setShowUpdateInfoModal} />
            )}
          </div>
          <div className={styles.paddingofone}>
            {selectedModal === "Blood" && (
              <BloodTrackerModal setShowUpdateInfoModal={setShowUpdateInfoModal} />
            )}
          </div>
          <div className={styles.paddingofone}>
            {selectedModal === "Period" && (
              <PeriodTracker setShowUpdateInfoModal={setShowUpdateInfoModal} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateInfoModal;
