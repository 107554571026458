//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/styles/LoginPage.module.css";
import login_bg_right from "../assets/images/login_bg_right.png";
import login_bg_rightTabPhone from "../assets/images/login_bg_rightTabPhone.png";
import login_bg_rightPhone from "../assets/images/login_bg_rightPhone.png";
import login_bg_top from "../assets/images/login_bg_top.png";
import login_bg_bottom from "../assets/images/login_bg_bottom.png";
import login_bg_topTabPhone from "../assets/images/login_bg_topTabPhone.png";
import login_bg_bottomTabPhone from "../assets/images/login_bg_bottomTabPhone.png";
import login_bg_centre from "../assets/images/login_bg_centre.png";
import A_jain_logo from "../assets/images/A_Jain_logo.png";
import instagramHandle from "../assets/images/instagram.png";
import facebookHandle from "../assets/images/facebook.png";
import closedEye from "../assets/images/closedEye.png";
import openEye from "../assets/images/openEye.png";
import Request from "axios";
import { BASE_URL } from "../components/baseURL";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function LoginPage() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  const [showPass, setShowPass] = useState(false);
  const email = useRef();
  const pass = useRef();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //--------------------------------------------------------------------UseEffect for RoleWise Navigation---------------------------------------------------------------------------//

  useEffect(() => {
    let cache = JSON.parse(localStorage.getItem("login"));
    let user = JSON.parse(localStorage.getItem("user"));
    if (cache) {
      email.current.value = cache.Emailid;
      pass.current.value = cache.Password;
      document.getElementById("rememberme").checked = true;
    }
    if (user) {
      navigation(
        user?.Role === "Admin" || user?.Role === "Manager" ? "/admindashboard" : "/clientdashboard"
      );
    }
  }, []);

  //--------------------------------------------------------------------UseEffect for RoleWise Navigation---------------------------------------------------------------------------//


  //-----------------------------------------------------Validation function-------------------------------------------------------------//

  async function validate() {
    let emailValidate = document.getElementById("emailValidate");
    let passwordValidate = document.getElementById("passwordValidate");
    let errorMessageEmail = document.getElementById("errorMessageEmail");
    let errorMessagePassword = document.getElementById("errorMessagePassword");
    let rm = document.getElementById("rememberme").checked;
    if (email.current.value?.length < 1) {
      emailValidate.style.border = "2px solid red";
      errorMessageEmail.style.display = "block";
      errorMessageEmail.textContent = "Please Enter Email";
      if (pass.current.value?.length < 1) {
        passwordValidate.style.border = "2px solid red";
        errorMessagePassword.style.display = "block";
        errorMessagePassword.textContent = "Please Enter Password";
      }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.current.value)) {
      emailValidate.style.border = "2px solid red";
      errorMessageEmail.style.display = "block";
      errorMessageEmail.textContent = "Please Enter Valid Email";
      if (!/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(pass.current.value)) {
        passwordValidate.style.border = "2px solid red";
        errorMessagePassword.style.display = "block";
        errorMessagePassword.textContent =
          "Password Must include: Capital letter, Small Letter, special character, number";
      }
    } else {
      login(rm);
      errorMessageEmail.style.display = "none";
      errorMessagePassword.style.display = "none";
      emailValidate.style.border = "1.5px solid #D9D9D9";
      passwordValidate.style.border = "1.5px solid #D9D9D9";
    }
  }

  //-----------------------------------------------------Validation function-------------------------------------------------------------//

  //----------------------------------------------------------Login Function-------------------------------------------------------------//
  
  async function login(rm) {
    Request.post(`${BASE_URL}login`, {
      Emailid: email.current.value,
      Password: pass.current.value,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          localStorage.setItem("user", JSON.stringify({ ...res.data.data }));
          if (rm) {
            localStorage.setItem(
              "login",
              JSON.stringify({
                Emailid: email.current.value,
                Password: pass.current.value,
              })
            );
          } else {
            localStorage.removeItem("login");
          }

          if (res?.data?.data?.Verified == 1) {
            dispatch({ type: "USER_DETAILS", payload: { ...res.data.data } });
            if (res?.data?.data?.Role === "Client") {
              if (res?.data?.data?.PlanExpired == 0 && res?.data?.data?.PlanStatus == 1) {
                navigation("clientdashboard");
              } else {
                navigation("plans");
              }
            } else {
              navigation("admindashboard");
            }
          } else {
            alert(
              "Please verify your email address. A link has been shared to your registered email id."
            );
          }
        } else {
          if (res?.data?.message === "Invalid Password") {
            alert("Invalid Password");
          } else if (res?.data?.message === "User Not Found") {
            alert("Invalid Email Id");
          }
        }
      })
      .catch((err) => {
        alert("Error logging in");
        console.log("Error logging in: ", err);
      });
  }

  //----------------------------------------------------------Login Function------------------------------------------------------------//

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftInputs}>
        <img className={styles.AJainLogo} src={A_jain_logo} alt="" />
        <label className={styles.signInText}>Sign In</label>
        <label className={styles.belowSignInText}>
          Hey! Enter your details to get sign in to your account
        </label>
        <div className={styles.inputCred}>
          <input
            className={styles.inputUserName}
            type="text"
            placeholder="Email"
            ref={email}
            id="emailValidate"
          />
          <span className={styles.errorSpan} id="errorMessageEmail"></span>
          <div className={styles.passwordWithEye}>
            <input
              type={showPass ? "text" : "password"}
              name="pwd"
              placeholder="Password"
              ref={pass}
              id="passwordValidate"
            />
            <button
              type="button"
              className={styles.showPasswd}
              onClick={() => {
                setShowPass(() => !showPass);
              }}
            >
              <img src={showPass ? openEye : closedEye} alt="" />
            </button>
          </div>
          <span className={styles.errorSpan} id="errorMessagePassword"></span>
        <div className={styles.rememberMeAndForgotPass}>
          <div className={styles.rememberMeDiv}>
            <input type="checkbox" id="rememberme" />
            <label htmlFor="rememberme" className={styles.rememText}>Remember Me</label>
          </div>
          <div className={styles.rememberMeDiv}>
            <label
              className={styles.signupRedirectALink}
              onClick={() => {
                navigation("forgotpassword");
              }}
            >
              Forgot Password?
            </label>
          </div>
        </div>
        <button className={styles.signInButton} onClick={validate}>Sign In</button>
        </div>
        <div className={styles.signupRedirect}>
          <label className={styles.signupRedirectLabel}>New User?</label>
          <label
            className={styles.signupRedirectALink}
            onClick={() => {
              navigation("/signup");
            }}
          >
            {" "}
            Sign Up Now
          </label>
        </div>
        <div className={styles.signupRedirect}>
          <label className={styles.signupRedirectLabel}>Have a query?</label>
          <label
            className={styles.signupRedirectALink}
            onClick={() => {
              window.open(`https://healvick.com/contact-us/`);
            }}
          >
            {" "}
            Contact us
          </label>
        </div>
        <div className={styles.followUsTextAndTag}>
          <div className={styles.followUsText}>
            <label>Follow Us</label>
          </div>
          <div className={styles.followUsTag}>
            <a
              href="https://instagram.com/dt.arpita_healvick?igshid=NGVhN2U2NjQ0Yg=="
              target="_blank"
            >
              <img src={instagramHandle} alt="" />
            </a>
            <a href="https://www.facebook.com/healvick?mibextid=hrBMPu" target="_blank">
              <img src={facebookHandle} alt="" />
            </a>
            {/* <img src={twitterHandle} alt="" /> */}
          </div>
        </div>
      </div>
      <div className={styles.rightImages}>
        <div className={styles.rightImagesInsideParentOne}>
          <div className={styles.rightImagesInsideChildOne}>
            <img className={styles.loginBgTop} src={login_bg_top} alt="" />
            <img className={styles.loginBgBottom} src={login_bg_bottom} alt="" />
          </div>
          <img className={styles.loginBgRight} src={login_bg_right} alt="" />
        </div>
        <div className={styles.rightImagesInsideParentTwo}>
          <img className={styles.loginBgCentre} src={login_bg_centre} alt="" />
        </div>
      </div>
      <div className={styles.rightImagesTabPhone}>
        <div className={styles.rightImagesInsideParentOneTabPhone}>
          <img className={styles.loginBgRightTabPhone} src={login_bg_rightTabPhone} alt="" />
          <img className={styles.loginBgRightPhone} src={login_bg_rightPhone} alt="" />
          <div className={styles.rightImagesInsideParentTwoTabPhone}>
            <img className={styles.loginBgCentreTabPhone} src={login_bg_centre} alt="" />
          </div>
          <div className={styles.rightImagesInsideChildOneTabPhone}>
            <img className={styles.loginBgTopTabPhone} src={login_bg_topTabPhone} alt="" />
            <img className={styles.loginBgBottomTabPhone} src={login_bg_bottomTabPhone} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
