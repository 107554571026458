//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import meal_plan_icon from "../../assets/images/meal_plan_icon.png";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import styles from "../../assets/styles/AccessControl.module.css";
import $ from "jquery";
import Request from "axios";
import { BASE_URL } from "../../components/baseURL";
import moment from "moment";
import ManagerRegistrationModal from "../../components/ManagerRegistrationModal";
import ManagerDropdown from "../../components/ManagerDropdown";
import { JsonToExcel } from "react-json-to-excel";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function AccessControl() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [mask, setMask] = useState();
  const [showManagerModal, setShowManagerModal] = useState(false);
  const [excelList, setExcelList] = useState([]);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Get Managers Function-----------------------------------------------------------------------//

  async function getManagers() {
    Request.get(`${BASE_URL}manager/getManager`)
      .then((res) => {
        setClientList(res?.data?.data);

        $(() => {
          $("#myTable").DataTable();
        });
        let exceltemp = [];
        res?.data?.data?.map((val) => {
          exceltemp.push({
            Firstname: val.Firstname,
            Lastname: val.Lastname,
            EmailId: val.EmailId,
            Mobilenumber: val.Mobilenumber,
            Logindate: val.Logindate,
          });
        });
        setExcelList(exceltemp);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      });
  }

  //---------------------------------------------------------Get Managers Function-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Get Managers-----------------------------------------------------------------------//

  useEffect(() => {
    getManagers();
  }, []);

  //---------------------------------------------------------useEffect to Get Managers-----------------------------------------------------------------------//

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  function styleChanges() {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document.querySelectorAll(".dataTables_wrapper").forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document.querySelectorAll(".dataTables_length").forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
  }

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to set style to mask-----------------------------------------------------------------------//
  
  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = mask ? "150px" : "50px"));
  }, [mask]);

  //---------------------------------------------------------useEffect to set style to mask-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to Dispatch Routes-----------------------------------------------------------------------//

  useEffect(() => {
    setTimeout(() => {
      styleChanges();
    }, [200]);
    dispatch({ type: "ROUTES", payload: "Access Control" });
  }, []);

  //---------------------------------------------------------useEffect to Dispatch Routes-----------------------------------------------------------------------//

  //---------------------------------------------------------useEffect to set style to sidebar-----------------------------------------------------------------------//

  useEffect(() => {
    if (!showManagerModal) {
      document.getElementById("sidebarMainDiv").style.zIndex = 5;
    } else {
      document.getElementById("sidebarMainDiv").style.zIndex = 1;
    }
  }, [showManagerModal]);
  
  //---------------------------------------------------------useEffect to set style to sidebar-----------------------------------------------------------------------//

  return (
    <div className={bootstrap["bootstrap-iso"]}>
      {showManagerModal && <ManagerRegistrationModal setShowManagerModal={setShowManagerModal} />}
      {mask && (
        <div
          style={{ width: window.innerWidth, height: window.innerHeight }}
          className={styles.mask}
          onClick={() => {
            setMask(false);
          }}
        ></div>
      )}
      <div className={styles.MainDiv}>
        <div className={styles.ManageClientsRow}>
          <div className={styles.FormSection}>
            <div className={styles.MainRowsec}>
              <div className={styles.labeldivcls}>
                <label className={styles.labelHeadingcls}>Manager Lists</label>
              </div>
              <div className={styles.customBtnsec}>
                <button
                  className={styles.customButton}
                  onClick={() => {
                    setShowManagerModal(true);
                  }}
                >
                  <img className={styles.planIconcls} src={meal_plan_icon} alt="" />
                  New Manager Registration
                </button>

                {/* <button className={styles.customexportButton}>
                  <img className={styles.planIconcls} src={excelExport} alt="" />
                  <p>Excel Export</p>
                </button> */}
                <JsonToExcel
                  title="Excel Export"
                  data={excelList}
                  fileName="Manager List"
                  btnClassName={styles.customexportButton}
                />
              </div>
            </div>
          </div>
        </div>
        <table
          id="myTable"
          className={bootstrap[("table", "table-striped")] + " " + styles.tableCustomcls}
        >
          <thead>
            <tr>
              {/* <th>
                <input type="checkbox" id="selectallchk" name="selectallchk" value="" />
              </th> */}
              <th className={styles.Tablefontcls}>Employee Name</th>
              <th className={styles.Tablefontcls}>Email</th>
              <th className={styles.Tablefontcls}>Mobile No.</th>
              <th className={styles.Tablefontcls}>Date of joining</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clientList?.length > 0 &&
              clientList?.map((val, i) => {
                return (
                  <tr key={String(i)}>
                    {/* <td>
                      <input type="checkbox" id="seluserid" name="seluserid" />
                    </td> */}
                    <td className={styles.Tablefonttdcls}>
                      {val?.Firstname} {val?.Lastname}
                    </td>
                    <td className={styles.Tablefonttdcls}>{val?.EmailId}</td>
                    <td className={styles.Tablefonttdcls}>{val?.Mobilenumber}</td>
                    <td className={styles.Tablefonttdcls}>
                      {val?.CreatedDate ? moment(val?.CreatedDate).format("YYYY-MM-DD") : "NULL"}
                    </td>
                    <td>
                      <ManagerDropdown setMask={setMask} mask={mask} id={i} data={val} />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AccessControl;
