//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import bootstrap from "../assets/bootstrapStyles/bootstrap.min.module.css";
import styles from "../assets/styles/Dropdown.module.css";
import table_dropDown_icon from "../assets/images/table_dropDown_icon.png";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

const ManagerDropdown = ({ setMask, mask, data }) => {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const options = ["Manager History"];
  const [selectedItem, setSelectedItem] = useState();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to set show-----------------------------------------------------------------------//

  useEffect(() => {
    !mask && setShow(false);
  }, [mask]);

  //---------------------------------------------------------UseEffect to set show-----------------------------------------------------------------------//

  //---------------------------------------------------------On Item Click Function-----------------------------------------------------------------------//

  function onItemClick(val) {
    if (val === "Manager History") {
      navigation("/managerhistory", { state: { data: data } });
    }
  }
  //---------------------------------------------------------On Item Click Function-----------------------------------------------------------------------//
  return (
    <div className={bootstrap["dropdown"]}>
      <a
        role="button"
        onClick={() => {
          setShow(true);
          setMask(true);
        }}
      >
        <img src={table_dropDown_icon} />
      </a>
      {show && (
        <div className={styles["dropCont"]} onBlur={() => setShow(false)}>
          {options?.map((val, i) => {
            return (
              <a
                key={String(i)}
                onClick={() => {
                  onItemClick(val);
                  setShow(false);
                  setMask(false);
                }}
                className={styles["items"]}
                style={{ backgroundColor: val === selectedItem ? "#E6F8F1" : "transparent" }}
              >
                {val}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ManagerDropdown;
