//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import style from "../../assets/styles/ClientAssessmentForm.module.css";
import CA1 from "./CA/CA1";
import CA2 from "./CA/CA2";
import CA3 from "./CA/CA3";
import CA4 from "./CA/CA4";
import CA5 from "./CA/CA5";
import { BASE_URL } from "../../components/baseURL";
import { useNavigate } from "react-router";
import Request from "axios";
import CA9 from "./CA/CA9";
import CA8 from "./CA/CA8";
import CA7 from "./CA/CA7";
import CA6 from "./CA/CA6";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ClientAssessmentForm({ profile, admin, id }) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [pageIndex, setPageIndex] = useState(1);
  const [barWidth, setBarWidth] = useState("11.12%");
  const [data, setData] = useState();
  const age = useRef();
  const gender = useRef();
  const fullname = useRef();
  const caste = useRef();
  const mobile = useRef();
  const address = useRef();
  const zipcode = useRef();
  const country = useRef();
  const state = useRef();
  const city = useRef();
  const height = useRef();
  const weight = useRef();
  const lastWeight = useRef();
  const dreamWeight = useRef();
  const activityPattern = useRef();
  const activityPatternOther = useRef();
  const occupation = useRef();
  const jobTime = useRef();
  const workTravel = useRef();
  const personalTravel = useRef();
  const travelCountry = useRef();
  const workMeals = useRef();
  const homeCook = useRef();
  const familyType = useRef();
  const familyTypeOther = useRef();
  const maritalStatus = useRef();
  const maritalStatusOther = useRef();
  const totalKids = useRef();
  const kidsAge = useRef();
  const eatinghabits = useRef();
  const eatingHabitsOther = useRef();
  const preferredCuisine = useRef();
  const smoking = useRef();
  const smokingOther = useRef();
  const alcohol = useRef();
  const alcoholOther = useRef();
  const preferredAlcohol = useRef();
  const waterIntake = useRef();
  const religiousRestrictions = useRef();
  const fasting = useRef();
  const foodAllergies = useRef();
  const foodDislikes = useRef();
  const frequencyOfAlcohol = useRef();
  const sleepPattern = useRef();
  const bowelMovement = useRef();
  const regularPeriods = useRef();
  const regularPeriodsOther = useRef();
  const lastMonthlyCycle = useRef();
  const remark = useRef();
  const physicalactivity = useRef();
  const physicalactivityother = useRef();
  const medicalHistory = useRef();
  const medicalHistoryOther = useRef();
  const currentMedication = useRef();
  const wakeTime = useRef();
  const prefer = useRef();
  const preferOther = useRef();
  const breakfastTime = useRef();
  const breakfastFood = useRef();
  const lunchFood = useRef();
  const eveningSnack = useRef();
  const dinnerFood = useRef();
  const wholeDayFood = useRef();
  const bedTime = useRef();
  const pizza = useRef();
  const pasta = useRef();
  const maggie = useRef();
  const biscuits = useRef();
  const chaat = useRef();
  const ghee = useRef();
  const butter = useRef();
  const fruits = useRef();
  const fishChickenMeat = useRef();
  const bread = useRef();
  const popcornChips = useRef();
  const chocolate = useRef();
  const egg = useRef();
  const iceCream = useRef();
  const lateNightBinging = useRef();
  const samosaVadapav = useRef();
  const cheese = useRef();
  const C1 = useRef();
  const C2 = useRef();
  const C3 = useRef();
  const C4 = useRef();
  const C5 = useRef();
  const C6 = useRef();
  const C7 = useRef();
  const C8 = useRef();
  const C9 = useRef();
  const dispatch = useDispatch();
  let temp = JSON.parse(localStorage.getItem("user"));
  const navigation = useNavigate();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Check Client Assessment Function -----------------------------------------------------------------------//

  function checkClientAssessment() {
    Request.post(`${BASE_URL}client/getClientAssessment`, {
      Client_id: profile ? temp?.id : id,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          setData(res?.data?.data[0]);
        } else {
          alert("Error checking for assessment form");
          console.log("Error checking for assessment", res?.data?.error);
        }
      })
      .catch((err) => {
        console.log("Error checking for assessment form", err);
      });
  }

  //---------------------------------------------------------Check Client Assessment Function -----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Alert User-----------------------------------------------------------------------//

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  //---------------------------------------------------------UseEffect to Alert User-----------------------------------------------------------------------//

  //---------------------------------------------------------Alert User-----------------------------------------------------------------------//

  const alertUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("CA1");
    localStorage.removeItem("CA2");
    localStorage.removeItem("CA3");
    localStorage.removeItem("CA4");
    localStorage.removeItem("CA5");
    localStorage.removeItem("CA6");
    localStorage.removeItem("CA7");
    localStorage.removeItem("CA8");
    localStorage.removeItem("CA9");
  };

  //---------------------------------------------------------Alert User-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Dispatch Routes-----------------------------------------------------------------------//

  useEffect(() => {
    if (!temp?.Firstname) {
      navigation("/");
    }
    dispatch({ type: "ROUTES", payload: "Health Assessment" });
    (profile || admin) && checkClientAssessment();
  }, []);

  //---------------------------------------------------------UseEffect to Dispatch Routes-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Set Bar Width-----------------------------------------------------------------------//

  useEffect(() => {
    setBarWidth(() => pageIndex * 11.11);
  }, [pageIndex, data]);

  //---------------------------------------------------------UseEffect to Set Bar Width-----------------------------------------------------------------------//

  //---------------------------------------------------------Post Client Assessment Function -----------------------------------------------------------------------//

  async function clientassessment() {
    let temp = JSON.parse(localStorage.getItem("user"));
    let c1 = JSON.parse(localStorage.getItem("CA1"));
    let c2 = JSON.parse(localStorage.getItem("CA2"));
    let c3 = JSON.parse(localStorage.getItem("CA3"));
    let c4 = JSON.parse(localStorage.getItem("CA4"));
    let c5 = JSON.parse(localStorage.getItem("CA5"));
    let c6 = JSON.parse(localStorage.getItem("CA6"));
    let c7 = JSON.parse(localStorage.getItem("CA7"));
    let c8 = JSON.parse(localStorage.getItem("CA8"));
    let c9 = JSON.parse(localStorage.getItem("CA9"));
    let cache = { ...c1, ...c2, ...c3, ...c4, ...c5, ...c6, ...c7, ...c8, ...c9 };
    Request.post(`${BASE_URL}client/clientassessment`, {
      Age: cache?.Age,
      Gender: cache?.Gender ? cache?.Gender : "Male",
      Caste: cache?.Caste,
      Address: cache?.Address,
      Country: cache?.Country,
      City: cache?.City,
      Zipcode: cache?.Zipcode,
      Mobile: cache?.Mobile,
      State: cache?.State,
      Fullname: cache?.Fullname,
      Height: cache?.Height,
      Weight: cache?.Weight,
      LastWeight: cache?.LastWeight,
      Goal: cache?.Goal,
      Activitypattern: cache?.Activitypattern,
      Occupation: cache?.Occupation,
      Jobtiming: cache?.Jobtiming,
      Travelling: cache?.Travelling,
      Traveloften: cache?.Traveloften,
      Travelwithin: cache?.Travelwithin,
      Carrymeal: cache?.Carrymeal,
      Whocook: cache?.Whocook,
      Familytype: cache?.Familytype,
      Maritalstatus: cache?.Maritalstatus,
      Kids: cache?.Kids,
      Childrenage: cache?.Childrenage,
      Eatinghabit: cache?.Eatinghabit,
      Preferredcuisine: cache?.Preferredcuisine,
      Smoking: cache?.Smoking,
      Alcohol: cache?.Alcohol,
      Alcoholconsumption: cache?.Alcoholconsumption,
      Preferredalcohol: cache?.Preferredalcohol,
      Waterintake: cache?.Waterintake,
      Religiousrestrictions: cache?.Religiousrestrictions,
      Fastingfrequency: cache?.Fastingfrequency,
      Foodallergies: cache?.Foodallergies,
      Fooddislike: cache?.Fooddislike,
      Sleeppattern: cache?.Sleeppattern,
      Bowelmovement: cache?.Bowelmovement,
      Regularperiods: cache?.Regularperiods,
      Monthlycycledate: cache?.Monthlycycledate?.length > 0 ? cache?.Monthlycycledate : null,
      Description: cache?.Description,
      Client_id: temp?.id,
      Mobilenumber: temp?.Mobilenumber,
      Emailid: temp?.Emailid,
      PhysicalActivity: cache?.PhysicalActivity,
      MedicalHistory: cache?.MedicalHistory,
      CurrentMedication: cache?.CurrentMedication,
      WakeTime: cache?.WakeTime,
      BreakfastTime: cache?.BreakfastTime,
      BreakfastFood: cache?.BreakfastFood,
      LunchFood: cache?.LunchFood,
      EveningSnack: cache?.EveningSnack,
      DinnerFood: cache?.DinnerFood,
      WholeDayFood: cache?.WholeFood,
      BedTime: cache?.BedTime,
      Prefer: cache?.Prefer,
      Pizza: cache?.Pizza,
      Pasta: cache?.Pasta,
      Maggie: cache?.Maggie,
      Biscuits: cache?.Biscuits,
      Chaat: cache?.Chaat,
      Ghee: cache?.Ghee,
      Butter: cache?.Butter,
      Fruits: cache?.Fruits,
      FishChickenMeat: cache?.FishChickenMeat,
      Bread: cache?.Bread,
      PopcornChips: cache?.PopcornChips,
      Chocolate: cache?.Chocolate,
      Egg: cache?.Egg,
      Icecream: cache?.Icecream,
      LateNightBinging: cache?.LateNightBinging,
      SamosaVadapav: cache?.SamosaVadapav,
      Cheese: cache?.Cheese,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Assessment filled");
          localStorage.removeItem("CA1");
          localStorage.removeItem("CA2");
          localStorage.removeItem("CA3");
          localStorage.removeItem("CA4");
          localStorage.removeItem("CA5");
          localStorage.removeItem("CA6");
          localStorage.removeItem("CA7");
          localStorage.removeItem("CA8");
          localStorage.removeItem("CA9");
          navigation("/clientdashboard");
        } else {
          alert(
            "Error filling assessment. Have you filled the form before? Please check your profile section"
          );
        }
      })
      .catch((err) => {
        alert("Error uploading assessment form in");
        console.log("Error uploading assessment form in: ", err);
      });
  }

  //---------------------------------------------------------Post Client Assessment Function -----------------------------------------------------------------------//

  //---------------------------------------------------------Update Client Assessment Function -----------------------------------------------------------------------//

  async function updateClientassessment() {
    let temp = JSON.parse(localStorage.getItem("user"));
    let c1 = JSON.parse(localStorage.getItem("CA1"));
    let c2 = JSON.parse(localStorage.getItem("CA2"));
    let c3 = JSON.parse(localStorage.getItem("CA3"));
    let c4 = JSON.parse(localStorage.getItem("CA4"));
    let c5 = JSON.parse(localStorage.getItem("CA5"));
    let c6 = JSON.parse(localStorage.getItem("CA6"));
    let c7 = JSON.parse(localStorage.getItem("CA7"));
    let c8 = JSON.parse(localStorage.getItem("CA8"));
    let c9 = JSON.parse(localStorage.getItem("CA9"));
    let cache = { ...c1, ...c2, ...c3, ...c4, ...c5, ...c6, ...c7, ...c8, ...c9 };
    Request.put(`${BASE_URL}client/Updateclientassessment`, {
      Age: cache?.Age,
      Gender: cache?.Gender ? cache?.Gender : "Male",
      Caste: cache?.Caste,
      Address: cache?.Address,
      Country: cache?.Country,
      City: cache?.City,
      Zipcode: cache?.Zipcode,
      Mobilenumber: cache?.Mobile,
      State: cache?.State,
      Fullname: cache?.Fullname,
      Height: cache?.Height,
      Weight: cache?.Weight,
      LastWeight: cache?.LastWeight,
      Goal: cache?.Goal,
      Activitypattern: cache?.Activitypattern,
      Occupation: cache?.Occupation,
      Jobtiming: cache?.Jobtiming,
      Travelling: cache?.Travelling,
      Traveloften: cache?.Traveloften,
      Travelwithin: cache?.Travelwithin,
      Carrymeal: cache?.Carrymeal,
      Whocook: cache?.Whocook,
      Familytype: cache?.Familytype,
      Maritalstatus: cache?.Maritalstatus,
      Kids: cache?.Kids,
      Childrenage: cache?.Childrenage,
      Eatinghabit: cache?.Eatinghabit,
      Preferredcuisine: cache?.Preferredcuisine,
      Smoking: cache?.Smoking,
      Alcohol: cache?.Alcohol,
      Alcoholconsumption: cache?.Alcoholconsumption,
      Preferredalcohol: cache?.Preferredalcohol,
      Waterintake: cache?.Waterintake,
      Religiousrestrictions: cache?.Religiousrestrictions,
      Fastingfrequency: cache?.Fastingfrequency,
      Foodallergies: cache?.Foodallergies,
      Fooddislike: cache?.Fooddislike,
      Sleeppattern: cache?.Sleeppattern,
      Bowelmovement: cache?.Bowelmovement,
      Regularperiods: cache?.Regularperiods,
      Monthlycycledate: cache?.Monthlycycledate?.length > 0 ? cache?.Monthlycycledate : null,
      Description: cache?.Description,
      Client_id: temp?.id,
      PhysicalActivity: cache?.PhysicalActivity,
      MedicalHistory: cache?.MedicalHistory,
      CurrentMedication: cache?.CurrentMedication,
      WakeTime: cache?.WakeTime,
      BreakfastTime: cache?.BreakfastTime,
      BreakfastFood: cache?.BreakfastFood,
      LunchFood: cache?.LunchFood,
      EveningSnack: cache?.EveningSnack,
      DinnerFood: cache?.DinnerFood,
      WholeDayFood: cache?.WholeFood,
      BedTime: cache?.BedTime,
      WholeDayFood: cache?.WholeDayFood,
      Prefer: cache?.Prefer,
      Pizza: cache?.Pizza,
      Pasta: cache?.Pasta,
      Maggie: cache?.Maggie,
      Biscuits: cache?.Biscuits,
      Chaat: cache?.Chaat,
      Ghee: cache?.Ghee,
      Butter: cache?.Butter,
      Fruits: cache?.Fruits,
      FishChickenMeat: cache?.FishChickenMeat,
      Bread: cache?.Bread,
      PopcornChips: cache?.PopcornChips,
      Chocolate: cache?.Chocolate,
      Egg: cache?.Egg,
      Icecream: cache?.Icecream,
      LateNightBinging: cache?.LateNightBinging,
      SamosaVadapav: cache?.SamosaVadapav,
      Cheese: cache?.Cheese,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Assessment Updated");
          // checkClientAssessment();
          // localStorage.removeItem("CA1");
          // localStorage.removeItem("CA2");
          // localStorage.removeItem("CA3");
          // localStorage.removeItem("CA4");
          // localStorage.removeItem("CA5");
          // localStorage.removeItem("CA6");
          // localStorage.removeItem("CA7");
          // localStorage.removeItem("CA8");
          // localStorage.removeItem("CA9");
          // setPageIndex(1);
        } else {
          alert("Error updating assesssment form!");
        }
      })
      .catch((err) => {
        alert("Error uploading assessment form in");
        console.log("Error uploading assessment form in: ", err);
      });
  }

  //---------------------------------------------------------Update Client Assessment Function -----------------------------------------------------------------------//

  return (
    <div className={style.mainDivClientAssessmentForm}>
      <div className={style.formInternalDiv}>
        <div className={style.topProgressBarOutside}>
          <div className={style.topProgressBarInside} style={{ width: `${barWidth}%` }}></div>
        </div>
        {pageIndex === 1 ? (
          <CA1
            ref={C1}
            age={age}
            gender={gender}
            fullname={fullname}
            caste={caste}
            mobile={mobile}
            address={address}
            zipcode={zipcode}
            country={country}
            state={state}
            city={city}
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
          />
        ) : pageIndex == 2 ? (
          <CA2
            height={height}
            weight={weight}
            lastWeight={lastWeight}
            dreamWeight={dreamWeight}
            activityPattern={activityPattern}
            activityPatternOther={activityPatternOther}
            occupation={occupation}
            jobTime={jobTime}
            workTravel={workTravel}
            personalTravel={personalTravel}
            travelCountry={travelCountry}
            setPageIndex={setPageIndex}
            ref={C2}
            adminData={admin && data}
            profileData={profile && data}
            physicalactivity={physicalactivity}
            physicalactivityother={physicalactivityother}
          />
        ) : pageIndex == 3 ? (
          <CA3
            workMeals={workMeals}
            homeCook={homeCook}
            familyType={familyType}
            familyTypeOther={familyTypeOther}
            maritalStatus={maritalStatus}
            maritalStatusOther={maritalStatusOther}
            totalKids={totalKids}
            kidsAge={kidsAge}
            eatinghabits={eatinghabits}
            eatingHabitsOtherRef={eatingHabitsOther}
            setPageIndex={setPageIndex}
            ref={C3}
            adminData={admin && data}
            profileData={profile && data}
          />
        ) : pageIndex == 4 ? (
          <CA4
            preferredCuisine={preferredCuisine}
            smoking={smoking}
            smokingOther={smokingOther}
            alcohol={alcohol}
            alcoholOther={alcoholOther}
            preferredAlcohol={preferredAlcohol}
            waterIntake={waterIntake}
            religiousRestrictions={religiousRestrictions}
            fasting={fasting}
            foodAllergies={foodAllergies}
            foodDislikes={foodDislikes}
            frequencyOfAlcohol={frequencyOfAlcohol}
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
            ref={C4}
          />
        ) : pageIndex == 5 ? (
          <CA5
            sleepPattern={sleepPattern}
            bowelMovement={bowelMovement}
            regularPeriods={regularPeriods}
            regularPeriodsOther={regularPeriodsOther}
            lastMonthlyCycle={lastMonthlyCycle}
            remark={remark}
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
            ref={C5}
          />
        ) : pageIndex == 6 ? (
          <CA6
            medicalHistory={medicalHistory}
            medicalHistoryOther={medicalHistoryOther}
            currentMedication={currentMedication}
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
            ref={C6}
          />
        ) : pageIndex == 7 ? (
          <CA7
            wakeTime={wakeTime}
            prefer={prefer}
            preferOther={preferOther}
            breakfastTime={breakfastTime}
            breakfastFood={breakfastFood}
            lunchFood={lunchFood}
            eveningSnack={eveningSnack}
            dinnerFood={dinnerFood}
            wholeDayFood={wholeDayFood}
            bedTime={bedTime}
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
            ref={C7}
          />
        ) : pageIndex == 8 ? (
          <CA8
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
            ref={C8}
            pizza={pizza}
            pasta={pasta}
            maggie={maggie}
            biscuits={biscuits}
            chaat={chaat}
            ghee={ghee}
            butter={butter}
            fruits={fruits}
            fishChickenMeat={fishChickenMeat}
            bread={bread}
            popcornChips={popcornChips}
          />
        ) : pageIndex == 9 ? (
          <CA9
            setPageIndex={setPageIndex}
            adminData={admin && data}
            profileData={profile && data}
            ref={C9}
            chocolate={chocolate}
            egg={egg}
            iceCream={iceCream}
            lateNightBinging={lateNightBinging}
            samosaVadapav={samosaVadapav}
            cheese={cheese}
            updateClientAssessment={updateClientassessment}
            clientassessment={clientassessment}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={style.belowButtonOfPI}>
        {pageIndex === 1 ? (
          <></>
        ) : (
          <button
            className={style.backBtnPI}
            onClick={() => {
              setPageIndex((i) => i - 1);
            }}
          >
            Back
          </button>
        )}
        {admin && pageIndex === 9 ? (
          <></>
        ) : pageIndex === 9 ? (
          <button
            onClick={() => {
              localStorage.setItem(
                `CA9`,
                JSON.stringify({
                  Chocolate: chocolate.current?.value,
                  Egg: egg.current?.value,
                  Icecream: iceCream.current?.value,
                  LateNightBinging: lateNightBinging.current?.value,
                  SamosaVadapav: samosaVadapav.current?.value,
                  Cheese: cheese.current?.value,
                })
              );
              if (profile) {
                checkClientAssessment();
                C9.current.checkValidation();
              } else {
                C9.current.checkValidation();
              }
            }}
          >
            {profile ? "Update" : "Submit"}
          </button>
        ) : (
          <button
            onClick={() => {
              if (pageIndex === 1) {
                localStorage.setItem(
                  `CA1`,
                  JSON.stringify({
                    Age: age?.current?.value,
                    Gender: gender?.current?.value,
                    Caste: caste?.current?.value,
                    Address: address?.current?.value,
                    Country: country?.current?.value,
                    City: city?.current?.value,
                    Zipcode: zipcode?.current?.value,
                    Mobile: mobile?.current?.value,
                    State: state?.current?.value,
                    Fullname: fullname?.current?.value,
                  })
                );
                C1.current.checkValidation();
              } else if (pageIndex === 2) {
                localStorage.setItem(
                  `CA2`,
                  JSON.stringify({
                    Height: height?.current?.value,
                    Weight: weight?.current?.value,
                    LastWeight: lastWeight?.current?.value,
                    Goal: dreamWeight?.current?.value,
                    Activitypattern:
                      activityPattern?.current?.value === "Other"
                        ? activityPatternOther?.current?.value
                        : activityPattern?.current?.value,
                    Occupation: occupation?.current?.value,
                    Jobtiming: jobTime?.current?.value,
                    Travelling: workTravel?.current?.value,
                    Traveloften: personalTravel?.current?.value,
                    Travelwithin:
                      personalTravel?.current?.value !== "None"
                        ? travelCountry?.current?.value
                        : "",
                    PhysicalActivity:
                      physicalactivity?.current?.value === "Other"
                        ? physicalactivityother?.current?.value
                        : physicalactivity?.current?.value,
                  })
                );
                C2.current.checkValidation();
              } else if (pageIndex === 3) {
                let fam = "";
                if (familyType.current.value === "Nuclear-Family") {
                  fam = "Nuclear-Family";
                } else if (familyType.current.value === "Joint Family") {
                  fam = "Joint Family";
                } else if (familyType.current.value === "Bachelor") {
                  fam = "Bachelor";
                } else {
                  fam = familyTypeOther.current.value;
                }
                if (eatinghabits.current.value === "Vegetarian") {
                } else if (eatinghabits.current.value === "Non-Vegetarian") {
                } else if (eatinghabits.current.value === "Egg Eater") {
                } else if (eatinghabits.current.value === "Vegan") {
                } else {
                }
                localStorage.setItem(
                  `CA3`,
                  JSON.stringify({
                    Carrymeal: workMeals?.current?.value,
                    Whocook: homeCook?.current?.value,

                    Familytype: fam,

                    Maritalstatus:
                      maritalStatus?.current?.value === "Other"
                        ? maritalStatusOther?.current?.value
                        : maritalStatus?.current?.value,
                    Kids: totalKids?.current?.value,
                    Childrenage: kidsAge?.current?.value,
                    Eatinghabit:
                      eatinghabits?.current?.value === "Other"
                        ? eatingHabitsOther?.current?.value
                        : eatinghabits?.current?.value,
                  })
                );
                C3.current.checkValidation();
              } else if (pageIndex === 4) {
                localStorage.setItem(
                  `CA4`,
                  JSON.stringify({
                    Preferredcuisine: preferredCuisine?.current?.value,
                    Smoking:
                      smoking?.current?.value === "Other"
                        ? smokingOther?.current?.value
                        : smoking?.current?.value,
                    Alcohol:
                      alcohol?.current?.value === "Other"
                        ? alcoholOther?.current?.value
                        : alcohol?.current?.value,
                    Alcoholconsumption: frequencyOfAlcohol?.current?.value,
                    Preferredalcohol: preferredAlcohol?.current?.value,
                    Waterintake: waterIntake?.current?.value,
                    Religiousrestrictions: religiousRestrictions?.current?.value,
                    Fastingfrequency: fasting?.current?.value,
                    Foodallergies: foodAllergies?.current?.value,
                    Fooddislike: foodDislikes?.current?.value,
                  })
                );
                C4.current.checkValidation();
              } else if (pageIndex === 5) {
                localStorage.setItem(
                  `CA5`,
                  JSON.stringify({
                    Sleeppattern: sleepPattern?.current?.value,
                    Bowelmovement: bowelMovement?.current?.value,
                    Regularperiods:
                      regularPeriods?.current?.value === "Other"
                        ? regularPeriodsOther?.current?.value
                        : regularPeriods?.current?.value,
                    Monthlycycledate: lastMonthlyCycle?.current?.value,
                    Description: remark?.current?.value,
                  })
                );
                C5.current.checkValidation();
              } else if (pageIndex === 6) {
                localStorage.setItem(
                  `CA6`,
                  JSON.stringify({
                    MedicalHistory:
                      medicalHistory?.current?.value === "Other"
                        ? medicalHistoryOther?.current?.value
                        : medicalHistory?.current?.value,
                    CurrentMedication: currentMedication.current.value,
                  })
                );
                C6.current.checkValidation();
              } else if (pageIndex === 7) {
                localStorage.setItem(
                  `CA7`,
                  JSON.stringify({
                    WakeTime: wakeTime.current.value,
                    Prefer:
                      prefer?.current?.value === "Other"
                        ? preferOther?.current?.value
                        : prefer.current?.value,
                    BreakfastTime: breakfastTime.current?.value,
                    BreakfastFood: breakfastFood.current?.value,
                    LunchFood: lunchFood.current?.value,
                    EveningSnack: eveningSnack.current?.value,
                    DinnerFood: dinnerFood.current?.value,
                    WholeDayFood: wholeDayFood.current?.value,
                    BedTime: bedTime.current?.value,
                  })
                );
                C7.current.checkValidation();
              } else if (pageIndex === 8) {
                localStorage.setItem(
                  `CA8`,
                  JSON.stringify({
                    Pizza: pizza.current?.value,
                    Pasta: pasta.current?.value,
                    Maggie: maggie.current?.value,
                    Biscuits: biscuits.current?.value,
                    Chaat: chaat.current?.value,
                    Ghee: ghee.current?.value,
                    Butter: butter.current?.value,
                    Fruits: fruits.current?.value,
                    FishChickenMeat: fishChickenMeat.current?.value,
                    Bread: bread.current?.value,
                    PopcornChips: popcornChips.current?.value,
                  })
                );

                C8.current.checkValidation();
              }
            }}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default ClientAssessmentForm;
