//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/ManageClients.module.css";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BASE_URL } from "../../components/baseURL";
import $ from "jquery";
import Request from "axios";
import style from "../../assets/styles/weightTrackerGrid.module.css";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function BloodTracker() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [mask, setMask] = useState();
  const navigation = useNavigate();
  const userDetails = useSelector((state) => state?.allData?.user_details);
  let temp = JSON.parse(localStorage?.getItem("user"));
  const location = useLocation();
  const data = location?.state?.data;
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //------------------------------------------------------------UseEffect to GET PERIOD TRACKER BY ID---------------------------------------------------------------------------//

  useEffect(() => {
    let link =
      temp?.Role === "Admin"
        ? `${BASE_URL}tracker/getPeriodTracker?id=${data?.id}`
        : `${BASE_URL}tracker/getPeriodTracker?id=${userDetails?.id ? userDetails?.id : temp?.id}`;
    Request.get(link)
      .then((res) => {
        setList(res?.data?.data);
        $(() => {
          $("#myTable").DataTable({
            data: res?.data?.data,
            columns: [
              // moment(new Date(data.Lastperioddate)).format("DD.MM.YYYY") ,
              {
                data: "Lastperioddate",
                render: function (data) {
                  return moment(data).format("YYYY-MM-DD");
                },
                targets: 1,
              },
              { data: "Symptoms" },
              { data: "Flow" },
            ],
          });
        });
        setTimeout(() => {
          styleChanges();
        }, [200]);
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      });
  }, []);

  //------------------------------------------------------------UseEffect to GET PERIOD TRACKER BY ID---------------------------------------------------------------------------//


  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  function styleChanges() {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document.querySelectorAll(".dataTables_wrapper").forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginBottom = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document.querySelectorAll(".dataTables_length").forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
    document
      .querySelectorAll(".dataTables_wrapper .dataTables_info")
      .forEach((el) => (el.style.paddingLeft = "10px"));
    document.querySelectorAll(".paginate_button").forEach((el) => (el.style.color = "#FFFFFF"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.background = "#00C1BD"));
    // document.querySelectorAll(".paginate_button").forEach((el) => (el.style.border = "1px solid #00C1BD"));
    document.querySelectorAll(".paginate_button").forEach((el) => (el.style.borderRadius = "4px"));
  }

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to Style Table-----------------------------------------------------------------------//

  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = mask ? "150px" : "50px"));
  }, [mask]);

  //---------------------------------------------------------UseEffect to Style Table-----------------------------------------------------------------------//
  
  //------------------------------------------------------------UseEffect to dispatch current route---------------------------------------------------------------------------//

  useEffect(() => {
    // setTimeout(() => {
    //   styleChanges();
    // }, [200]);
    dispatch({ type: "ROUTES", payload: "Period Tracker" });
  }, []);

  //------------------------------------------------------------UseEffect to dispatch current route---------------------------------------------------------------------------//

  return (
    <div className={style.weightTrackerMainDiv}>
      <div
        className={style.backButtonDiv}
        onClick={() => {
          temp?.Role === "Admin" ? navigation("/manageclients") : navigation("/clientdashboard");
        }}
      >
        {/* <img src={} alt="" /> */}
        <label> &lt; Back</label>
      </div>
      {/* <div className={style.setwidth}>
        <button className={style.excelbtn}>
          <img src={excelExport} alt="" />
          Excel Export
        </button>
      </div> */}
      <table
        id="myTable"
        className={bootstrap[("table", "table-striped")] + " " + styles.tableCustomcls}
      >
        <thead>
          <tr>
            <th className={styles.Tablefontcls}>Lastperioddate</th>
            <th className={styles.Tablefontcls}>Symptoms </th>
            <th className={styles.Tablefontcls}>Flow </th>
            {/* <th className={styles.Tablefontcls}>Client Name</th> */}
          </tr>
        </thead>
        <tbody>
          {/* {list?.length > 0 ? (
            list?.map((val, i) => {
              return (
                <tr key={String(i)}>
                  <td className={styles.Tablefonttdcls}>{moment(val?.Lastperioddate).format("YYYY-MM-DD")}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Symptoms}</td>
                  <td className={styles.Tablefonttdcls}>{val?.Flow}</td>
                  <td className={styles.Tablefonttdcls}>{data?.Firstname && data?.Lastname ? data?.Firstname + " " + data?.Lastname : "---"}</td>
                </tr>
              );
            })
          ) : (
            <></>
          )} */}
        </tbody>
      </table>
    </div>
  );
}

export default BloodTracker;
