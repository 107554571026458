//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useRef, useState } from "react";
import ClientAssessmentForm from "./ClientAssessmentForm";
import style from "../../assets/styles/MyProfileClient.module.css";
import profileImage from "../../assets/images/personIcon.png";
import uploadButton from "../../assets/images/upload.png";
import { useDispatch, useSelector } from "react-redux";
import Request from "axios";
import { BASE_URL } from "../../components/baseURL";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function MyProfileClient() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [img, setImg] = useState();
  const [imgUrl, setImgUrl] = useState();
  const dispatch = useDispatch();
  const temp = JSON.parse(localStorage.getItem("user"));
  const upload = useRef();
  const oldPass = useRef();
  const newPass = useRef();
  const newPass2 = useRef();
  const userDetails = useSelector((state) => state?.allData?.user_details);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  
  //---------------------------------------------------------UseEffect to Dispatch Routes-----------------------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: "ROUTES", payload: "My Profile" });
  }, []);

  //---------------------------------------------------------UseEffect to Dispatch Routes-----------------------------------------------------------------------//

  //--------------------------------------------------------Change Password Function----------------------------------------------------------------------//

  function changePassword() {
    if (
      newPass2?.current?.value?.length > 0 &&
      newPass?.current?.value?.length > 0 &&
      oldPass?.current?.value?.length > 0
    ) {
      if (newPass?.current?.value === newPass2?.current?.value) {
        Request.post(`${BASE_URL}client/changePassword`, {
          Emailid: userDetails?.Emailid ? userDetails?.Emailid : temp?.Emailid,
          Password: newPass2?.current?.value,
          CurrentPassword: oldPass?.current?.value,
        })
          .then((res) => {
            if (res?.data?.status === 200) {
              alert("Password updated successfully!");
              newPass.current.value = "";
              newPass2.current.value = "";
              oldPass.current.value = "";
            } else {
              if (res?.data?.error === "Incorrect Current Password") {
                alert("Incorrect password");
              } else {
                alert("Error updating password");
                console.log("Error updating password", res?.data?.error);
              }
            }
          })
          .catch((err) => {
            console.log("Error updating password", err);
          });
      } else {
        alert("Password doesn't match");
      }
    } else {
      alert("Please fill all details to update!");
    }
  }

 //--------------------------------------------------------Change Password Function----------------------------------------------------------------------//

 //--------------------------------------------------------Get Image Data Function----------------------------------------------------------------------//

  function getImgData(id, img) {
    const files = img;
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        setImgUrl(this.result);
      });
      sendImageData();
    }
  }
  
  //--------------------------------------------------------Get Image Data Function----------------------------------------------------------------------//
  
  //--------------------------------------------------------Send Image Data Function----------------------------------------------------------------------//

  function sendImageData() {
    const data = new FormData();
    data.append("photo", img);
    data.append("id", temp?.id);
    Request.post(`${BASE_URL}client/updateProfilePhoto`, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          getLoginDetails();
        } else {
          alert("Error updating profile photo");
          console.log("Error updating profile photo", res?.data);
        }
      })
      .catch((err) => {
        console.log("Error updating profile photo", err);
      });
  }

  //--------------------------------------------------------Send Image Data Function----------------------------------------------------------------------//
  
  //--------------------------------------------------------Get Login Details Function----------------------------------------------------------------------//

  function getLoginDetails() {
    Request.post(`${BASE_URL}login/loginDetails`, { Client_id: temp?.id })
      .then((res) => {
        let temp = userDetails;

        temp = {
          ...temp,
          PlanStatus: res?.data?.data[0]?.PlanStatus,
          PlanType: res?.data?.data[0]?.Type,
          PlanPurchasedate: res?.data?.data[0]?.Purchasedate,
          PlanExpired: res?.data?.data[0]?.isExpired,
          Profilepicture: res?.data?.data[0]?.Profilepicture,
          PlanStartDate: res?.data?.data[0]?.PlanStartDate,
          Logindate: res?.data?.data[0]?.Logindate,
          Firstname: res?.data?.data[0]?.Firstname,
          Lastname: res?.data?.data[0]?.Lastname,
          Mobilenumber: res?.data?.data[0]?.Mobilenumber,
          Planid: res?.data?.data[0]?.Planid,
        };
        res?.data?.data[0]?.Profilepicture &&
          setImgUrl(`https://server.healvick.com/profile/${res?.data?.data[0]?.Profilepicture}`);
        dispatch({ type: "USER_DETAILS", payload: temp });
        localStorage.setItem("user", JSON.stringify(temp));
      })
      .catch((err) => {
        console.log("Error fetching login details", err);
      });
  }

  //--------------------------------------------------------Get Login Details Function----------------------------------------------------------------------//
  
  //--------------------------------------------------------UseEffect to Validate Image Size----------------------------------------------------------------------//

  useEffect(() => {
    if (img?.size > 1000000) {
      alert("Please upload file of size 1MB or less!");
      setImg();
    } else {
      img && getImgData("profilepic", img);
      getLoginDetails();
    }
  }, [img]);
  
  //--------------------------------------------------------UseEffect to Validate Image Size----------------------------------------------------------------------//

  return (
    <div className={style.mainProfileClientDiv}>
      <div className={style.topProfileDiv}>
        <div className={style.firstRowOfPC}>
          <div className={style.leftProfileInfo}>
            <div className={style.profilePicture}>
              <img
                src={
                  temp?.Profilepicture
                    ? `https://server.healvick.com/profile/${temp?.Profilepicture}`
                    : profileImage
                }
                alt=""
                className={style.profileImg}
              />
              <div
                className={style.bottomUploadButton}
                onClick={() => {
                  upload.current.click();
                }}
              >
                <img src={uploadButton} alt="" />
                {/* <img id="profilepic" alt="" /> */}
                <input
                  type="file"
                  id="file"
                  ref={upload}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    setImg(file);
                  }}
                />
              </div>
            </div>
            <div className={style.profileInfo}>
              <label>
                {userDetails?.Firstname} {userDetails?.Lastname}
              </label>
              <label>Client</label>
            </div>
          </div>
          <div className={style.rightInvoiceButton}>
            <button
              onClick={() => {
                localStorage.setItem("pageData", JSON.stringify(temp?.id));
                window.open("/invoicepdf");
              }}
            >
              Download Invoice
            </button>
          </div>
        </div>
        <div className={style.secondRowOfPC}>
          <label className={style.changePasswordLabel}>Change Password</label>
          <div className={style.inputDivForPassword}>
            <div className={style.oldPasswordInput}>
              <label>Old Password</label>
              <input
                onChange={() => {}}
                ref={oldPass}
                type="text"
                className={style.oldPassowrd}
                placeholder="Old Password"
              />
            </div>
            <div className={style.newPasswordInput}>
              <label>New Password</label>
              <input
                onChange={() => {}}
                ref={newPass}
                type="text"
                className={style.newPassowrd}
                placeholder="New Password"
              />
            </div>
            <div className={style.confirmPasswordInput}>
              <label>Confirm Password</label>
              <input
                onChange={() => {}}
                ref={newPass2}
                type="text"
                className={style.confirmPassowrd}
                placeholder="Confirm Password"
              />
            </div>
          </div>
        </div>
        <div className={style.thirdRowOfPC}>
          <button onClick={() => changePassword()}>Update Password</button>
        </div>
      </div>
      <div className={style.divider}>
        <div className={style.innerDivider}></div>
      </div>
      <div className={style.bottomHealthAssessmentdiv}>
        {temp?.PlanStatus == 1 && <ClientAssessmentForm profile={true} />}
      </div>
    </div>
  );
}

export default MyProfileClient;
