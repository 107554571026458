//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useRef } from "react";
import styles from "../assets/styles/LoginPage.module.css";
import login_bg_right from "../assets/images/login_bg_right.png";
import login_bg_rightTabPhone from "../assets/images/login_bg_rightTabPhone.png";
import login_bg_rightPhone from "../assets/images/login_bg_rightPhone.png";
import login_bg_top from "../assets/images/login_bg_top.png";
import login_bg_bottom from "../assets/images/login_bg_bottom.png";
import login_bg_topTabPhone from "../assets/images/login_bg_topTabPhone.png";
import login_bg_bottomTabPhone from "../assets/images/login_bg_bottomTabPhone.png";
import login_bg_centre from "../assets/images/login_bg_centre.png";
import A_jain_logo from "../assets/images/A_Jain_logo.png";
import instagramHandle from "../assets/images/instagram.png";
import facebookHandle from "../assets/images/facebook.png";
import Request from "axios";
import { BASE_URL } from "../components/baseURL";
import { useNavigate } from "react-router";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ForgotPassword() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const email = useRef();
  const navigation = useNavigate();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  // async function validate() {
  //   if (email.current.value?.length < 1) {
  //     alert("Enter email id");
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.current.value)) {
  //     alert("Enter Valid Email");
  //   } else {
  //   }
  //   let rm = false;
  //   login(rm);
  // }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  async function validate() {
    let error = false;
    let emailValidate = document.getElementById("email");
    let errorMessageEmail = document.getElementById("errorMessageEmail");
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.current.value)) {
      error = true;
      emailValidate.style.border = "2px solid red";
      errorMessageEmail.style.display = "block";
      errorMessageEmail.textContent = "Please Enter Valid Email";
    } else {
      errorMessageEmail.style.display = "none";
      emailValidate.style.border = "1.5px solid #D9D9D9";
    }
    !error && forgotPass();
  }

  //---------------------------------------------------------Validate Function-----------------------------------------------------------------------//

  //---------------------------------------------------------Forgot Password Function-----------------------------------------------------------------------//

  async function forgotPass() {
    Request.post(`${BASE_URL}client/forgotPassword`, {
      Emailid: email.current.value,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          navigation("/");
          alert("Please check your registered email id");
        } else {
          console.log("Error forgot password", res?.data);
        }
        if (res?.data?.error === "No user found") {
          alert("Invalid Email Id");
        }
      })
      .catch((err) => {
        console.log("Error forgot password in: ", err);
      });
  }

  //---------------------------------------------------------Forgot Password Function-----------------------------------------------------------------------//

  return (
    <div className={styles.mainContainer + " " + styles.newend}>
      <div className={styles.leftInputs}>
        <img className={styles.AJainLogo + " " + styles.AJainLogonewset} src={A_jain_logo} alt="" />
        <label className={styles.signInText}>Forgot Password?</label>
        <label className={styles.belowSignInText}>
          Enter email address associated with your account
        </label>
        <div className={styles.inputCred}>
          <input
            className={styles.inputUserName}
            type="text"
            placeholder="Email ID"
            ref={email}
            id="email"
          />
          <span className={styles.errorSpan + " " + styles.settop} id="errorMessageEmail"></span>
          <button className={styles.signInButton + " " + styles.newwidthset} onClick={validate}>
            Send
          </button>
        </div>
        <div className={styles.signupRedirect}>
          <label className={styles.signupRedirectLabel}>Back to</label>
          <label
            className={styles.signupRedirectALink}
            onClick={() => {
              navigation("/");
            }}
          >
            {" "}
            Sign In{" "}
          </label>
        </div>
        <div className={styles.followUsTextAndTag}>
          <div className={styles.followUsText}>
            <label>Follow Us</label>
          </div>
          <div className={styles.followUsTag}>
            <a
              href="https://instagram.com/dt.arpita_healvick?igshid=NGVhN2U2NjQ0Yg=="
              target="_blank"
            >
              <img src={instagramHandle} alt="" />
            </a>
            <a href="https://www.facebook.com/healvick?mibextid=hrBMPu" target="_blank">
              <img src={facebookHandle} alt="" />
            </a>
            {/* <img src={twitterHandle} alt="" /> */}
          </div>
        </div>
      </div>
      <div className={styles.rightImages}>
        <div className={styles.rightImagesInsideParentOne}>
          <div className={styles.rightImagesInsideChildOne}>
            <img className={styles.loginBgTop} src={login_bg_top} alt="" />
            <img className={styles.loginBgBottom} src={login_bg_bottom} alt="" />
          </div>
          <img className={styles.loginBgRight} src={login_bg_right} alt="" />
        </div>
        <div className={styles.rightImagesInsideParentTwo}>
          <img className={styles.loginBgCentre} src={login_bg_centre} alt="" />
        </div>
      </div>
      <div className={styles.rightImagesTabPhone}>
        <div className={styles.rightImagesInsideParentOneTabPhone}>
          <img className={styles.loginBgRightTabPhone} src={login_bg_rightTabPhone} alt="" />
          <img className={styles.loginBgRightPhone} src={login_bg_rightPhone} alt="" />
          <div className={styles.rightImagesInsideParentTwoTabPhone}>
            <img className={styles.loginBgCentreTabPhone} src={login_bg_centre} alt="" />
          </div>
          <div className={styles.rightImagesInsideChildOneTabPhone}>
            <img className={styles.loginBgTopTabPhone} src={login_bg_topTabPhone} alt="" />
            <img className={styles.loginBgBottomTabPhone} src={login_bg_bottomTabPhone} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
