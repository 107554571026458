//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React from "react";
import styles from "../assets/styles/Parent.module.css";
import newRegNotiLogo from "../assets/images/newRegNotiLogo.png";
import { BASE_URL } from "./baseURL";
import Request from "axios";
import moment from "moment";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function MealApproveNotification({
  name,
  email,
  MobileNumber,
  Createdby,
  refresh,
  id,
  Purchasedate,
}) {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  let todays_date = moment();
  let CreatedDate = moment(Purchasedate);
  let dateDiff = CreatedDate.diff(todays_date, "days");
  let duration = "";  
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //-------------------------------------------------------------------Set Duration-----------------------------------------------------------------------------//

  if (dateDiff == 1) {
    duration = "1 day ago";
  } else if (dateDiff == 0) {
    duration = "Today";
  } else {
    duration = `${dateDiff} days ago`;
  }

  //-------------------------------------------------------------------Set Duration-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Approve Plan Function-----------------------------------------------------------------------------//

  function approve() {
    Request.post(`${BASE_URL}notifications/updatePlan`, { id: id, approved: 1 })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Approved Successfully");
          refresh();
        } else {
          console.log("Error approving plan", res?.data);
        }
      })
      .catch((err) => {
        console.log("Error approving plan: ", err);
      });
  }

  //-------------------------------------------------------------------Approve Plan Function-----------------------------------------------------------------------------//

  //-------------------------------------------------------------------Decline Plan Function-----------------------------------------------------------------------------//

  function decline() {
    Request.post(`${BASE_URL}notifications/updatePlan`, { id: id, approved: 0 })
      .then((res) => {
        if (res?.data?.status === 200) {
          alert("Declined Successfully");
          refresh();
        } else {
          console.log("Error declining plan", res?.data);
        }
      })
      .catch((err) => {
        console.log("Error approving plan: ", err);
      });
  }

  //-------------------------------------------------------------------Decline Plan Function-----------------------------------------------------------------------------//

  return (
    <>
      <div className={styles.divider}></div>
      <div className={styles.notificationOne}>
        <div className={styles.notificationOneInnerDivOneAdmin}>
          <img src={newRegNotiLogo} alt="" />
          <div className={styles.titleTextsAndDates}>
            <label className={styles.titleApprovedNoti}>Meal Plan Ready ({duration})</label>
            <label className={styles.infoApprovedNoti}>
              Created by: <span>{Createdby}</span>
            </label>
            <label className={styles.infoApprovedNoti}>
              Name: <span>{name}</span>
            </label>
            <label className={styles.dateApprovedNoti}>
              Email: <span>{email}</span>
            </label>
            <label className={styles.dateApprovedNoti}>
              Number: <span>{MobileNumber}</span>
            </label>
          </div>
        </div>
        <div className={styles.notificationOneButtonTwoAdmin}>
          <button
            onClick={() => {
              approve();
            }}
          >
            Accept
          </button>
          <button
            onClick={() => {
              decline();
            }}
          >
            Reject
          </button>
        </div>
      </div>
    </>
  );
}

export default MealApproveNotification;
